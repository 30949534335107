<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Insert Token</DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">Select an attribute to insert dynamically when the message is sent to a person.</p>
                            </div>
                            <div class="mt-8">
                                <form-field>
                                    <form-select label="Attribute" id="attribute" name="attribute" v-model="tag_value.attribute_id">
                                        <option :value="null">- Select Attribute -</option>
                                        <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                    </form-select>
                                    <form-validation param="attribute_id" :errors="errors" />
                                </form-field>
                                <form-field v-if="tag_value.attribute_id">
                                    <form-input label="Default Value" id="dt_default" name="dt_default" v-model="tag_value.default_value"></form-input>
                                    <form-validation param="value" :errors="errors" />
                                </form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="$emit('add', tag_value); ">
                            {{ actionText }}
                        </form-button>
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'dynamic-tag-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
            FormField,
            FormLabel,
            FormSelect,
            FormInput,
            FormValidation,
        },
        emits: [
            'insert',
            'cancel',
        ],
        props: {
            attributes: Array,
            errors: Object,
            tag: {
                type: Object,
                default: {
                    attribute_id: null,
                    value: '',
                },
            },
            actionText: {
                type: String,
                default: 'Insert',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            tag_value: {
                get() {
                    return this.tag
                },
                set(val) {
                    this.$emit('update:tag', val);
                }
            },
        },
    };
</script>
