<template>
    <div>
        <form-label :id="id" :label="label"></form-label>
        <div class="relative mb-3">
            <select class="appearance-none block w-full text-gray-700 border border-gray-400 rounded-full shadow-sm py-2 px-4 pr-8 focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple"
                :class="{
                    'bg-gray-300': (disabled || readonly),
                    'bg-white': (!disabled && !readonly),
                }"
                :id="id"
                :name="name"
                :readonly="readonly"
                :disabled="disabled"
                v-model="select_value"
            >
                <slot></slot>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
        </div>
    </div>
</template>

<script>
    import FormLabel from './Label'

    export default {
        name: 'form-select',
        components: {
            FormLabel,
        },
        props: {
            id: String,
            name: String,
            label: String,
            required: Boolean,
            readonly: Boolean,
            disabled: Boolean,
            modelValue: {
                type: [String, Number, Boolean],
            },
        },
        emits: [
            'update:modelValue'
        ],
        computed: {
            select_value: {
                get() {
                    return this.modelValue;
                },
                set(val) {
                    this.$emit('update:modelValue', val);
                }
            },
        },
    }
</script>
