<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <div>
                                <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Attachment Preview</DialogTitle>
                                <div class="mt-2 mb-2">
                                    <img :src="previewAttachmentUrl" class="object-scale-down h-48 w-full" v-if="previewAttachmentType === 'image'"/>
                                    <audio controls :src="previewAttachmentUrl" class="object-scale-down h-48 w-full" v-if="previewAttachmentType === 'audio'"/>
                                    <video controls :src="previewAttachmentUrl" class="object-scale-down h-48 w-full" v-if="previewAttachmentType === 'video'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button'

    export default {
        name: 'preview-attachment-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
        },
        emits: [
            'cancel',
        ],
        props: {
            name: String,
            previewAttachmentUrl: String,
            previewAttachmentType: {
                type: String,
                default: 'image',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
