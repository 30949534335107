<template>
    <div class="w-full p-4 border bg-gray-100 border-gray-100 dark:bg-gray-900 dark:border-gray-800 cursor-pointer text-gray-500" v-bind:class="{'bg-sbr-light-purple text-gray-800': selected && selected.id === person.id}" @click="$emit('select-person')">
        <div class="flex flex-row items-center justify-between">
            <div class="flex flex-col hidden sm:block">
                <div class="text-xs uppercase font-bold">{{ $filters.formatPhone(person.phone_number) }}</div>
                <div class="text-xl font-bold"></div>
            </div>
            <svg class="stroke-current" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewbox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2">
                </path>
                <circle cx="9" cy="7" r="4">
                </circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87">
                </path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75">
                </path>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'list-person',
        props: [
            'person',
            'selected'
        ],
        components: {
        },
        created() {
        },
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>
