<template>
    <div>
        <layout-header title="Edit Number"></layout-header>

        <form-section title="Number Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="number.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="number" name="number" label="Number" v-model="number.number" disabled></form-input>
                <form-validation param="number" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="number.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="catchall_sequence_id" name="catchall_sequence_id" label="Catchall Sequence" v-model="number.catchall_sequence_id">
                    <option :value="null">- None -</option>
                    <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                </form-select>
                <form-validation param="catchall_sequence_id" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="apple_reactions" name="apple_reactions" label="iOS Reaction Handler" v-model="number.apple_reactions">
                    <option value="ignore">Ignore</option>
                    <option value="normal_process">Attempt Keyword Matching</option>
                    <option value="attempt_match">Attempt Reaction Matching</option>
                    <option value="use_sequence">Use Specific Sequence</option>
                </form-select>
                <form-validation param="apple_reactions" :errors="errors" />
            </form-field>

            <form-field v-if="number.apple_reactions === 'use_sequence'">
                <form-select id="apple_reaction_sequence_id" name="apple_reaction_sequence_id" label="iOS Reaction Sequence" v-model="number.apple_reaction_sequence_id">
                    <option :value="null">- None -</option>
                    <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                </form-select>
                <form-validation param="apple_reaction_sequence_id" :errors="errors" />
            </form-field>
        </form-section>

        <form-section title="Keyword Details">
            <form-field>
                <form-input name="start_keywords" label="Start Keywords" v-model="number.start_keywords" disabled></form-input>
                <form-validation param="start_keywords" :errors="errors" />
            </form-field>

            <form-field>
                <form-input name="stop_keywords" label="Stop Keywords" v-model="number.stop_keywords" disabled></form-input>
                <form-validation param="stop_keywords" :errors="errors" />
            </form-field>

            <form-field>
                <form-input name="help_keywords" label="Help Keywords" v-model="number.help_keywords" disabled></form-input>
                <form-validation param="help_keywords" :errors="errors" />
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Update Number
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'EditNumber',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchNumber();
            this.fetchSequences();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                sequences: [],
                number: {
                    id: '',
                    name: '',
                    number: '',
                    status: 'active',
                    catchall_sequence_id: null,
                    apple_reactions: 'ignore',
                    apple_reaction_sequence_id: null,
                },
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?number_id=${this.id}&per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchNumber() {
                this.$http.get(`/api/v1/numbers/${this.id}`).then(this.refreshNumber);
            },
            refreshNumber({data}) {
                this.number = data.data;

                this.number.start_keywords = this.number.start_keywords.map((item) => item).join(', ')
                this.number.stop_keywords = this.number.stop_keywords.map((item) => item).join(', ')
                this.number.help_keywords = this.number.help_keywords.map((item) => item).join(', ')
            },
            save() {
              this.$http.put('/api/v1/numbers/'+this.id, this.number).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The number has been updated',
                    duration: 5000,
                    speed: 1000
                });
                this.$emitter.emit('numbers_updated');

                this.$router.push({name: 'list_numbers'});
              }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
