<template>
    <div>
        <layout-header title="Edit Webform"></layout-header>

        <form-section title="Form Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="webform.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="webform.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-select label="Sequence" id="number_id" name="number_id" v-model="webform.sequence_id">
                    <option :value="null">- Select Sequence -</option>
                    <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                </form-select>
                <form-validation param="sequence_id" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="button_text" name="button_text" label="Submit Button Label" v-model="webform.button_text"></form-input>
                <form-validation param="button_text" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="captcha_type" name="captcha_type" label="Captcha Type" v-model="webform.captcha_type">
                    <option value="none">None</option>
                    <option value="text">Text</option>
                    <!--<option value="recaptcha">reCAPTCHA</option>-->
                </form-select>
                <form-validation param="captcha_type" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="success_type" name="success_type" label="Success Type" v-model="webform.success_type">
                    <option value="send_message">Display Message</option>
                    <option value="redirect">Redirect to URL</option>
                </form-select>
                <form-validation param="success_type" :errors="errors" />
            </form-field>

            <form-field v-if="webform.success_type === 'send_message'">
                <form-input id="success_message" name="success_message" label="Success Message" v-model="webform.success_message"></form-input>
                <form-validation param="success_message" :errors="errors" />
            </form-field>

            <form-field v-if="webform.success_type === 'redirect'">
                <form-input id="success_redirect_url" name="success_redirect_url" label="Redirect URL" v-model="webform.success_redirect_url"></form-input>
                <form-validation param="success_redirect_url" :errors="errors" />
            </form-field>

            <form-field>
                <form-textarea id="mobile_optin_language" name="mobile_optin_language" label="Optin Language" v-model="webform.mobile_optin_language"></form-textarea>
                <form-validation param="mobile_optin_language" :errors="errors" />
            </form-field>
        </form-section>

        <form-section title="URL Details">
            <form-field>
                <form-validation param="urls" :errors="errors" />
                <form-validation param="urls.values" :errors="errors" />
            </form-field>

            <form-field wide>
                <div class="flex flex-wrap mb-6 -mx-3" v-for="(value, index) in webform.urls" v-bind:key="index">
                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                        <form-input label="" :id="'urls_'+index" :name="'urls_'+index" type="text" v-model="value.url" />
                        <form-validation :param="'urls.'+index+'.url'" :errors="errors" />
                    </div>
                    <div class="w-full h-full pl-6 text-right cursor-pointer md:w-1/5">
                        <form-button negative-action @click="removeURL(index)" v-if="webform.urls.length > 1"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                    </div>
                </div>
                <div class="flex flex-wrap mx-3 my-6">
                    <form-button positive-action @click="addURL()"><i class="fas fa-plus-circle"></i> ADD</form-button>
                </div>
            </form-field>
        </form-section>

        <form-section title="Field Details">
            <form-field>
                <form-validation param="fields" :errors="errors" />
            </form-field>

            <form-field wide>
                <draggable v-model="webform.fields" handle=".handle" :item-key="((item) => webform.fields.indexOf(item))">
                    <template #item="{element, index}">
                        <div class="flex w-full">
                            <div class="flex-initial px-3 mb-6 md:mb-0">
                                <i class="pt-8 fa fa-align-justify handle"></i>
                            </div>

                            <div class="px-3 mb-6 md:mb-0 w-1/7">
                                <form-select label="Attribute" :id="'fields_'+index+'_attribute_id'" :name="'fields_'+index+'_attribute_id'" v-model="element.attribute_id" disabled v-if="element.attribute_id === 1">
                                    <option :value="1">Phone Number</option>
                                </form-select>
                                <form-select label="Attribute" :id="'fields_'+index+'_attribute_id'" :name="'fields_'+index+'_attribute_id'" v-model="element.attribute_id" v-if="element.attribute_id !== 1">
                                    <option :value="null">- Select Attribute -</option>
                                    <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                </form-select>
                                <form-validation :param="'fields.'+index+'.attribute_id'" :errors="errors" />
                            </div>
                            <div class="px-3 mb-6 md:mb-0 w-1/7">
                                <form-select label="Type" :id="'fields_'+index+'_type'" :name="'fields_'+index+'_type'" v-model="element.type" :disabled="element.attribute_id === 1">
                                    <option :value="null">- Select Type -</option>
                                    <option value="input">Text Input</option>
                                    <option value="textarea">Text Area</option>
                                    <option value="select">Select</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="hidden">Hidden</option>
                                    <option value="query_param">Query Param</option>
                                </form-select>
                                <form-validation :param="'fields.'+index+'.type'" :errors="errors" />
                            </div>

                            <div class="px-3 mb-6 md:mb-0 w-1/7">
                                <label class="form-label" :for="'fields_'+index+'_is_required'">Required?</label><br />
                                <input class="form-checkbox" :id="'fields_'+index+'_is_required'" :name="'fields_'+index+'_is_required'" type="checkbox" v-model="element.parameters.is_required" :value="true" :disabled="element.attribute_id === 1">
                                <p class="text-xs italic text-red-500" v-if="errors && errors['fields.'+index+'.parameters.is_required']">{{ errors['fields.'+index+'.parameters.is_required'][0] }}</p>
                            </div>

                            <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="element.type !== 'hidden' && element.type !== 'query_param'">
                                <form-input label="Label" :id="'fields_'+index+'_label'" :name="'fields_'+index+'_label'" type="text" v-model="element.parameters.label" />
                                <form-validation :param="'fields.'+index+'.parameters.label'" :errors="errors" />
                            </div>
                            <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="element.type === 'hidden'">
                                <form-input label="Value" :id="'fields_'+index+'_value'" :name="'fields_'+index+'_value'" type="text" v-model="element.parameters.value" />
                                <form-validation :param="'fields.'+index+'.parameters.value'" :errors="errors" />
                            </div>
                            <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="element.type === 'query_param'">
                                <form-input label="Param" :id="'fields_'+index+'_param'" :name="'fields_'+index+'_param'" type="text" v-model="element.parameters.param" />
                                <form-validation :param="'fields.'+index+'.parameters.param'" :errors="errors" />
                            </div>

                            <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="element.type !== 'checkbox' && element.type !== 'hidden'">
                                <form-input label="Default Value" :id="'fields_'+index+'_default_value'" :name="'fields_'+index+'_default_value'" type="text" v-model="element.parameters.default_value" />
                                <form-validation :param="'fields.'+index+'.parameters.default_value'" :errors="errors" />
                            </div>
                            <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="element.type === 'checkbox' || element.type === 'hidden'">

                            </div>

                            <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="element.type !== 'checkbox' && element.type !== 'hidden' && element.type !== 'query_param'">
                                <form-input label="Placeholder" :id="'fields_'+index+'_placeholder'" :name="'fields_'+index+'_placeholder'" type="text" v-model="element.parameters.placeholder" />
                                <form-validation :param="'fields.'+index+'.parameters.placeholder'" :errors="errors" />
                            </div>
                            <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="element.type === 'checkbox' || element.type === 'hidden' || element.type === 'query_param'">

                            </div>

                            <div class="flex-initial h-full pl-6 mt-6 text-right cursor-pointer">
                                <form-button negative-action @click="removeField(index)" v-if="element.attribute_id !== 1"><i class="fas fa-minus-circle"></i></form-button>
                            </div>
                        </div>
                    </template>
                </draggable>

                <div class="flex flex-wrap mx-3 my-6">
                    <form-button positive-action @click="addField()"><i class="fas fa-plus-circle"></i> ADD</form-button>
                </div>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Update Webform
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormTextarea from '../../shared/form/TextArea';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import draggable from 'vuedraggable'

    export default {
        name: 'CreateWebform',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormTextarea,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            draggable,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetchAttributes()
            this.fetchSequences()
            this.fetchWebform()
        },
        computed: {
            id () {
                return this.$route.params.id;
            },
        },
        data() {
            return {
                attributes: [],
                sequences: [],
                webform: {
                    number_id: this.$store.getters.current_number_id,
                    name: '',
                    status: 'active',
                    captcha_type: 'none',
                    sequence_id: null,
                    success_type: 'send_message',
                    success_message: '',
                    success_redirect_url: '',
                    mobile_optin_language: 'By providing my phone number, I consent to receive periodic text message alerts from CAMPAIGN NAME.  Carrier message & data rates may apply. Text STOP to '+this.$store.getters.current_number.number+' to stop receiving messages. Text HELP to '+this.$store.getters.current_number.number+' for more information. Terms & Conditions',
                    urls: [
                        {
                            url: '',
                        }
                    ],
                    fields: [
                        {
                            attribute_id: 1,
                            type: 'input',
                            parameters: {
                                is_required: true,
                                label: 'Phone Number',
                                param: '',
                                placeholder: '555-555-5555',
                                default_value: '',
                                value: '',
                            },
                        }
                    ],
                },
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchWebform(data) {
                this.$http.get(`/api/v1/webforms/${this.id}`).then(this.refreshWebform)
            },
            refreshWebform({data}) {
                this.webform = data.data;
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?number_id=${this.$store.getters.current_number_id}&per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({ data }) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                return this.$http.get(`/api/v1/attributes?per_page=-1&search_scopes=client`).then(this.refreshAttributes);
            },
            refreshAttributes({ data }) {
                this.attributes = data.data;
            },
            save() {
              this.$http.put('/api/v1/webforms/'+this.id, this.webform).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The webform has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_webforms'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while updating the webform',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addURL() {
                this.webform.urls.push({
                    url: '',
                })
            },
            removeURL(index) {
                this.webform.urls.splice(index, 1);
            },
            addField() {
                this.webform.fields.push({
                    attribute_id: null,
                    type: 'input',
                    parameters: {
                        is_required: false,
                        label: '',
                        param: '',
                        placeholder: '',
                        default_value: '',
                        value: '',
                    },
                });
            },
            removeField(index) {
                this.webform.fields.splice(index, 1);
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
