<template>
    <div class="mb-3">
        <div class="mb-2">
            <form-label :id="name" :label="label"></form-label>
            <div class="float-right">
                <a class="btn-small" @click="showModal()" v-if="showAttributeOptions">{Attribute}</a>
            </div>
        </div>
        <textarea
            class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-lg shadow-sm py-2 px-4 mb-3 focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple"
            :id="name"
            :name="name"
            ref="refTextarea"
            v-model="text"
            @click="checkSelection($event)"
            @keyup="checkSelection($event);"
        ></textarea>
        <p class="text-xs italic mb-2" :class="{'text-red-600': remainingWarn}" v-if="max">{{ remaining }}</p>

        <DynamicTagModal
            :show="open_tag_modal"
            :attributes="attributes"
            v-model:tag="tag"
            @add="(tag) => { insertTag(tag); tag.attribute_id = null; tag.default_value = ''; }"
            @cancel="open_tag_modal = false; tag.attribute_id = null; tag.default_value = '';"
        />
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import FormLabel from './form/Label'
    import DynamicTagModal from './modal/DynamicTagModal'

    export default {
        name: 'DynamicTextarea',
        components: {
            FormLabel,
            DynamicTagModal,
        },
        emits: [
            'update:modelValue',
            'update:type',
        ],
        data() {
            return {
                tag: {
                    attribute_id: null,
                    default_value: '',
                },
                remainingWarn: false,
                open_tag_modal: false,
            }
        },
        setup() {
            const refTextarea = ref(null);

            return { refTextarea };
        },
        methods: {
            showModal() {
                this.open_tag_modal = true;
            },
            insertTag(tag) {
                let start = this.refTextarea.selectionStart;
                let end = this.refTextarea.selectionEnd;
                let value = this.text;
                let prefix = value.substring(0, start);
                let postfix = value.substring(end, value.length);
                let result = prefix + '{{attribute_id='+tag.attribute_id;

                if (tag.default_value !== '') {
                    result += ';default='+tag.default_value;
                }

                result += '}}' + postfix;

                this.text = result;
                this.open_tag_modal = false;
            },
            checkSelection(event) {
                if (event.type === 'key' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
                    return;
                }

                let cursor = this.refTextarea.selectionStart;
                let search = RegExp('{{.*?}}','g');
                let match = null;

                while ((match = search.exec(this.text)) !== null) {
                    let start = match.index;
                    let end = start + match[0].length;

                    if (cursor > start && cursor < end) {
                        this.refTextarea.focus();
                        this.refTextarea.setSelectionRange(start, end, 'forward');

                        if (event.type === 'click') {
                            let parts = match[0].replace(/^{{|}}$/g, '').split(';');

                                let attribute = parts[0].split('=');
                                let default_value = (parts.length === 2 ? parts[1].split('=') : ['default', '']);

                                if (attribute[0] === 'attribute_id') {
                                    this.tag.attribute_id = attribute[1];
                                    this.tag.default_value = default_value[1];

                                    this.open_tag_modal = true;
                                } else if(attribute[0] === 'pl') {
                                    this.pl_tag.name = attribute[1];
                                    this.pl_tag.default_value = default_value[1];

                                    this.open_locator_modal = true;
                                } else if(attribute[0] === 'link_id') {
                                    this.showLinkShortener().then(() => {
                                        this.getLinkDetails(attribute[1]);
                                    })
                                } else if(attribute[0] === 'res') {
                                    this.res_tag.name = attribute[1];
                                    this.res_tag.default_value = default_value[1];

                                    this.open_results_modal = true;
                                }


                        }

                        break;
                    }
                }
            },
        },
        computed: {
            text: {
                get() {
                    return this.modelValue;
                },
                set(v) {
                    this.$emit('update:modelValue', v)
                }
            },
            remaining: {
                get() {
                    let search = RegExp('{{.*?}}','g');

                    if (this.text !== null && this.text.match(search)) {
                        this.remainingWarn = false;

                        return 'Character counting not available with dynamic tokens'
                    } else {
                        let characters = this.max - (this.text === null ? 0 : this.text.length);
                        this.remainingWarn = (characters < 0 ? true : false);

                        return ((characters < 0) ? Math.abs(characters) + ' characters over' : characters + ' characters remaining');
                    }
                }
            },
        },
        props: {
            name: {
                required: true
            },
            modelValue: {
                required: true,
                type: String,
            },
            label: {
                required: true
            },
            max: {
                required: true,
            },
            showAttributeOptions: {
                type: Boolean,
                default: true,
            },
            attributes: Array,
        }
    };
</script>

<style scoped>
    .btn-small {
        @apply bg-sbr-orange text-white font-bold p-1 text-xs cursor-pointer rounded mr-2 shadow
    }
</style>
