<template>
    <div class="inbox-message col-start-1 col-end-8 p-3 rounded-lg" v-bind:class="{'col-start-6 col-end-13': message.type === 'outbound', 'col-start-1 col-end-8': message.type === 'inbound'}">
        <div class="flex items-center" v-bind:class="{'justify-start flex-row-reverse': message.type === 'outbound', 'flex-row': message.type === 'inbound'}">
            <Popper :content="$filters.formatDate(message.updated_at)" :hover="true">
                <div class="flex items-center justify-center h-10 w-10 rounded-full bg-sbr-purple text-white flex-shrink-0">
                    <i class="fas fa-robot" v-if="message.type === 'outbound'"></i>
                    <i class="far fa-user" v-if="message.type !== 'outbound'"></i>
                </div>
            </Popper>
            <div class="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl flex flex-col" v-bind:class="{'mr-3 bg-sbr-light-purple': message.type === 'outbound', 'ml-3 bg-gray-200': message.type === 'inbound'}">
                <div>
                    {{ message.text || '(no text)' }}
                </div>
                <div v-if="message.media && message.media.length > 0">
                    <span class="btn inline-block relative text-gray-700 text-center bg-gray-400 px-4 py-2 m-2" v-for="(file, file_index) in message.media" :key="file_index" :index="file_index">
                        <a @click="previewAttachment(file.url, file.type)" href="javascript:void(0)" v-if="file.type === 'image'">
                            <img :src="file.url" width="40" height="40" />
                        </a>
                        <a @click="previewAttachment(file.url, file.type)" href="javascript:void(0)" v-if="file.type === 'audio'">
                            <audio :src="file.url" width="40" height="40" />
                        </a>
                        <a @click="previewAttachment(file.url, file.type)" href="javascript:void(0)" v-if="file.type === 'video'">
                            <video :src="file.url" width="40" height="40" />
                        </a>
                    </span>
                </div>
                <div>
                    <span class="text-xs italic">{{ $filters.formatDate(message.updated_at) }}</span>
                </div>
                <!--<div v-if="message.attachments === null">{{ message.text }}</div>
                <div v-if="message.attachments !== null">
                    <div v-for="(attachment, attachment_index) in message.attachments" v-bind:key="attachment_index" >
                        <img :src="attachment.payload.url" width="60" height="60" class="pr-2" v-if="attachment.type === 'image'" />
                        <audio controls :src="attachment.payload.url" class="pr-2" v-if="attachment.type === 'audio'"></audio>
                        <video controls width="70%" :src="attachment.payload.url" class="pr-2" v-if="attachment.type === 'video'"></video>
                    </div>
                </div>-->
            </div>
        </div>

        <PreviewAttachmentModal
            :show="open_preview_modal"
            :previewAttachmentUrl="previewAttachmentUrl"
            :previewAttachmentType="previewAttachmentType"
            @cancel="open_preview_modal = false; previewAttachmentUrl = null; previewAttachmentType = null;"
        />
    </div>
</template>

<style>
  :root {
    --popper-theme-background-color: #fff;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 4px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>

<script>
    import Popper from "vue3-popper";
    import PreviewAttachmentModal from '../../../shared/modal/PreviewAttachmentModal'

    export default {
        name: 'list-message',
        props: ['message'],
        components: {
            Popper,
            PreviewAttachmentModal,
        },
        created() {},
        data() {
            return {
                open_preview_modal: false,
                previewAttachmentUrl: '',
                previewAttachmentType: '',
            }
        },
        methods: {
            previewAttachment(url, type) {
                this.previewAttachmentUrl = url;
                this.previewAttachmentType = type;
                this.open_preview_modal = true;
            },
        }
    }

</script>
