<template>
    <div>
        <layout-header title="Inbox">
        </layout-header>

        <filter-filter cols.number="2">
            <filter-column>
                <form-input id="search" name="search" label="" v-model="search" placeholder="Search by number and press ↵" @keyupenter="updateSearch()"></form-input>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_inbox_statuses"
                    :options="inbox_statuses"
                    :searchable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    :multiple="true"
                    :preserve-search="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Status"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
        </filter-filter>

        <div class="m-5 h-screen2/3">
            <div class="flex flex-row h-screen2/3">
                <div v-if="loading" class="p-4 ">
                    <p>Loading inbox, please wait</p>
                </div>
                <div v-if="!loading && inbox.length < 1" class="p-4 ">
                    <p>No inbox conversations available for given status.  Filter by status above to see messages.</p>
                </div>

                <!-- Number Listing -->
                <div class="flex-none flex-col md:w-48 shadow-sm my-6 rounded-lg border-2 border-gray-200 bg-gray-100 mr-3 h-full overflow-y-auto" v-if="!loading && inbox.length > 0">
                    <list-person v-for="person in inbox" v-bind:key="person.id" v-bind:person="person" v-bind:selected="selected_person" v-on:select-person="selectPerson(person)"></list-person>
                </div>

                <!-- Message Listing -->
                <div class="flex flex-grow flex-col h-full my-6 rounded-lg border-2 border-gray-200 bg-gray-100 shadow-sm  mx-3" v-if="selected_person !== null">
                    <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-white h-full">
                        <div class="flex flex-row items-center h-16 rounded-xl bg-sbr-light-purple w-full p-2 pl-4" v-if="messages.length > 0">
                            <div class="flex-grow ml-4">
                                <div class="relative w-full font-bold">
                                    {{ $filters.formatPhone(selected_person.phone_number) }}
                                </div>
                            </div>
                            <div class="ml-4 flex flex-row">
                                <form-button title="Trigger Sequence" :disabled="selected_person === null" @click="triggerSequenceDialog(selected_person.id)">
                                    <i class="fas fa-sort-amount-down"></i>
                                </form-button>
                                <form-button title="Unsubscribe" :disabled="selected_person === null" @click="confirmUnsubscribe(selected_person.id)">
                                    <i class="fas fa-ban"></i>
                                </form-button>
                            </div>
                        </div>
                        <div class="flex flex-col h-full overflow-y-auto shadow-inner">
                            <div class="flex flex-col h-full">
                                <div class="grid grid-cols-12 gap-y-2">
                                    <list-message v-for="message in messages" :key="message.id" v-bind:message="message"></list-message>
                                    <div class="inbox-message-footer"></div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row  h-16 rounded-xl bg-white w-full bg-gray-200 pt-2" v-if="messages.length > 0">
                            <!--<div>
                                <button class="flex items-center justify-center text-gray-400 hover:text-gray-600" :disabled="selected_person === null">
                                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path>
                                    </svg>
                                </button>
                            </div>-->
                            <div class="flex-grow ml-4">
                                <div class="relative w-full">
                                    <form-input ref="refTextInput" v-model="inbox_message_send_text" :disabled="selected_person === null" @keyup="updateSendText" />
                                    <Popper>
                                        <button class="absolute flex items-center justify-center h-10 w-12 right-0 top-0 text-gray-400 hover:text-gray-600" :disabled="selected_person === null">
                                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                        </button>
                                        <template #content>
                                            <EmojiPicker :native="true" @select="onSelectEmoji" />
                                        </template>
                                    </Popper>
                                </div>
                            </div>
                            <div class="ml-4">
                                <form-button :disabled="selected_person === null || !can_send_message" @click="sendMessage()">
                                    <div class="flex flex-row">
                                        <span>Send</span>
                                        <span class="ml-2 pt-1">
                                            <svg class="w-4 h-4 transform rotate-90 -mt-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </form-button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Profile Details -->
                <div class="flex-none flex-col my-6 hidden lg:block">
                    <div class="flex-none flex-row w-64 mb-4 rounded-lg border-2 border-gray-200 bg-gray-100 shadow-sm mx-3 p-3" v-if="selected_person !== null">
                        <h5>Inbox Status</h5>
                        <form-select label="" name="inbox_status" v-model="selected_person.inbox_status" @change="updateInboxStatus()">
                            <option value="waiting">Waiting</option>
                            <option value="active">Active</option>
                            <option value="complete">Complete</option>
                        </form-select>
                    </div>
                    <div class="flex-none flex-row w-64 mb-4 rounded-lg border-2 border-gray-200 bg-gray-100 shadow-sm mx-3 p-3" v-if="selected_person !== null">
                        <h5>Segments</h5>
                        <div class="flex p-2" v-for="segment in selected_person.segments" v-bind:key="segment.id">
                            <div class="w-5/6 text-left text-sm text-left">{{ segment.name }}<br /><span class="text-xs text-gray-700">{{ $filters.formatDate(segment.pivot.added_at) }}</span></div>
                        </div>
                    </div>
                    <div class="flex-none flex-row w-64 mb-4 rounded-lg border-2 border-gray-200 bg-gray-100 shadow-sm mx-3 p-3" v-if="selected_person !== null">
                        <h5>Attributes</h5>
                        <div class="flex p-2" v-for="attribute in selected_person.attributes" v-bind:key="attribute.id">
                            <div class="w-5/6 text-left text-sm text-left">{{ attribute.name }}<br /><span class="text-sm text-gray-700">{{ attribute.pivot.value }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<table class="listing">
                <thead>
                    <tr>
                        <th><a @click="updateSort('id')">ID</a></th>
                        <th><a @click="updateSort('last_name')">From</a></th>
                        <th><a @click="updateSort('text')">Message</a></th>
                        <th><a @click="updateSort('created_at')">Date</a></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="hover:bg-grey-lighter" v-for="inbox in inbox" v-bind:key="inbox.id">
                        <td class="py-4 px-6 border-b border-grey-light">{{ inbox.id }}</td>
                        <td class="py-4 px-6 border-b border-grey-light"><router-link :to="{ name: 'view_person', params: { id: inbox.person_id }}" class="mx-3">{{ inbox.first_name }} {{ inbox.last_name }}</router-link></td>
                        <td class="py-4 px-6 border-b border-grey-light">{{ inbox.text }}</td>
                        <td class="py-4 px-6 border-b border-grey-light">{{ inbox.created_at | formatDate }}</td>
                        <td class="py-4 px-6 border-b border-grey-light">
                            <a href="javascript:void(0);" v-on:click="confirmDelete(inbox.id)" class="mx-3"><i class="fas fa-trash-alt"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>-->
        </div>

        <!--<paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>-->

        <!--<modal name="unsubscribe-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Unsubscribe Person</div>
                    <div class="modal-c-text">Are you sure you want to end this person's subscription.  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('unsubscribe-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="unsubscribe()">Unsubscribe</button>
                </div>
            </div>
        </modal>

        <modal name="delete-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Delete Message</div>
                    <div class="modal-c-text">Are you sure you want to delete this message from the Inbox?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('delete-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="deleteInbox()">Delete</button>
                </div>
            </div>
        </modal>

        <modal name="trigger-sequence" class="confirm-modal" :height="300">
            <div class="modal-container">
                <div class="inline-block align-bottom bg-white text-left">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="mt-3 mx-4">
                            <h3 class="modal-c-title">Trigger Sequence</h3>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500 pb-5">Select a sequence to trigger for the user, and close their inbox interaction.</p>

                                <div class="w-full">
                                    <div class="pt-2">
                                        <label for="sequence" class="form-label">Attribute</label>
                                        <div class="relative">
                                            <select class="form-select" id="sequence" name="sequence" v-model="trigger_sequence_id">
                                                <option :value="null">- Select Sequence -</option>
                                                <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                        <p class="text-red-500 text-xs italic" v-if="trigger_sequence_errors && trigger_sequence_errors.sequence_id">{{ trigger_sequence_errors.sequence_id[0] }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse absolute inset-x-0 bottom-0">
                        <button type="button" class="modal-button-primary m-3" @click="triggerSequence()">
                            Trigger
                        </button>
                        <button type="button" class="modal-button m-3" @click="$modal.hide('trigger-sequence')">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </modal>-->

        <TriggerSequenceModal
            name="trigger-sequence"
            actionText="Trigger"
            :sequences="sequences"
            v-model:triggerSequenceID="trigger_sequence_id"
            :errors="trigger_sequence_errors"
            :show="open_trigger_sequence_modal"
            @trigger="triggerSequence()"
            @cancel="open_trigger_sequence_modal = false"
        />

        <ConfirmModal
            name="unsubscribe-confirm"
            title="Unsubscribe Person?"
            description="Are you sure you want to end this person's subscription.  This action cannot be undone."
            actionText="Unsubscribe"
            :show="open_unsubscribe_modal"
            @confirm="unsubscribe()"
            @cancel="open_unsubscribe_modal = false"
        />
    </div>
</template>

<style scoped>
    .inline-block {
        position: absolute;
        top: 0;
        right: 0;
    }
</style>

<script>
    import { shallowRef, ref } from 'vue';
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import LayoutButton from '../../shared/layout/Button'
    import FilterFilter from '../../shared/filter/Filter'
    import FilterColumn from '../../shared/filter/Column'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'
    import FormButton from '../../shared/form/Button'
    import ListPerson from './components/ListPerson'
    import ListMessage from './components/ListMessage'
    import ConfirmModal from '../../shared/modal/ConfirmModal'
    import TriggerSequenceModal from '../../shared/modal/TriggerSequenceModal'

    import Popper from "vue3-popper";
    import EmojiPicker from 'vue3-emoji-picker'
    import 'vue3-emoji-picker/css'

    export default {
        name: 'ListInbox',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            FilterFilter,
            FilterColumn,
            FormSelect,
            FormInput,
            FormButton,
            ListPerson,
            ListMessage,
            Popper,
            EmojiPicker,
            ConfirmModal,
            TriggerSequenceModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetch(1);
            this.fetchSequences();

            window.Echo.private(`inbox.update`).listen('.inbox.notification', (e) => {
                this.fetch(1);
            });
        },
        updated: function () {
            this.$nextTick(function () {
                if (this.scroll_messages) {
                    let el = this.$el.getElementsByClassName('inbox-message-footer')[0];

                    if (el) {
                        el.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    }

                    this.scroll_messages = false
                }
            })
        },
        setup() {
            const refTextInput = ref(null);
            return { refTextInput };
        },
        data() {
            return {
                loading: true,
                inbox: [],
                delete_id: null,
                unsubscribe_id: null,
                trigger_sequence_person_id: null,
                trigger_sequence_id: null,
                trigger_sequence_errors: [],
                page_count: 1,
                sort: 'desc',
                order: 'id',
                messages: [],
                sequences: [],
                search: '',
                search_inbox_statuses: [],
                messages_page_count: 1,
                selected_person: null,
                scroll_messages: false,
                inbox_message_send_text: '',
                inbox_statuses: [
                    {id: 'active', name: 'Active'},
                    {id: 'waiting', name: 'Waiting'},
                    {id: 'complete', name: 'Complete'},
                ],
                can_send_message: false,
                open_trigger_sequence_modal: false,
                open_unsubscribe_modal: false,
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_inbox_statuses = this.search_inbox_statuses.map(status => status.id)

                this.$http.get(`/api/v1/inbox?number_id=${this.$store.getters.current_number_id}&page=${this.current_page}&sort=${this.sort}&order=${this.order}&search_inbox_statuses=${search_inbox_statuses}`).then(this.refresh);
            },
            refresh({data}) {
                this.inbox = data.data;
                this.page_count = data.meta.pages;
                //this.selected_person = null;
                this.loading = false;
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?number_id=${this.$store.getters.current_number_id}&per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchMessages(person_id, page, sort, order) {
                this.$http.get(`/api/v1/inbox/${person_id}?number_id=${this.$store.getters.current_number_id}&page=${page}&sort=${sort}&order=${order}`).then(this.refreshMessages);
            },
            refreshMessages({data}) {
                this.messages = data.data.slice().reverse();
                this.messages_page_count = data.meta.pages;

                if (data.meta.page === 1) {
                    this.scroll_messages = true

                    window.Echo.private(`inbox.${this.selected_person.id}`).listen('.inbox.notification', (e) => {
                        console.log(e)
                        this.messages.push(e.message)
                        // TODO: Update person here so we get the latest info?
                        this.scroll_messages = true
                    });

                    window.scrollTo(0,0);
                }
            },
            sendMessage() {
                let message = {
                    text: this.inbox_message_send_text
                }

                this.$http.post(`/api/v1/inbox/${this.selected_person.id}/reply?number_id=${this.$store.getters.current_number_id}`, message).then(this.refreshSentMessage);
            },
            refreshSentMessage({data}) {
                this.inbox_message_send_text = '';
            },
            updateSendText(e) {
                if (e.target.value !== '') {
                    this.can_send_message = true;
                } else {
                    this.can_send_message = false;
                }
            },
            confirmUnsubscribe(id) {
                this.unsubscribe_id = id;

                this.open_unsubscribe_modal = true;
            },
            unsubscribe() {
                this.$http.post(`/api/v1/people/${this.unsubscribe_id}/unsubscribe?number_id=${this.$store.getters.current_number_id}&`).then(this.postUnsubscribe);
            },
            postUnsubscribe() {
                this.open_unsubscribe_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The subscriber has been unsubscribed',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
                this.$emitter.emit('inbox_updated');
                this.selected_person = null;
            },
            triggerSequenceDialog(id) {
                this.trigger_sequence_person_id = id;
                this.trigger_sequence_id = null;
                this.trigger_sequence_errors = []

                this.open_trigger_sequence_modal = true;
            },
            triggerSequence() {
                this.trigger_sequence_errors = []

                this.$http.post(`/api/v1/people/${this.trigger_sequence_person_id}/sequence/${this.trigger_sequence_id}?number_id=${this.$store.getters.current_number_id}&`).then(this.postTriggerSequence).catch(error => {
                    if (error.response.status === 422) {
                        this.trigger_sequence_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while triggering the sequence',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postTriggerSequence() {
                this.fetch(1);
                this.$emitter.emit('inbox_updated');
                this.selected_person = null;
                this.open_trigger_sequence_modal = false;

                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The sequence has been triggered',
                    duration: 5000,
                    speed: 1000
                });
            },
            confirmDelete(id) {
                this.delete_id = id;

                this.$modal.show('delete-confirm');
            },
            deleteInbox() {
                this.$http.delete(`/api/v1/inbox/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.$modal.hide('delete-confirm');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The inbox message has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
                this.selected_person = null;
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            onSelectEmoji(text) {
                let start = this.refTextInput.selectionStart;
                let end = this.refTextInput.selectionEnd;
                let value = this.inbox_message_send_text;

                let prefix = value.substring(0, start);
                let postfix = value.substring(end, value.length);
                let result = prefix + text.i + postfix;

                this.inbox_message_send_text = result;
            },




            selectPerson(person) {
                if (this.selected_person !== null) {
                    window.Echo.leaveChannel(`inbox.${this.selected_person.id}`);
                }

                this.selected_person = person
                this.fetchMessages(person.id, 1, 'desc', 'id')
            },
            updateInboxStatus() {
                let status = {
                    inbox_status: this.selected_person.inbox_status
                }

                this.$http.post(`/api/v1/inbox/${this.selected_person.id}/status?number_id=${this.$store.getters.current_number_id}`, status).then(this.refreshInboxStatus);
            },
            refreshInboxStatus({data}) {
                if (this.selected_person.inbox_status === 'complete') {
                    this.fetch(1)
                    this.$emitter.emit('inbox_updated');
                }
            },
            updateSearch() {
                this.fetch(1);
            },
        }
    }
</script>
