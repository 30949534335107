import { createRouter, createWebHistory } from 'vue-router';

import Home from './components/app/Home';

import ListNumbers from './components/app/numbers/ListNumbers';
import CreateNumber from './components/app/numbers/CreateNumber';
import EditNumber from './components/app/numbers/EditNumber';

import ListSequences from './components/app/sequences/ListSequences';
import CreateSequence from './components/app/sequences/CreateSequence';
import EditSequence from './components/app/sequences/EditSequence';

import ListAttributes from './components/app/attributes/ListAttributes';
import CreateAttribute from './components/app/attributes/CreateAttribute';
import EditAttribute from './components/app/attributes/EditAttribute';

import ListSegments from './components/app/segments/ListSegments';
import CreateSegment from './components/app/segments/CreateSegment';
import EditSegment from './components/app/segments/EditSegment';

import ListWebforms from './components/app/webforms/ListWebforms';
import CreateWebform from './components/app/webforms/CreateWebform';
import EditWebform from './components/app/webforms/EditWebform';

import ListBroadcasts from './components/app/broadcasts/ListBroadcasts';
import CreateBroadcast from './components/app/broadcasts/CreateBroadcast';
import EditBroadcast from './components/app/broadcasts/EditBroadcast';

import ListReports from './components/app/reports/ListReports';
import CreateReport from './components/app/reports/CreateReport';
import EditReport from './components/app/reports/EditReport';

import ListInbox from './components/app/inbox/ListInbox';
import ListPeople from './components/app/people/ListPeople';
import ViewPerson from './components/app/people/ViewPerson';

import ListImports from './components/app/imports/ListImports';
import CreateImport from './components/app/imports/CreateImport';

import ListIntegrations from './components/app/integrations/ListIntegrations';

import ListFiles from './components/app/files/ListFiles';

import NotFound from './components/app/NotFound';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            component: NotFound
        },
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/numbers',
            name: 'list_numbers',
            component: ListNumbers
        },
        {
            path: '/numbers/create',
            name: 'create_number',
            component: CreateNumber
        },
        {
            path: '/numbers/edit/:id',
            name: 'edit_number',
            component: EditNumber,
            props: true
        },
        {
            path: '/sequences',
            name: 'list_sequences',
            component: ListSequences
        },
        {
            path: '/sequences/create',
            name: 'create_sequence',
            component: CreateSequence
        },
        {
            path: '/sequences/edit/:id',
            name: 'edit_sequence',
            component: EditSequence,
            props: true
        },
        {
            path: '/attributes',
            name: 'list_attributes',
            component: ListAttributes
        },
        {
            path: '/attributes/create',
            name: 'create_attribute',
            component: CreateAttribute
        },
        {
            path: '/attributes/edit/:id',
            name: 'edit_attribute',
            component: EditAttribute,
            props: true
        },
        {
            path: '/segments',
            name: 'list_segments',
            component: ListSegments
        },
        {
            path: '/segments/create',
            name: 'create_segment',
            component: CreateSegment
        },
        {
            path: '/segments/edit/:id',
            name: 'edit_segment',
            component: EditSegment,
            props: true
        },
        {
            path: '/webforms',
            name: 'list_webforms',
            component: ListWebforms
        },
        {
            path: '/webforms/create',
            name: 'create_webform',
            component: CreateWebform
        },
        {
            path: '/webforms/edit/:id',
            name: 'edit_webform',
            component: EditWebform,
            props: true
        },
        {
            path: '/broadcasts',
            name: 'list_broadcasts',
            component: ListBroadcasts
        },
        {
            path: '/broadcasts/create',
            name: 'create_broadcast',
            component: CreateBroadcast
        },
        {
            path: '/broadcasts/edit/:id',
            name: 'edit_broadcast',
            component: EditBroadcast,
            props: true
        },
        {
            path: '/reports',
            name: 'list_reports',
            component: ListReports
        },
        {
            path: '/reports/create',
            name: 'create_report',
            component: CreateReport
        },
        {
            path: '/reports/edit/:id',
            name: 'edit_report',
            component: EditReport,
            props: true
        },
        {
            path: '/inbox',
            name: 'list_inbox',
            component: ListInbox
        },
        {
            path: '/people',
            name: 'list_people',
            component: ListPeople
        },
        {
            path: '/people/view/:id',
            name: 'view_person',
            component: ViewPerson,
            props: true
        },
        {
            path: '/integrations',
            name: 'list_integrations',
            component: ListIntegrations
        },
        {
            path: '/imports',
            name: 'list_imports',
            component: ListImports,
        },
        {
            path: '/imports/create',
            name: 'create_import',
            component: CreateImport,
        },
        {
            path: '/imports/edit/:id',
            name: 'edit_import',
            component: CreateImport,
        },
        {
            path: '/files',
            name: 'list_files',
            component: ListFiles,
        },
    ]
});

export default router;
