<template>
    <p class="text-red-500 text-xs italic" v-if="hasErrors">{{ showErrors }}</p>
</template>

<script>

    export default {
        name: 'ValidationNotice',
        computed: {
            hasErrors: function() {
                if (_.get(this.errors, this.param) !== undefined) {
                    return true;
                } else {
                    return false;
                }
            },
            showErrors: function() {
                return _.get(this.errors, this.param)[0];
            }
        },
        props: {
            param: {
                required: true
            },
            errors: {
                required: true
            }
        }
    };
</script>
