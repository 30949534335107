<template>
    <div>
        <layout-header title="Create New Segment"></layout-header>

        <form-section title="Segment Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="segment.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="segment.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="type" name="type" label="Type" v-model="segment.type">
                    <option value="static">Static</option>
                    <option value="dynamic">Dynamic</option>
                </form-select>
                <form-validation param="type" :errors="errors" />
            </form-field>
        </form-section>

        <form-section title="Dynamic Segment Details" v-if="segment.type === 'dynamic'">
            <form-field wide>
                <p class="text-red-500 text-xs italic" v-for="(error, index) in errors.dynamic_body" v-bind:key="index">{{ error }}</p>
            </form-field>

            <form-field wide>
                <div class="" v-for="(item, index) in segment.dynamic_body.items" v-bind:key="index">
                    <div class="border-solid border-2 border-gray-600 bg-gray-300 mb-4 mr-4">
                        <div class="flex flex-wrap my-3" v-for="(row, row_index) in item.items" v-bind:key="row_index">
                            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                                <div class="relative">
                                    <form-select v-model="row.attribute_id" @change="updateAttributeValue(row_index, index)">
                                        <option :value="null">- Select Attribute -</option>
                                        <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                    </form-select>
                                </div>
                            </div>
                            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                                <div class="relative" v-if="row.attribute_id">
                                    <form-select v-model="row.operator">
                                        <option :value="null">- Select Operator -</option>
                                        <option value="=">is equal to</option>
                                        <option value="!=">is not equal to</option>
                                        <option value="<">is less than</option>
                                        <option value="<=">is less than or equal to</option>
                                        <option value=">">is greater than</option>
                                        <option value=">=">is greater than or equal to</option>
                                    </form-select>
                                </div>
                            </div>
                            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                                <form-input v-model="row.value" v-if="row.attribute_id && row.attribute.values_type === 'open'"></form-input>
                                <div class="relative" v-if="row.attribute && row.attribute.values_type === 'predefined'">
                                    <form-select v-model="row.value">
                                        <option :value="null">- Select Value -</option>
                                        <option v-for="(value, value_index) in row.attribute.valid_values.values" v-bind:key="value_index" :value="value.value">{{ value.label || value.value }}</option>
                                    </form-select>
                                </div>
                            </div>
                            <div class="w-full md:w-1/6 cursor-pointer h-full pl-6">
                                <form-button positive-action @click="addRow(index, row_index)"><i class="fas fa-plus-circle"></i> OR</form-button>
                            </div>
                            <div class="w-full md:w-1/12 cursor-pointer h-full">
                                <form-button negative-action @click="removeRow(row_index, index)"><i class="fas fa-minus-circle"></i></form-button>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap mx-3 mb-6">
                        <form-button positive-action @click="addBlock(index)"><i class="fas fa-plus-circle"></i> AND</form-button>
                    </div>
                </div>

                <div class="flex flex-wrap mx-3 mb-6" v-if="segment.dynamic_body.items.length === 0">
                    <form-button @click="addBlock(0)">SETUP</form-button>
                </div>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Create Segment
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'CreateSegment',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetchAttributes()
        },
        data() {
            return {
                attributes: [],
                segment: {
                  name: '',
                  number_id: this.$store.getters.current_number_id,
                  status: 'active',
                  type: 'static',
                  dynamic_body: {
                      operator: 'AND',
                      items: []
                  }
                },
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchAttributes() {
                this.$http.get(`/api/v1/attributes`).then(this.refreshAttributes);
            },
            refreshAttributes({ data }) {
                this.attributes = data.data;
            },
            save() {
              let item = JSON.parse(JSON.stringify(this.segment));

              if (this.segment.type === 'dynamic') {
                for (let i = 0 ; i < item.dynamic_body.items.length ; ++i) {
                    for (let j = 0 ; j < item.dynamic_body.items[i].items.length ; ++j) {
                        if (item.dynamic_body.items[i].items[j].attribute) {
                            item.dynamic_body.items[i].items[j].attribute_id = item.dynamic_body.items[i].items[j].attribute.id;
                            delete item.dynamic_body.items[i].items[j].attribute;
                        } else {

                        }
                    }
                }
              }

              this.$http.post('/api/v1/segments', item).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The segment has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_segments'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while creating the segment',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addBlock(index) {
                this.segment.dynamic_body.items.splice(index + 1, 0, {
                    operator: 'OR',
                    items: [{
                        attribute: null,
                        attribute_id: null,
                        operator: '=',
                        value: null
                    }]
                })
            },
            addRow(block_index, row_index) {
                this.segment.dynamic_body.items[block_index].items.splice(row_index + 1, 0, {
                    attribute: null,
                    attribute_id: null,
                    operator: '=',
                    value: null
                })
            },
            removeRow(index, block_index) {
                this.segment.dynamic_body.items[block_index].items.splice(index, 1);

                if (this.segment.dynamic_body.items[block_index].items.length === 0) {
                    this.segment.dynamic_body.items.splice(block_index, 1)
                }
            },
            updateAttributeValue(row_index, block_index) {
                this.segment.dynamic_body.items[block_index].items[row_index].attribute = null;

                for (let attribute_index in this.attributes) {
                    if (this.attributes[attribute_index].id === this.segment.dynamic_body.items[block_index].items[row_index].attribute_id) {
                        this.segment.dynamic_body.items[block_index].items[row_index].attribute = this.attributes[attribute_index];
                        break;
                    }
                }

                this.segment.dynamic_body.items[block_index].items[row_index].value = null
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
