<template>
    <div>
        <layout-header title="Sequences">
            <layout-button href="/sequences/create">Create New Sequence</layout-button>
        </layout-header>

        <filter-filter cols.number="2">
            <filter-column>
                <form-input id="search" name="search" label="" v-model="search" placeholder="Search by name and press ↵" @keyupenter="updateSearch()"></form-input>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_statuses"
                    :options="statuses"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Status"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
        </filter-filter>

        <table-table>
            <template v-slot:header>
                <table-row type="header">
                    <table-header @click="updateSort('id')" hide-sm>ID</table-header>
                    <table-header @click="updateSort('name')">Name</table-header>
                    <table-header hide-sm>Keywords</table-header>
                    <table-header @click="updateSort('status')" hide-md>Status</table-header>
                    <table-header type="text" last>Actions</table-header>
                </table-row>
            </template>
            <template v-slot:body>
                <table-row v-for="(sequence, index) in sequences" :key="sequence.id" :index="index">
                    <table-cell hide-sm bold>{{ sequence.id }}</table-cell>
                    <table-cell>{{ sequence.name }}</table-cell>
                    <table-cell hide-sm>
                        <span class="bg-sbr-purple text-white font-bold py-1 px-2 rounded-full text-sm mx-1" v-for="keyword in sequence.keywords" v-bind:key="keyword.id">{{ keyword.keyword }}</span>
                    </table-cell>
                    <table-cell hide-md>
                        <table-status-badge :status="sequence.status"></table-status-badge>
                    </table-cell>
                    <table-cell last>
                        <table-edit-link :href="{ name: 'edit_sequence', params: { id: sequence.id }}"></table-edit-link>
                        <table-delete-link @click="confirmDelete(sequence.id)"></table-delete-link>
                    </table-cell>
                </table-row>
            </template>
        </table-table>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <ConfirmModal
            name="delete-confirm"
            height="220"
            title="Delete Sequence?"
            description="Are you sure you want to delete this sequence?  This action cannot be undone."
            actionText="Delete"
            :show="open_delete_modal"
            @confirm="deleteSequence()"
            @cancel="open_delete_modal = false"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import LayoutButton from '../../shared/layout/Button'
    import FilterFilter from '../../shared/filter/Filter'
    import FilterColumn from '../../shared/filter/Column'
    import TableTable from '../../shared/table/Table'
    import TableRow from '../../shared/table/Row'
    import TableHeader from '../../shared/table/Header'
    import TableCell from '../../shared/table/Cell'
    import TableStatusBadge from '../../shared/table/StatusBadge'
    import TableEditLink from '../../shared/table/EditLink'
    import TableDeleteLink from '../../shared/table/DeleteLink'
    import ConfirmModal from '../../shared/modal/ConfirmModal'
    import FormField from '../../shared/form/Field'
    import FormInput from '../../shared/form/Input'
    import FormSelect from '../../shared/form/Select'

    export default {
        name: 'ListSequences',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            FilterFilter,
            FilterColumn,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            TableStatusBadge,
            TableEditLink,
            TableDeleteLink,
            FormField,
            FormInput,
            FormSelect,
            ConfirmModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetch(1)
            this.fetchNumbers()
        },
        data() {
            return {
                sequences: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                numbers: [],
                statuses: [
                    {id: 'active', name: 'Active'},
                    {id: 'inactive', name: 'Inactive'},
                ],
                search: this.$route.query.search || '',
                search_statuses: [],
                search_number_ids: [],
                open_delete_modal: false,
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_number_ids = this.search_number_ids.map(number => number.id)
                let search_statuses = this.search_statuses.map(status => status.id)

                this.$http.get(`/api/v1/sequences?number_id=${this.$store.getters.current_number_id}&page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_statuses=${search_statuses}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.sequences = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            fetchNumbers() {
                this.$http.get(`/api/v1/numbers?per_page=-1`).then(this.refreshNumbers);
            },
            refreshNumbers({data}) {
                this.numbers = data.data;
            },
            confirmDelete(id) {
                this.delete_id = id;
                this.open_delete_modal = true;
            },
            deleteSequence() {
                this.$http.delete(`/api/v1/sequences/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.open_delete_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The sequence has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
        }
    }
</script>
