<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{  title  }}</DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">{{ description }}</p>
                            </div>
                            <form-validation param="request" :errors="errors" />
                            <div class="w-full mt-8">
                                <form-select label="Prompt Type" id="prompt_type" name="prompt_type" v-model="prompt_type">
                                    <option value="donation">Ask for donation</option>
                                    <option value="volunteer">Ask for volunteer</option>
                                    <option value="call_to_action">Call to action</option>
                                    <option value="custom">Custom</option>
                                </form-select>
                            </div>
                            <div class="mt-8" v-if="prompt_type === 'donation'">
                                <form-input label="Donation Amount" id="donation_amount" name="donation_amount" v-model="donation_amount" placeholder="27" />
                            </div>
                            <div class="mt-8" v-if="prompt_type === 'donation'">
                                <form-input label="Donation Deadline" id="donation_deadline" name="donation_deadline" v-model="donation_deadline" placeholder="July 31st" />
                            </div>
                            <div class="mt-8" v-if="prompt_type === 'volunteer'">
                                <form-input label="Volunteer Location" id="volunteer_location" name="volunteer_location" v-model="volunteer_location" placeholder="Missouri River" />
                            </div>
                            <div class="mt-8" v-if="prompt_type === 'volunteer'">
                                <form-input label="Volunteer Ask" id="volunteer_ask" name="volunteer_ask" v-model="volunteer_ask" placeholder="River Cleanup" />
                            </div>
                            <div class="mt-8" v-if="prompt_type === 'volunteer'">
                                <form-input label="Volunteer Date" id="volunteer_date" name="volunteer_date" v-model="volunteer_date" placeholder="July 31st"  />
                            </div>
                            <div class="mt-8" v-if="prompt_type === 'call_to_action'">
                                <form-input label="CTA Location" id="cta_location" name="cta_location" v-model="cta_location" placeholder="St. Louis Arch" />
                            </div>
                            <div class="mt-8" v-if="prompt_type === 'call_to_action'">
                                <form-input label="CTA Ask" id="cta_ask" name="cta_ask" v-model="cta_ask" placeholder="Climate Change" />
                            </div>
                            <div class="mt-8" v-if="prompt_type === 'call_to_action'">
                                <form-input label="CTA Date" id="cta_date" name="cta_date" v-model="cta_date" placeholder="July 31st"  />
                            </div>
                            <div class="w-full mt-8" v-if="prompt_type === 'custom'">
                                <textarea class="overflow-auto bg-gray-300 m-2 min-w-full" style="height: 220px;" v-model="custom_prompt"></textarea>
                            </div>
                            <div class="mt-8 w-full">
                                <form-button @click="generateText()">Generate</form-button>
                            </div>

                            <div class="mt-8 w-full">
                                <textarea readonly class="overflow-auto bg-gray-300 m-2 min-w-full" style="height: 220px;" v-model="generated_text"></textarea>
                                <p class="text-xs"><a @click="generateText()">Regenerate</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="$emit('insert', generated_text)">
                            {{ actionText }}
                        </form-button>
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormInput from '../../shared/form/Input'
    import FormSelect from '../../shared/form/Select'
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'chat-gpt-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            ExclamationTriangleIcon,
            FormButton,
            FormField,
            FormLabel,
            FormInput,
            FormSelect,
            FormValidation,
        },
        emits: [
            'close',
            'insert',
        ],
        props: {
            name: String,
            embedCode: String,
            title: {
                type: String,
                default: 'Generate Text',
            },
            description: {
                type: String,
                default: '',
            },
            actionText: {
                type: String,
                default: 'Insert',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            settings: Object,
            show: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                prompt_type: 'donation',
                donation_amount: '',
                donation_deadline: '',
                volunteer_ask: '',
                volunteer_date: '',
                volunteer_location: '',
                cta_location: '',
                cta_ask: '',
                cta_date: '',
                custom_prompt: '',
                generated_text: '',
                errors: [],
            };
        },
        methods: {
            generateText(query) {
                let promptLanguage = '';

                if (this.prompt_type === 'donation') {
                    promptLanguage = 'Write text for a SMS program asking for a donation of $'+this.donation_amount+' before the deadline of '+this.donation_deadline+', in 160 characters or less.';
                } else if (this.prompt_type === 'volunteer') {
                    promptLanguage = 'Write text for a SMS program asking for volunteers for '+this.volunteer_ask+' on '+this.volunteer_date+' in '+this.volunteer_location+', in 160 characters or less.';
                } else if (this.prompt_type === 'call_to_action') {
                    promptLanguage = 'Write text for a SMS program asking for recipients to take action on '+this.cta_ask+' on '+this.cta_date+' at '+this.cta_location+', in 160 characters or less.';
                } else if (this.prompt_type === 'custom') {
                    promptLanguage = this.custom_prompt;
                }

                this.$http.post(`https://api.openai.com/v1/chat/completions`, {
                    'model': 'gpt-3.5-turbo',
                    'messages': [
                        {'role': 'user', 'content': promptLanguage},
                    ],
                    'max_tokens': 40,
                }, {
                    headers: {
                        'OpenAI-Organization': this.settings.parameters.org_id,
                        'Content-Type': 'application/json',
                    },
                    transformRequest: (data, headers) => {
                        headers['Authorization'] = 'Bearer '+this.settings.parameters.api_key;
                        delete headers['X-CSRF-TOKEN']
                        delete headers['X-Socket-Id']

                        data = JSON.stringify(data)
                        return data
                    }
                })
                .then((result) => {
                    console.log(result)
                    if (result.data.choices[0] && result.data.choices[0].message) {
                        this.generated_text = result.data.choices[0].message.content.replace(/^\"/, '').replace(/\"$/, '');
                    }
                })
                .catch(error => {
                    this.errors['request'] = [];
                    this.errors['request'][0] = 'An error occurred making the request. Please check your settings/request and try again.';
                });
            },
        }
    };
</script>
