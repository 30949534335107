<template>
    <div>
        <layout-header title="Create New Report"></layout-header>

        <form-section title="Report Details">
            <form-field wide>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.self">{{ errors.self[0] }}</p>
            </form-field>

            <form-field>
                <form-input id="name" name="name" label="Name" v-model="report.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="type" name="type" label="Type" v-model="report.type">
                    <option :value="null">- Select Report Type -</option>
                    <option value="growth_loss_summary">Growth/Loss Summary</option>
                    <option value="message_summary">Message Summary</option>
                    <option value="message_export">Message Export</option>
                    <option value="broadcast_export">Broadcast Export</option>
                    <option value="segment_person_export">Segment Person Export</option>
                    <option value="trackable_link_export" v-if="allow_tracking_report">Tracking Link Export</option>
                    <option value="optout_export">Opt Out Export</option>
                </form-select>
                <form-validation param="type" :errors="errors" />
            </form-field>
            <form-field>
                <form-label for="date_range">Date Range</form-label>
                <form-datepicker id="date_range" name="date_range" label="Date Range" v-model="report.parameters.date_range" type="daterange" :range="true"></form-datepicker>
                <form-validation param="parameters.date_start" :errors="errors" />
            </form-field>
            <form-field v-if="showGroupBy()">
                <form-select id="group_by" label="Group By" v-model="report.parameters.group_by">
                    <option :value="null">- Select Group By -</option>
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="year">Year</option>
                </form-select>
                <form-validation param="parameters.group_by" :errors="errors" />
            </form-field>
            <form-field v-if="showDirection()">
                <form-select id="direction" label="Direction" v-model="report.parameters.direction">
                    <option :value="null">- Select Direction -</option>
                    <option value="">All</option>
                    <option value="in">In</option>
                    <option value="out">Out</option>
                </form-select>
                <form-validation param="parameters.direction" :errors="errors" />
            </form-field>
            <form-field v-if="showLinkSelect()">
                <form-select label="Domain" id="domain_id" name="domain_id" v-model="report.parameters.domain_id" @change="updateLinkDetails()">
                    <option :value="null">- Select Domain -</option>
                    <option v-for="domain in domains" v-bind:key="domain.id" :value="domain.id">{{ domain.name }}</option>
                </form-select>
                <form-validation param="parameters.number_ids" :errors="errors" />
            </form-field>
            <form-field v-if="showSegmentSelect('static')">
                <form-label for="static_segments" label="Static Segments"></form-label>
                <multiselect id="static_segments" v-model="report.parameters.segments" :options="static_segments" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Select a Segment"></multiselect>
                <form-validation param="parameters.segment_ids" :errors="errors" />
            </form-field>
            <form-field v-if="showSegmentSelect('all')">
                <form-label for="segments" label="Segments"></form-label>
                <multiselect id="segments" v-model="report.parameters.segments" :options="segments" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Select a Segment"></multiselect>
                <form-validation param="parameters.segment_ids" :errors="errors" />
            </form-field>
            <form-field v-if="showSequenceSelect()">
                <form-label for="sequences" label="Sequences"></form-label>
                <multiselect id="sequences" v-model="report.parameters.sequences" :options="sequences" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Select a Sequence"></multiselect>
                <form-validation param="parameters.sequence_ids" :errors="errors" />
            </form-field>
            <form-field v-if="showLinkSelect()">
                <form-label for="links" label="Short URLs"></form-label>
                <multiselect id="links" v-model="report.parameters.links" track-by="id" label="name" placeholder="Search Links" :options="links" :multiple="true" :searchable="true" :loading="links_loading" :internal-search="false" :clear-on-search="false"  @search-change="updateLinkSearch" @input="updateLinkOnSelect"></multiselect>
                <form-validation param="parameters.link_ids" :errors="errors" />
            </form-field>
            <form-field v-if="showAttributeSelect()">
                <form-label for="attributes" label="Attributes"></form-label>
                <multiselect id="attributes" v-model="report.parameters.attributes" :options="attributes" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Select an Attribute"></multiselect>
                <form-validation param="parameters.attribute_ids" :errors="errors" />
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Create Report
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import FormDatepicker from '../../shared/form/DatePicker';

    export default {
        name: 'CreateReport',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            FormDatepicker,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetchAttributes();
            this.fetchSegments();
            this.fetchSequences();
            this.fetchSettings();
        },
        watch: {
            'report.parameters.date_range' (newDate, oldDate) {
                this.closeDatepicker();
            }
        },
        data() {
            return {
                daterange: {
                    lang: {
                        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                        placeholder: {
                        date: 'Select Date',
                        dateRange: 'Select Date Range'
                        }
                    },
                },
                attributes: [],
                segments: [],
                static_segments: [],
                sequences: [],
                domains: [],
                links: [],
                links_loading: false,
                report: {
                  name: '',
                  number_id: this.$store.getters.current_number_id,
                  type: null,
                  parameters: {
                      date_range: [new Date(), new Date()],
                      group_by: null,
                      segments: [],
                      attributes: [],
                      sequence_id: null,
                      direction: null,
                      domain_id: null,
                  }
                },
                settings: {
                    text: {
                        features: {
                        },
                        maximum_numbers: null,
                        maximum_sequences: null,
                    },
                },
                permissions: {
                    chat: false,
                    text: true,
                    talk: false,
                    lookup: false,
                    links: false,
                    connect: false,
                },
                allow_tracking_report: false,
                errors: []
            }
        },
        methods: {
            closeDatepicker() {
                this.$refs.datepicker.closePopup()
            },
            fetchSettings() {
                this.$http.get(`/api/v1/me`).then(this.refreshSettings);
            },
            refreshSettings({data}) {
                this.settings = data.data.settings;
                this.permissions = data.data.permissions;

                if (this.permissions.links && this.settings.links.features.link_type_trackable) {
                    this.allow_tracking_report = true
                    this.getDomains()
                }
            },
            getDomains() {
                return this.$http.get(process.env.MIX_LINKS_URL+'/api/v1/domains', {transformRequest: (data, headers) => {
                    delete headers['X-CSRF-TOKEN']
                    delete headers['X-Socket-Id']
                }}).then(response => {
                    this.domains = response.data.data;
                });
            },
            fetchSegments() {
                this.$http.get(this.segmentsUrl()).then(this.refreshSegments);
            },
            segmentsUrl() {
                return `/api/v1/segments?number_id=${this.$store.getters.current_number_id}&type=static`;
            },
            refreshSegments({ data }) {
                this.segments = data.data;
                this.static_segments = [];

                data.data.forEach(item => {
                    if (item.type === 'static') {
                        console.log(item);
                        this.static_segments.push(item)
                    }
                })
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?number_id=${this.$store.getters.current_number_id}&per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({ data }) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                this.$http.get(`/api/v1/attributes?per_page=-1`).then(this.refreshAttributes);
            },
            refreshAttributes({ data }) {
                this.attributes = data.data;
            },
            save() {
              let report = this.buildReport()

              this.$http.post('/api/v1/reports', report).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The report has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_reports'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while creating the report',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            showGroupBy() {
                if (this.report.type === 'growth_loss_summary' || this.report.type === 'message_summary')
                    return true
                else
                    return false
            },
            showDirection() {
                if (this.report.type === 'message_export')
                    return true
                else
                    return false
            },
            showAttributeSelect() {
                if (this.report.type === 'segment_person_export' || this.report.type === 'trackable_link_export' || this.report.type === 'optout_export')
                        return true
                    else
                        return false
            },
            showSegmentSelect(type) {
                if (type === 'static') {
                    if (this.report.type === 'growth_loss_summary' || this.report.type === 'broadcast_export')
                        return true
                    else
                        return false
                } else {
                    if (this.report.type === 'segment_person_export')
                        return true
                    else
                        return false
                }
            },
            showSequenceSelect() {
                if (this.report.type === 'message_summary' || this.report.type === 'message_export')
                    return true
                else
                    return false
            },
            showLinkSelect() {
                if (this.report.type === 'trackable_link_export')
                    return true
                else
                    return false
            },
            updateLinkDetails() {
                this.links = []

                if (this.report.parameters.domain_id !== null) {
                    this.updateLinkSearch('')
                }
            },
            updateLinkSearch(query) {
                this.links_loading = true

                this.$http.get(process.env.MIX_LINKS_URL+'/api/v1/links?domain_id='+this.report.parameters.domain_id+'&page=1&sort=desc&order=id&search='+query+'&search_statuses=active&search_types=trackable_text', {transformRequest: (data, headers) => {
                    delete headers['X-CSRF-TOKEN']
                    delete headers['X-Socket-Id']
                }}).then(response => {
                    this.links = response.data.data;
                    this.links_loading = false
                });
            },
            updateLinkOnSelect(entity) {
                //this.link.id = entity.id
                //this.link.generated_url = entity.generated_url
            },
            buildReport() {
                let report = {
                    name: this.report.name,
                    number_id: this.$store.getters.current_number_id,
                    type: this.report.type,
                    parameters: {
                        date_start: moment(this.report.parameters.date_range[0]).format('YYYY-MM-DD'),
                        date_end: moment(this.report.parameters.date_range[1]).format('YYYY-MM-DD'),
                    }
                }

                if (this.report.type === 'growth_loss_summary' || this.report.type === 'message_summary') {
                    report.parameters.group_by = this.report.parameters.group_by
                }

                if (this.report.type === 'message_export') {
                    report.parameters.direction = this.report.parameters.direction
                }

                if (this.report.type === 'growth_loss_summary' || this.report.type === 'broadcast_export' || this.report.type === 'segment_person_export') {
                    report.parameters.segment_ids = []

                    for (let i in this.report.parameters.segments) {
                        report.parameters.segment_ids.push(this.report.parameters.segments[i].id)
                    }
                }

                if (this.report.type === 'message_summary' || this.report.type === 'message_export') {
                    report.parameters.sequence_id = this.report.parameters.sequence_id
                }

                if (this.report.type === 'trackable_link_export') {
                    report.parameters.link_ids = []

                    for (let i in this.report.parameters.links) {
                        report.parameters.link_ids.push(this.report.parameters.links[i].id)
                    }
                }

                if (this.report.type === 'trackable_link_export' || this.report.type === 'segment_person_export' || this.report.type === 'optout_export') {
                    report.parameters.attribute_ids = []

                    for (let i in this.report.parameters.attributes) {
                        report.parameters.attribute_ids.push(this.report.parameters.attributes[i].id)
                    }
                }

                return report
            },
        }
    }
</script>
