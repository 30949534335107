import moment from 'moment-timezone';

function capitalize(value) {
    if (!value)
        return value;

    let frags = value.split(/_|-/);
    for (let i = 0; i < frags.length; ++i) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

function upper (value) {
    if (!value)
        return value;

    return value.toUpperCase()
}


function formatDateFriendly(value) {
    if (value) {
        return moment(String(value)).tz(moment.tz.guess()).format('MMMM Do YYYY, h:mm a z')
    }
}

function formatDate(value) {
    if (value) {
        return moment(String(value)).tz(moment.tz.guess()).format('MM/DD/YYYY h:mma z');
    }
}

function formatDateSeconds(value) {
    if (value) {
        return moment(String(value)).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm:ssa z');
    }
}

function formatSeconds(seconds) {
    if (seconds) {
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        return `${('0'+minutes).slice(-2)}:${('0'+seconds).slice(-2)}`;
    }
}

function formatMinutes(minutes) {
    if (minutes) {
        return `${('0'+minutes).slice(-2)}:00`;
    }
}

function formatPhone(value) {
    var cleaned = ('' + value).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[2] + ') ' + match[3] + '-' + match[4];
    }
    return number;
}

function truncate(text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
}

export { capitalize, upper, formatDate, formatDateFriendly, formatDateSeconds, formatSeconds, formatMinutes, formatPhone, truncate };
