<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Send Test Message</DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">Send the slice as a test to the numbers provide.</p>
                            </div>
                            <div class="mt-8">
                                <form-validation param="slice.type" :errors="test_errors"></form-validation>
                                <form-validation param="slice.message" :errors="test_errors"></form-validation>
                                <form-validation param="slice.message.message" :errors="test_errors"></form-validation>
                                <form-validation param="slice.sequence_id" :errors="test_errors"></form-validation>

                                <form-field>
                                    <label for="person_ids" class="form-label">Test Numbers</label>
                                    <multiselect v-model="test_people_value" track-by="id" label="phone_number" placeholder="Search People" :options="people" :max="10" :multiple="true" :searchable="true" :loading="people_loading" :internal-search="false" :clear-on-search="false"  @search-change="updatePersonSearch">
                                        <template slot="maxElements">Only 10 people may be selected for a test, at a time.</template>
                                    </multiselect>
                                    <form-validation param="person_ids" :errors="errors" />
                                </form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="$emit('confirm')">
                            {{ actionText }}
                        </form-button>
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'add-attribute-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
            FormField,
            FormLabel,
            FormSelect,
            FormInput,
            FormValidation,
        },
        emits: [
            'confirm',
            'cancel',
            'update:testPeople',
        ],
        props: {
            name: String,
            testPeople: Array,
            numberID: Number,
            errors: Object,
            actionText: {
                type: String,
                default: 'Add',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            test_people_value: {
                get() {
                    return this.testPeople
                },
                set(val) {
                    this.$emit('update:testPeople', val);
                }
            },
        },
        data() {
            return {
                people: [],
                people_loading: false,
            };
        },
        methods: {
            updatePersonSearch(query) {
                this.people_loading = true

                this.$http.get('/api/v1/people?number_id='+this.numberID+'&page=1&sort=desc&order=id&search='+query, {transformRequest: (data, headers) => {
                    delete headers['X-CSRF-TOKEN']
                    delete headers['X-Socket-Id']
                }}).then(response => {
                    this.people = response.data.data;
                    this.people_loading = false
                });
            },
        }
    };
</script>
