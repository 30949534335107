<template>
    <div>
        <layout-header title="Integrations">
        </layout-header>

        <div class="flex">
            <div class="max-w-sm rounded overflow-hidden shadow-lg m-4 w-1/3">
                <!--<img class="w-full" src="/img/google.png" alt="">-->
                <img class="w-full" style="min-height:275px;" src="/img/undraw_voting_nvu7.png" alt="Google Civic API" title="Google Civic API" />
                <div class="px-6 py-4">
                    <div class="font-bold text-xl mb-2">Google Civic API <span v-bind:class="{'bg-green-500': (integrations.google_civic_api.status === 'active'), 'bg-gray-600': (integrations.google_civic_api.status === 'inactive')}" class="text-white font-bold py-1 px-2 rounded-full text-sm mx-1">{{ $filters.capitalize(integrations.google_civic_api.status) }}</span></div>
                    <p class="text-gray-700 text-base">
                        Use the Google Civic API to generate polling locator sequences for voter registration workflows.
                    </p>
                </div>
                <div class="flex flex-wrap px-6 py-4 mb-2">
                    <form-button v-on:click="showGoogleCivicAPI()">{{ (integrations.google_civic_api.status === 'inactive' ? 'Enable' : 'Update') }}</form-button>
                </div>
            </div>

            <div class="max-w-sm rounded overflow-hidden shadow-lg m-4 w-1/3">
                <!--<img class="w-full" src="/img/google.png" alt="">-->
                <img class="w-full" style="min-height:275px;" src="/img/undraw_ai_enpp.png" alt="ChatGPT" title="ChatGPT" />
                <div class="px-6 py-4">
                    <div class="font-bold text-xl mb-2">ChatGPT <span v-bind:class="{'bg-green-500': (integrations?.chatgpt?.status === 'active'), 'bg-gray-600': (integrations?.chatgpt?.status === 'inactive')}" class="text-white font-bold py-1 px-2 rounded-full text-sm mx-1">{{ $filters.capitalize(integrations?.chatgpt?.status)}}</span></div>
                    <p class="text-gray-700 text-base">
                        Enhance broadcast with AI generated text with ChatGPT.
                    </p>
                </div>
                <div class="flex flex-wrap px-6 py-4 mb-2">
                    <form-button  v-on:click="showChatGPTAPI()">{{ (integrations?.chatgpt?.status === 'inactive' ? 'Enable' : 'Update') }}</form-button>
                </div>
            </div>
        </div>

        <IntegrationModal
            name="integration-modal"
            actionText="Update"
            :show="open_integration_modal"
            :useOrgID="(current_integration === 'chatgpt' ? true : false)"
            :errors="errors"
            v-model:apiKey="integration_api_key"
            v-model:orgID="integration_org_id"
            v-model:status="integration_status"
            @update="updateIntegration()"
            @cancel="open_integration_modal = false; current_integration = null; integration_status = 'inactive'; integration_api_key = ''; integration_org_id = '';"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import LayoutButton from '../../shared/layout/Button'
    import IntegrationModal from '../../shared/modal/IntegrationModal'
    import FormButton from '../../shared/form/Button'

    export default {
        name: 'ListIntegrations',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            IntegrationModal,
            FormButton,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetch(this.$store.getters.current_number_id, 1);
        },
        data() {
            return {
                integrations: {
                    google_civic_api: {
                        status: 'inactive',
                        parameters: {
                            api_key: null
                        }
                    },
                    chatgpt: {
                        status: 'inactive',
                        parameters: {
                            api_key: null,
                        },
                    },
                },
                disable_google_civic_api_key: true,
                google_civic_api_key_error: null,
                open_integration_modal: false,
                current_integration: null,
                integration_status: 'inactive',
                integration_api_key: '',
                integration_org_id: '',
                errors: [],
            }
        },
        methods: {
            fetch() {
                this.$http.get(`/api/v1/integrations`).then(this.refresh);
            },
            refresh({data}) {
                for (let index in data.data) {
                    if (data.data[index].integration === 'google_civic_api') {
                        this.integrations.google_civic_api.status = data.data[index].status;
                        this.integrations.google_civic_api.parameters = data.data[index].parameters;
                    } else if (data.data[index].integration === 'chatgpt') {
                        this.integrations.chatgpt.status = data.data[index].status;
                        this.integrations.chatgpt.parameters = data.data[index].parameters;
                    }
                }

                this.updateGoogleCivicStatus();
                this.updateChatGPTStatus();
                window.scrollTo(0,0);
            },
            showGoogleCivicAPI() {
                this.current_integration = 'google_civic_api';
                this.integration_api_key = this.integrations?.google_civic_api?.parameters.api_key;
                this.integration_status = this.integrations?.google_civic_api?.status;
                this.open_integration_modal = true;
            },
            updateIntegration() {
                if (this.current_integration === 'google_civic_api') {
                    this.integrations.google_civic_api.status = this.integration_status;
                    this.integrations.google_civic_api.parameters.api_key = this.integration_api_key;

                    this.googleCivicAPITest();
                } else if (this.current_integration === 'chatgpt') {
                    this.integrations.chatgpt.status = this.integration_status;
                    this.integrations.chatgpt.parameters.api_key = this.integration_api_key;
                    this.integrations.chatgpt.parameters.org_id = this.integration_org_id;

                    this.chatGPTTest();
                }
            },
            googleCivicAPITest() {
                this.errors = {};

                if (this.integrations.google_civic_api.status === 'active' && this.integrations.google_civic_api.parameters.api_key !== null) {
                    this.$http.get(`https://www.googleapis.com/civicinfo/v2/elections?key=${this.integrations.google_civic_api.parameters.api_key}`, {transformRequest: (data, headers) => {
                        delete headers['Authorization']
                        delete headers['X-CSRF-TOKEN']
                        delete headers['X-Socket-Id']
                    }}).then(this.googleCivicAPIUpdate).catch(error => {
                        this.errors['api_key'] = [];
                        this.errors['api_key'][0] = 'An error occurred saving the API key.  Please check the API key and try again.';
                    });
                } else if (this.integrations.google_civic_api.status === 'active') {
                    this.errors['api_key'] = [];
                    this.errors['api_key'][0] = 'Please provide a valid API key.';
                } else {
                    this.googleCivicAPIUpdate()
                }
            },
            googleCivicAPIUpdate() {
                let integration = this.integrations.google_civic_api;
                this.$http.put(`/api/v1/integrations/google_civic_api`, integration).then(this.postGoogleCivicAPIUpdate);
            },
            postGoogleCivicAPIUpdate() {
                this.open_integration_modal = false;
                this.current_integration = null;
                this.integration_api_key = '';
                this.integration_status = '';

                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The integration has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateGoogleCivicStatus() {
                if (this.integrations.google_civic_api.status === 'inactive') {
                    this.integrations.google_civic_api.parameters.api_key = null;
                }
            },


            showChatGPTAPI() {
                this.current_integration = 'chatgpt';
                this.integration_api_key = this.integrations?.chatgpt?.parameters?.api_key;
                this.integration_org_id = this.integrations?.chatgpt?.parameters?.org_id;
                this.integration_status = this.integrations?.chatgpt?.status;
                this.open_integration_modal = true;
            },
            chatGPTTest() {
                this.errors = {};

                if (this.integrations.chatgpt.status === 'active' && this.integrations.chatgpt.parameters.api_key !== null) {
                    this.$http.get(`https://api.openai.com/v1/models`, {headers: {
                        'OpenAI-Organization': this.integrations.chatgpt.parameters.org_id,
                    }, transformRequest: (data, headers) => {
                        headers['Authorization'] = 'Bearer '+this.integrations.chatgpt.parameters.api_key;
                        delete headers['X-CSRF-TOKEN']
                        delete headers['X-Socket-Id']
                    }}).then(this.chatGPTUpdate).catch(error => {
                        this.errors['api_key'] = [];
                        this.errors['api_key'][0] = 'An error occurred saving the API key.  Please check the API key and try again.';
                    });
                } else if (this.integrations.chatgpt.status === 'active') {
                    this.errors['api_key'] = [];
                    this.errors['api_key'][0] = 'Please provide a valid API key.';
                } else {
                    this.chatGPTUpdate()
                }
            },
            chatGPTUpdate() {
                let integration = this.integrations.chatgpt;
                this.$http.put(`/api/v1/integrations/chatgpt`, integration).then(this.postChatGPTUpdate);
            },
            postChatGPTUpdate() {
                this.open_integration_modal = false;
                this.current_integration = null;
                this.integration_api_key = '';
                this.integration_org_id = '';
                this.integration_status = '';

                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The integration has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateChatGPTStatus() {
                if (this.integrations?.chatgpt?.status === 'inactive') {
                    this.integrations.chatgpt.parameters.api_key = null;
                }
            },
        }
    }
</script>
