<template>
    <div>
        <layout-header title="People">
        </layout-header>

        <filter-filter cols.number="2">
            <filter-column>
                <form-input id="search" name="search" label="" v-model="search" placeholder="Search by number and press ↵" @keyupenter="updateSearch()"></form-input>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_number_ids"
                    :options="numbers"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Number"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_segment_ids"
                    :options="segments"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Segment"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
            <filter-column hide-sm>
                <multiselect
                    v-model="search_sources"
                    :options="sources"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    placeholder="Filter by Source"
                    @update:model-value="updateSearch()"
                >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                </multiselect>
            </filter-column>
        </filter-filter>

        <table-table>
            <template v-slot:header>
                <table-row type="header">
                    <table-header @click="updateSort('id')" hide-sm>ID</table-header>
                    <table-header @click="updateSort('phone_number')">Phone Number</table-header>
                    <table-header @click="updateSort('added_at')" hide-md>Date Joined</table-header>
                    <table-header @click="updateSort('removed_at')" hide-md>Date Removed</table-header>
                    <table-header @click="updateSort('status')" hide-md>Status</table-header>
                    <table-header @click="updateSort('source')" hide-sm>Source</table-header>
                    <table-header type="text" last>Actions</table-header>
                </table-row>
            </template>
            <template v-slot:body>
                <table-row v-for="(person, index) in people" :key="person.id" :index="index">
                    <table-cell hide-sm bold>{{ person.id }}</table-cell>
                    <table-cell>{{ person.phone_number }}</table-cell>
                    <table-cell hide-md>{{ $filters.formatDate(person.added_at) }}</table-cell>
                    <table-cell hide-md>{{ $filters.formatDate(person.removed_at) }}</table-cell>
                    <table-cell hide-md>{{ $filters.capitalize(person.status) }}</table-cell>
                    <table-cell hide-sm>{{ $filters.capitalize(person.source) }}</table-cell>
                    <table-cell last>
                        <router-link :to="{ name: 'view_person', params: { id: person.id }}" class="mx-3"><i class="fas fa-id-card"></i></router-link>
                        <table-delete-link @click="confirmDelete(person.id)"></table-delete-link>
                    </table-cell>
                </table-row>
            </template>
        </table-table>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <ConfirmModal
            name="delete-confirm"
            height="220"
            title="Delete Person?"
            description="Are you sure you want to delete this person?  This action cannot be undone."
            actionText="Delete"
            :show="open_delete_modal"
            @confirm="deletePerson()"
            @cancel="open_delete_modal = false"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import LayoutButton from '../../shared/layout/Button'
    import FilterFilter from '../../shared/filter/Filter'
    import FilterColumn from '../../shared/filter/Column'
    import TableTable from '../../shared/table/Table'
    import TableRow from '../../shared/table/Row'
    import TableHeader from '../../shared/table/Header'
    import TableCell from '../../shared/table/Cell'
    import TableStatusBadge from '../../shared/table/StatusBadge'
    import TableEditLink from '../../shared/table/EditLink'
    import TableDeleteLink from '../../shared/table/DeleteLink'
    import ConfirmModal from '../../shared/modal/ConfirmModal'
    import FormField from '../../shared/form/Field'
    import FormInput from '../../shared/form/Input'
    import FormSelect from '../../shared/form/Select'

    export default {
        name: 'ListPeople',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            FilterFilter,
            FilterColumn,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            TableStatusBadge,
            TableEditLink,
            TableDeleteLink,
            FormField,
            FormInput,
            FormSelect,
            ConfirmModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetch(1)
            this.fetchNumbers()
            this.fetchSegments()
        },
        data() {
            return {
                people: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                numbers: [],
                segments: [],
                sources: [
                    {id: 'keyword', name: 'Keyword'},
                    {id: 'api', name: 'API'},
                    {id: 'import', name: 'Import'},
                ],
                search: this.$route.query.search || '',
                search_number_ids: [],
                search_segment_ids: [],
                search_sources: [],
                open_delete_modal: false,
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_number_ids = this.search_number_ids.map(number => number.id)
                let search_segment_ids = this.search_segment_ids.map(segment => segment.id)
                let search_sources = this.search_sources.map(source => source.id)

                this.$http.get(`/api/v1/people?number_id=${this.$store.getters.current_number_id}&page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_segment_ids=${search_segment_ids}&search_sources=${search_sources}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.people = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            fetchNumbers() {
                this.$http.get(`/api/v1/numbers?per_page=-1`).then(this.refreshNumbers);
            },
            refreshNumbers({data}) {
                this.numbers = data.data;
            },
            fetchSegments() {
                this.$http.get(`/api/v1/segments?number_id=${this.$store.getters.current_number_id}&per_page=-1`).then(this.refreshSegments);
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            confirmDelete(id) {
                this.delete_id = id;
                this.open_delete_modal = true;
            },
            deletePerson() {
                this.$http.delete(`/api/v1/people/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.open_delete_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The person has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
        }
    }
</script>
