<template>
    <div>
        <vue-nestable v-model="sequence.blocks" childrenProp="blocks" :hooks="{'beforeMove': beforeMove}">
            <template #default="{ item, index}">
                <vue-nestable-handle :item="item" :index="index" class="w-full mb-6">
                    <div class="w-full px-3 mb-6 md:mb-0">
                        <div class="flex flex-col bg-white p-4 rounded-lg border-2 " v-bind:class="[{ 'border-sbr-purple': current_block && item.id && item.id === current_block.id, 'border-red-600 text-red-600': Object.keys(item.errors).length > 0}, 'border-gray-400']">
                            <div class="flex">
                                <div class="flex-1 text-lg"><a @click="setActiveBlock(item)" href="javascript:void(0)">{{ getMessagingName(item.messaging_type, item.parameters) }}</a></div>
                                <div class="text-lg text-sbr-orange" v-if="item.messaging_type !== 'true' && item.messaging_type !== 'false' && item.messaging_type !== 'menu_item' && item.messaging_type !== 'random_item' && item.messaging_type !== 'poll_item' && item.messaging_type !== 'absplit_item' && item.messaging_type !== 'subscription_welcome' && item.messaging_type !== 'subscription_exists' && item.messaging_type !== 'date_before' && item.messaging_type !== 'date_during' && item.messaging_type !== 'date_after'">
                                    <i v-on:click="removeBlock(item)" class="ml-2 fas fa-minus-circle cursor-pointer"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-nestable-handle>
            </template>
            <template #placeholder>
                <p></p>
            </template>
        </vue-nestable>
    </div>
</template>

<script>
    import { VueNestable, VueNestableHandle } from '../../shared/nestable'

    export default {
        name: 'Builder',
        components: {
            VueNestable,
            VueNestableHandle,
        },
        props: {
            sequence: {
                required: true
            },
            current_block: {
                required: true,
            },
            setActiveBlock: {
                required: true,
                type: Function,
            },
            removeBlock: {
                required: true,
                type: Function,
            },
        },
        methods: {
            beforeMove ({ dragItem, pathFrom, pathTo }) {
                if (dragItem.messaging_type === 'true' || dragItem.messaging_type === 'false' || dragItem.messaging_type === 'menu_item' || dragItem.messaging_type === 'random_item' || dragItem.messaging_type === 'poll_item' || dragItem.messaging_type === 'absplit_item' || dragItem.messaging_type === 'subscription_welcome' || dragItem.messaging_type === 'subscription_exists' || dragItem.messaging_type === 'date_before' || dragItem.messaging_type === 'date_during' || dragItem.messaging_type === 'date_after') {
                    return false
                }

                return true
            },
            getMessagingName(type, details) {
                if (type === 'subscription') {
                    return 'Subscription'
                } else if (type === 'message') {
                    return 'Send Message'
                } else if (type === 'conditional') {
                    return 'Conditional'
                } else if (type === 'collect_attribute') {
                    return 'Collect Attribute'
                } else if (type === 'collect_file') {
                    return 'Collect File'
                } else if (type === 'tag_attribute') {
                    return 'Tag Attribute'
                } else if (type === 'add_to_segment') {
                    return 'Add to Segment'
                } else if (type === 'forward_to_sequence') {
                    return 'Forward to Sequence'
                } else if (type === 'forward_to_inbox') {
                    return 'Forward to Inbox'
                } else if (type === 'menu') {
                    return 'Menu'
                } else if (type === 'random') {
                    return 'Random'
                } else if (type === 'drip_message') {
                    return 'Drip Message'
                } else if (type === 'poll') {
                    return 'Poll'
                } else if (type === 'absplit') {
                    return 'A/B Split'
                } else if (type === 'date_gate') {
                    return 'Date Gate'
                } else if (type === 'date_before') {
                    return 'Before'
                } else if (type === 'date_during') {
                    return 'During'
                } else if (type === 'date_after') {
                    return 'After'
                } else if (type === 'date_conditional') {
                    return 'Date Conditional'
                } else if (type === 'date_condition') {
                    return 'Condition'
                } else if (type === 'date_condition_default') {
                    return 'Default'
                } else if (type === 'dynamic_content') {
                    return 'Dynamic Content'
                } else if (type === 'notification') {
                    return 'Notification'
                } else if (type === 'google_civic_polling_locator') {
                    return 'Polling Locator'
                } else if (type === 'true') {
                    return 'True Result'
                } else if (type === 'false') {
                    return 'False Result'
                } else if (type === 'menu_item') {
                    return 'Menu Item ' + details.name
                } else if (type === 'random_item') {
                    return 'Random Item ' + details.name
                } else if (type === 'poll_item') {
                    return 'Poll Item ' + details.name + ' (Option: ' + (details.option !== null ? details.option : 'n/a') + ')'
                } else if (type === 'absplit_item') {
                    return 'A/B Split Item ' + details.name
                } else if (type === 'subscription_welcome') {
                    return 'New Subscriber'
                } else if (type === 'subscription_exists') {
                    return 'Existing Subscriber'
                }
            },
        },

    };
</script>

<style>
/*
* Style for nestable
*/
.nestable {
  position: relative;
}
.nestable-rtl {
  direction: rtl;
}
.nestable .nestable-list {
  margin: 0;
  padding: 0 0 0 40px;
  list-style-type: none;
}
.nestable-rtl .nestable-list {
  padding: 0 40px 0 0;
}
.nestable > .nestable-list {
  padding: 0;
}
.nestable-item,
.nestable-item-copy {
  margin: 10px 0 0;
}
.nestable-item:first-child,
.nestable-item-copy:first-child {
  margin-top: 0;
}
.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
  margin-top: 10px;
}
.nestable-item {
  position: relative;
}
.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}
.nestable-item.is-dragging * {
  opacity: 0;
  filter: alpha(opacity=0);
}
.nestable-item.is-dragging:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 127, 233, 0.274);
  border: 1px dashed rgb(73, 100, 241);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.nestable-drag-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}
.nestable-rtl .nestable-drag-layer {
  left: auto;
  right: 0;
}
.nestable-drag-layer > .nestable-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: rgba(106, 127, 233, 0.274);
}
.nestable-rtl .nestable-drag-layer > .nestable-list {
  padding: 0;
}
.nestable [draggable="true"] {
  cursor: move;
}
.nestable-handle {
  display: inline;
}
</style>
