<template>
    <div class="mb-3">
        <div class="mb-2">
            <form-label :id="name" :label="label"></form-label>
            <div class="float-right">
                <a class="btn-small" :class="{'btn-small-green': (text.type === 'mms')}" @click="switchType()">{{ $filters.upper(text.type) }}</a>
                <a class="btn-small" @click="showLinkShortener()" v-if="allowShortLinks">{Link}</a>
                <a class="btn-small" @click="showModal()" v-if="showAttributeOptions">{Attribute}</a>
                <a class="btn-small" @click="showPollingLocatorModal()" v-if="showPollingLocatorOptions">{Poll Result}</a>
                <a class="btn-small" @click="showPollResultsModal()" v-if="showPollOptions">{Poll Result}</a>
                <a class="btn-small" @click="showChatGPTModal()" v-if="showChatGPT">{ChatGPT}</a>
            </div>
        </div>
        <textarea
            class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-lg shadow-sm py-2 px-4 mb-3 focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple"
            :id="name"
            :name="name"
            ref="refTextarea"
            v-model="text.message"
            @click="checkSelection($event)"
            @keyup="checkSelection($event);"
        ></textarea>
        <p class="text-xs italic mb-2" :class="{'text-red-600': remainingWarn}" v-if="max">{{ remaining }}</p>

        <div v-if="text.type === 'mms'">
            <input type="file" id="file-upload" ref="refFileInput" hidden @change="handleFileUpload($event)" />
            <a class="btn fas fa-file-upload" @click="showFileUpload(id)"></a>
            <div class="btn inline-block relative text-gray-700 text-center bg-gray-400 px-4 py-2 m-2" v-for="(file, index) in text.files" v-bind:key="index">
                <a class="far fa-file-image" @click="previewAttachment(file)" href="javascript:void(0)"></a>
                <a class="fas fa-times-circle absolute" style="top: -8px; right: -8px;" @click="removeFile(index)" href="javascript:void(0)"></a>
            </div>
        </div>

        <DynamicTagModal
            :show="open_tag_modal"
            :attributes="attributes"
            v-model:tag="tag"
            @add="(tag) => { insertTag(tag); tag.attribute_id = null; tag.default_value = ''; }"
            @cancel="open_tag_modal = false; tag.attribute_id = null; tag.default_value = '';"
        />

        <PollingLocatorModal
            :show="open_locator_modal"
            v-model:tag="pl_tag"
            @add="(tag) => { insertPollingLocatorTag(tag); pl_tag.name = ''; pl_tag.default_value = ''; }"
            @cancel="open_locator_modal = false; pl_tag.name = ''; pl_tag.default_value = '';"
        />

        <PollResultModal
            :show="open_results_modal"
            v-model:tag="res_tag"
            @add="(tag) => { insertPollResultsTag(tag); res_tag.name = ''; res_tag.default_value = ''; }"
            @cancel="open_results_modal = false; res_tag.name = ''; res_tag.default_value = '';"
        />

        <PreviewAttachmentModal
            :show="open_preview_modal"
            :previewAttachmentUrl="previewAttachmentUrl"
            @cancel="open_preview_modal = false; previewAttachmentUrl = null;"
        />

        <LinkShortenerModal
            :show="open_shortener_modal"
            :domains="domains"
            :allowTrackableLinks="allowTrackableLinks"
            v-model:link="link"
            @insert="(generated_url) => { insertLink(link.id, generated_url); resetLink(); }"
            @cancel="open_shortener_modal = false; resetLink();"
        />

        <ChatGPTModal
            :show="open_chatgpt_modal"
            :settings="integrations.chatgpt"
            @insert="(generated_text) => { insertText(generated_text); }"
            @cancel="open_chatgpt_modal = false;"
        />
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import FormLabel from './form/Label'
    import DynamicTagModal from './modal/DynamicTagModal'
    import PollResultModal from './modal/PollResultModal'
    import PollingLocatorModal from './modal/PollingLocatorModal'
    import PreviewAttachmentModal from './modal/PreviewAttachmentModal'
    import LinkShortenerModal from './modal/LinkShortenerModal'
    import ChatGPTModal from './modal/ChatGPTModal'

    export default {
        name: 'DynamicTextarea',
        components: {
            FormLabel,
            DynamicTagModal,
            PollResultModal,
            PollingLocatorModal,
            PreviewAttachmentModal,
            LinkShortenerModal,
            ChatGPTModal,
        },
        emits: [
            'update:modelValue',
            'update:type',
        ],
        data() {
            return {
                tag: {
                    attribute_id: null,
                    default_value: '',
                },
                res_tag: {
                    name: '',
                    default_value: ''
                },
                domains: [],
                links: [],
                links_loading: false,
                link: {
                    id: null,
                    name: '',
                    path: '',
                    redirect: '',
                    domain_id: null,
                    action: 'new',
                    type: 'default',
                    selected_link: null,
                    errors: [],
                },
                pl_tag: {
                    name: '',
                    default_value: ''
                },
                integrations: {
                    chatgpt: {
                        status: null,
                        parameters: null,
                    },
                    google_civic_api: {
                        status: null,
                        parameters: null,
                    },
                },
                remainingWarn: false,
                attachmentFile: null,
                previewAttachmentUrl: '',
                open_tag_modal: false,
                open_results_modal: false,
                open_locator_modal: false,
                open_preview_modal: false,
                open_shortener_modal: false,
                open_chatgpt_modal: false,
            }
        },
        setup() {

            const refTextarea = ref(null);
            const refFileInput = ref(null);

            return { refTextarea, refFileInput };
        },
        mounted: function () {
            this.fetchIntegrations();
        },
        methods: {
            showModal() {
                this.open_tag_modal = true;
            },
            showPollingLocatorModal() {
                this.open_locator_modal = true;
            },
            showPollResultsModal() {
                this.open_results_modal = true;
            },
            showChatGPTModal() {
                this.open_chatgpt_modal = true;
            },
            showLinkShortener() {
                return this.getDomains().then(() => {
                    this.link = {
                        id: null,
                        name: '',
                        path: '',
                        redirect: '',
                        domain_id: null,
                        action: 'new',
                        type: 'default',
                        errors: [],
                    };

                    this.open_shortener_modal = true;
                });
            },
            switchType() {
                this.text.type = (this.text.type === 'sms' ? 'mms' : 'sms');
            },
            insertTag(tag) {
                let start = this.refTextarea.selectionStart;
                let end = this.refTextarea.selectionEnd;
                let value = this.text.message;
                let prefix = value.substring(0, start);
                let postfix = value.substring(end, value.length);
                let result = prefix + '{{attribute_id='+tag.attribute_id;

                if (tag.default_value !== '') {
                    result += ';default='+tag.default_value;
                }

                result += '}}' + postfix;

                this.text.message = result;
                this.open_tag_modal = false;
            },
            insertPollResultsTag() {
                let start = this.refTextarea.selectionStart;
                let end = this.refTextarea.selectionEnd;
                let value = this.text.message;
                let prefix = value.substring(0, start);
                let postfix = value.substring(end, value.length);
                let result = prefix + '{{res='+this.res_tag.name;

                if (this.res_tag.default_value !== '') {
                    result += ';default='+this.res_tag.default_value;
                }

                result += '}}' + postfix;

                this.text.message = result;
                this.open_results_modal = false;
            },
            insertPollingLocatorTag() {
                let start = this.refTextarea.selectionStart;
                let end = this.refTextarea.selectionEnd;
                let value = this.text.message;
                let prefix = value.substring(0, start);
                let postfix = value.substring(end, value.length);
                let result = prefix + '{{pl='+this.pl_tag.name;

                if (this.pl_tag.default_value !== '') {
                    result += ';default='+this.pl_tag.default_value;
                }

                result += '}}' + postfix;

                this.text.message = result;
                this.open_locator_modal = false;
            },
            insertLink(id, url) {
                let start = this.refTextarea.selectionStart;
                let end = this.refTextarea.selectionEnd;
                let value = this.text.message;
                let prefix = value.substring(0, start);
                let postfix = value.substring(end, value.length);

                let result = prefix + '{{link_id=' + id + ';link_url=' + url + '}}' + postfix;

                this.text.message = result;
                this.open_shortener_modal = false;
            },
            resetLink() {
                this.link = {
                    id: null,
                    name: '',
                    path: '',
                    redirect: '',
                    domain_id: null,
                    action: 'new',
                    type: 'default',
                    selected_link: null,
                    errors: [],
                };
            },
            getLinkDetails(id) {
                this.$http.get(process.env.MIX_LINKS_URL+'/api/v1/links/'+id, {transformRequest: (data, headers) => {
                    delete headers['X-CSRF-TOKEN']
                    delete headers['X-Socket-Id']
                }}).then(response => {
                    this.link = response.data.data
                    this.link.action = 'existing'
                    this.link.selected_link = response.data.data
                });
            },
            getDomains() {
                return this.$http.get(process.env.MIX_LINKS_URL+'/api/v1/domains', {transformRequest: (data, headers) => {
                    delete headers['X-CSRF-TOKEN']
                    delete headers['X-Socket-Id']
                }}).then(response => {
                    this.domains = response.data.data;
                });
            },
            checkSelection(event) {
                if (event.type === 'key' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
                    return;
                }

                let cursor = this.refTextarea.selectionStart;
                let search = RegExp('{{.*?}}','g');
                let match = null;

                while ((match = search.exec(this.text.message)) !== null) {
                    let start = match.index;
                    let end = start + match[0].length;

                    if (cursor > start && cursor < end) {
                        this.refTextarea.focus();
                        this.refTextarea.setSelectionRange(start, end, 'forward');

                        if (event.type === 'click') {
                            let parts = match[0].replace(/^{{|}}$/g, '').split(';');

                                let attribute = parts[0].split('=');
                                let default_value = (parts.length === 2 ? parts[1].split('=') : ['default', '']);

                                if (attribute[0] === 'attribute_id') {
                                    this.tag.attribute_id = attribute[1];
                                    this.tag.default_value = default_value[1];

                                    this.open_tag_modal = true;
                                } else if(attribute[0] === 'pl') {
                                    this.pl_tag.name = attribute[1];
                                    this.pl_tag.default_value = default_value[1];

                                    this.open_locator_modal = true;
                                } else if(attribute[0] === 'link_id') {
                                    this.showLinkShortener().then(() => {
                                        this.getLinkDetails(attribute[1]);
                                    })
                                } else if(attribute[0] === 'res') {
                                    this.res_tag.name = attribute[1];
                                    this.res_tag.default_value = default_value[1];

                                    this.open_results_modal = true;
                                }


                        }

                        break;
                    }
                }
            },
            submitFile() {
                let formData = new FormData();
                formData.append('file', this.attachmentFile);
                formData.append('number_id', this.$store.getters.current_number_id);

                this.$http.post('/api/v1/attachments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.text.files.push(response.data.attachment_url);
                }).catch(error => {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while uploading the attachment',
                        duration: 5000,
                        speed: 1000
                    });
                });
            },
            removeFile(index) {
                if (this.text.files[index]) {
                    this.text.files.splice(index, 1);
                }
            },
            handleFileUpload(e) {
                if (e && e.target && e.target.files && e.target.files.length > 0) {
                    this.attachmentFile = e.target.files[0];
                    this.submitFile();
                }
            },
            showFileUpload(id) {
                this.refFileInput.click();
            },
            previewAttachment(url) {
                this.previewAttachmentUrl = url;
                this.open_preview_modal = true;
            },
            fetchIntegrations() {
                this.$http.get(`/api/v1/integrations`).then(this.refreshIntegrations);
            },
            refreshIntegrations({data}) {
                for (let index in data.data) {
                    if (data.data[index].integration === 'google_civic_api') {
                        this.integrations.google_civic_api.status = data.data[index].status;
                        this.integrations.google_civic_api.parameters = data.data[index].parameters;
                    } else if (data.data[index].integration === 'chatgpt') {
                        this.integrations.chatgpt.status = data.data[index].status;
                        this.integrations.chatgpt.parameters = data.data[index].parameters;
                    }
                }
            },
            insertText(text) {
                let start = this.refTextarea.selectionStart;
                let end = this.refTextarea.selectionEnd;
                let value = this.text.message;
                let prefix = value.substring(0, start);
                let postfix = value.substring(end, value.length);

                let result = prefix + text + postfix;

                this.text.message = result;
                this.open_chatgpt_modal = false;
            },
        },
        computed: {
            text: {
                get() {
                    return this.modelValue;
                },
                set(v) {
                    this.$emit('update:modelValue', v)
                }
            },
            remaining: {
                get() {
                    let search = RegExp('{{.*?}}','g');

                    if (this.text.message !== null && this.text.message.match(search)) {
                        this.remainingWarn = false;

                        return 'Character counting not available with dynamic tokens'
                    } else {
                        let characters = this.max - (this.text.message === null ? 0 : this.text.message.length);
                        this.remainingWarn = (characters < 0 ? true : false);

                        return ((characters < 0) ? Math.abs(characters) + ' characters over' : characters + ' characters remaining');
                    }
                }
            },
            max: {
                get() {
                    return (this.text.type === 'mms' ? this.maxMms : this.maxSms);
                }
            },
        },
        props: {
            name: {
                required: true
            },
            modelValue: {
                required: true,
                type: Object,
            },
            label: {
                required: true
            },
            maxSms: {

            },
            maxMms: {

            },
            showPollingLocatorOptions: {
                type: Boolean,
                default: false,
            },
            showPollOptions: {
                type: Boolean,
                default: false,
            },
            showChatGPT: {
                type: Boolean,
                default: false,
            },
            showAttributeOptions: {
                type: Boolean,
                default: true,
            },
            allowShortLinks: {
                type: Boolean,
                default: false,
            },
            allowTrackableLinks: {
                type: Boolean,
                default: false,
            },
            attributes: Array,
        }
    };
</script>

<style scoped>
    .btn-small {
        @apply bg-sbr-orange text-white font-bold p-1 text-xs cursor-pointer rounded mr-2 shadow
    }
</style>
