<template>
    <div>
        <div v-if="current_step === 1">
            <layout-header title="Create Import - Step 1"></layout-header>

            <form-section title="Import Details">
                <form-field>
                    <form-input id="name" name="name" label="Name" v-model="import_item.name"></form-input>
                    <form-validation param="name" :errors="errors" />
                </form-field>
                <form-field>
                    <form-select label="Type" id="type" name="type" v-model="import_item.type">
                        <option value="subscriber">Subscriber</option>
                        <option value="attribute">Attributes</option>
                    </form-select>
                    <form-validation param="type" :errors="errors" />
                </form-field>
                <form-field v-if="import_item.type === 'subscriber'">
                    <form-select label="Segment" id="segment_id" name="segment_id" v-model="import_item.segment_id">
                        <option :value="null">- None -</option>
                        <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                    </form-select>
                    <form-validation param="segment_id" :errors="errors" />
                </form-field>
                <form-field>
                    <form-file label="Import File" name="import_file" id="import_file" ref="import_file" @change="importFile" />
                    <form-validation param="import_file" :errors="errors" />
                </form-field>
                <form-field wide>
                    <form-textarea label="Notes" id="notes" v-model="import_item.notes"></form-textarea>
                    <form-validation param="notes" :errors="errors" />
                </form-field>
            </form-section>

            <div class="flex flex-wrap m-5 px-3 pt-3">
                <button class="btn btn-primary" v-on:click="continueStep">
                    Continue
                </button>

                <button class="btn btn-secondary" v-on:click="cancel">
                    Cancel
                </button>
            </div>
        </div>

        <div v-if="current_step === 2">
            <layout-header title="Create Import - Step 2"></layout-header>

            <form-section title="Record Preview">
                <form-field wide>
                            <p>Listed below is an example of the data specified in the import file.  In the first row of the header, the original header row from the CSV file will be shown.  Below that
                                will be the matching attribute fields.  If a match could not be determined, the matching attribute will need to be manually set before continuing.
                            </p>
                </form-field>
                <form-field wide>
                    <div class="bg-white shadow-md rounded my-6 overflow-x-scroll">
                        <table class="import">
                            <thead>
                                <tr>
                                    <th>Row</th>
                                    <th v-for="(col, index) in import_header" v-bind:key="index">{{ col }}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th v-for="(col, index) in import_header" v-bind:key="index" style="min-width: 200px;">
                                        <div class="relative">
                                            <form-select class="form-select import-select" v-model="import_item.parameters.fields[index]" v-if="index === 0" disabled>
                                                <option :value="import_item.parameters.fields[index]">Phone Number</option>
                                            </form-select>
                                            <form-select name="" class="form-select import-select" :class="{'import-select-alert': (import_item.parameters.fields[index] === null)}" v-model="import_item.parameters.fields[index]" v-if="index !== 0">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute">{{ attribute.name }}</option>
                                            </form-select>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="hover:bg-grey-lighter" v-for="(row, row_index) in current_data" v-bind:key="row_index">
                                    <td class="py-4 px-6 border-b border-grey-light">{{ row.row_number }}</td>
                                    <td class="py-4 px-6 border-b border-grey-light" v-for="(col, col_index) in row.data" v-bind:key="col_index">{{ col }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form-field>
            </form-section>

                    <div class="flex flex-wrap m-5 px-3 pt-3">
                        <button class="btn btn-primary" v-on:click="continueStep" :disabled="!validAttributes">
                            Continue
                        </button>

                        <button class="btn btn-secondary" v-on:click="goBack">
                            Back
                        </button>
                    </div>
        </div>

        <div v-if="current_step === 3">
            <layout-header title="Create Import - Step 3"></layout-header>

            <form-section title="Record Summary">
                <form-field wide>
                            <p>All records in the CSV file are listed below.  Validation errors will show for each row, and can be modified before proceeding.
                            </p>
                </form-field>
                <form-field wide>
                            <div class="bg-white shadow-md rounded my-6 overflow-x-auto">
                                <table class="import">
                                    <thead>
                                        <tr>
                                            <th>Row</th>
                                            <th v-for="(col, index) in import_header" v-bind:key="index">{{ col }}</th>
                                            <th>Actions</th>
                                            <th>Errors</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="hover:bg-grey-lighter" v-for="(row, row_index) in current_data" v-bind:key="row_index">
                                            <td class="py-4 px-6 border-b border-grey-light">{{ row.row_number }}</td>
                                            <td class="py-4 px-6 border-b border-grey-light" :class="{'error': (row_errors[row.row_number - 1] && row_errors[row.row_number - 1][col_index])}" v-for="(col, col_index) in row.data" v-bind:key="col_index">
                                                <input type="text" class="form-text import-text" v-model="current_data[row_index].data[col_index]" />
                                            </td>
                                            <td class="py-4 px-6 border-b border-grey-light">
                                                <a href="javascript:void(0);" @click="updateRow(row_index, row.row_number - 1)"><i class="fas fa-save"></i></a>
                                                <a href="javascript:void(0);" @click="deleteRow(row.row_number - 1)"><i class="fas fa-trash"></i></a>
                                            </td>
                                            <td class="py-4 px-6 border-b border-grey-light">
                                                <a href="javascript:void(0);" v-if="row_errors[row_index] && Object.keys(row_errors[row_index]).length > 0">
                                                    <i class="fas fa-exclamation-circle"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <paginate
                                :page-count="totalPages"
                                :click-handler="loadCurrentPage"
                                :prev-text="'Prev'"
                                :next-text="'Next'"
                                :container-class="'pagination'"
                                :page-class="'pagination-page'"
                                :prev-class="'pagination-prev'"
                                :next-class="'pagination-next'"
                                :disabled-class="'pagination-inactive'">
                            </paginate>
                </form-field>
            </form-section>



                    <div class="flex flex-wrap m-5 px-3 pt-3">
                        <button class="btn btn-primary" v-on:click="continueStep">
                            Continue
                        </button>

                        <button class="btn btn-secondary" v-on:click="goBack">
                            Back
                        </button>
                    </div>
        </div>

        <div v-if="current_step === 4">
            <div class="flex">
                <h1 class="flex-1 ml-5 font-bold text-lg text-header">Create Import - Step 4</h1>
            </div>

            <div class="flex flex-row">
                <div class="flex-grow">
                    <!-- Audience Details -->
                    <div class="m-5 bg-white shadow-md rounded">
                        <div class="flex">
                            <h2 class="flex-1 p-4">Import Summary</h2>
                            <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('import_details')"><i class="far fa-question-circle"></i></a></h2>
                        </div>
                        <div class="bg-white px-6 pb-2">
                            <div class="w-full pb-8">
                                <p>Review the import details below before uploading.  If you receive errors, please download the processed file below to try again or submit for troubleshooting.</p>
                                <p>Name: <strong>{{ import_item.name }}</strong></p>
                                <p>Type: <strong>{{ import_item.type }}</strong></p>
                                <p>Total Records: <strong>{{  current_data.length - delete_rows.length }}... {{  current_data.length }}...{{ delete_rows.length }}</strong></p>
                                <p v-if="import_item.type === 'subscribe'">Segment: <strong>{{ getSegmentName(import_item.segment_id) }}</strong></p>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap m-5 px-3 pt-3">
                        <button class="btn btn-primary" v-on:click="continueStep">
                            Upload Import
                        </button>

                        <button class="btn btn-secondary" v-on:click="goBack">
                            Cancel
                        </button>
                    </div>
                </div>

                <div class="w-64" v-if="show_help_section">
                    <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'audience_details'">
                        <h2 class="p-4">Audience Details Help</h2>
                        <div class="px-4 pb-4">
                            <div class="w-full">
                                <p class="text-xs pb-2">An audience defines the recipients of the broadcast, created from the unique subscribers included in the specified segments.</p>
                            </div>
                        </div>
                    </div>

                    <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'broadcast_details'">
                        <h2 class="p-4">Broadcast Details Help</h2>
                        <div class="px-4 pb-4">
                            <div class="w-full">
                                <p class="text-xs pb-2">Each slice represents a message that will be received by subscribers, specified by the percentage.</p>
                                <p class="text-xs italic pb-2"><strong>Type</strong> - The type of message to be sent for the slice.  Message can be an SMS or MMS message, Sequence can be an existing sequence to trigger, and None will not send any
                                message to the selection of users (used to exclude a portion of recipients and/or include attribute tagging).</p>
                                <p class="text-xs italic pb-2"><strong>Percentage</strong> - The percentage of the audience that will receive this slice as a message.  All percentages across all slices must equal 100 and be whole numbers.</p>
                                <p class="text-xs italic pb-2"><strong>Tag Attribute</strong> - An option attribute that can be used to tag recipients of this slice.</p>
                            </div>
                        </div>
                    </div>

                    <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'schedule_details'">
                        <h2 class="p-4">Schedule Details Help</h2>
                        <div class="px-4 pb-4">
                            <div class="w-full">
                                <p class="text-xs pb-2">Determines when the broadcast will begin processing and sending to subscribers.  Note: this is only when the processing begins, and not when the subscribers will receive the message.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormFile from '../../shared/form/File';
    import FormTextarea from '../../shared/form/TextArea';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import ValidationNotice from '../../shared/ValidationNotice';
    import DynamicTextarea from '../../shared/DynamicTextarea';

    export default {
        name: 'CreateImport',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormFile,
            FormTextarea,
            FormValidation,
            ValidationNotice,
            DynamicTextarea,
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchImport()
            this.fetchSegments()
            //this.fetchSequences()
            this.fetchAttributes()
            this.fetchSettings()
        },
        data() {
            return {
                current_step: null,
                segments: [],
                sequences: [],
                attributes: [],
                all_attributes: [],
                import_item: {
                    id: null,
                    number_id: this.$store.getters.current_number_id,
                    segment_id: null,
                    type: 'attribute',
                    name: '',
                    notes: '',
                    parameters: {
                        fields: [],
                        field_ids: [],
                    }
                },
                import_header: null,
                import_header_format: [],
                import_data: null,
                current_data: null,
                current_page: 1,
                current_per_page: 25,
                current_filter: 'all',
                delete_rows: [],
                row_errors: {},
                settings: {
                    text: {
                        features: {
                        },
                        maximum_numbers: null,
                        maximum_sequences: null,
                    },
                },
                permissions: {
                    chat: false,
                    text: true,
                    talk: false,
                    lookup: false,
                    links: false,
                    connect: false,
                },
                errors: {},
                show_help_section: false,
            }
        },
        computed: {
            id () {
                return this.$route.params.id;
            },
            totalPages () {
                return Math.ceil(this.import_data.data.length / this.current_per_page);
            },
            validAttributes () {
                return !this.import_item.parameters.fields.includes(null);
            },
        },
        methods: {
            fetchImport() {
                if (this.id) {
                    this.$http.get(`/api/v1/imports/${this.id}`).then(this.refreshImport)
                } else {
                    this.current_step = 1
                }
            },
            refreshAttribute({data}) {
                this.import = data.data
            },
            fetchSettings() {
                this.$http.get(`/api/v1/me`).then(this.refreshSettings);
            },
            refreshSettings({data}) {
                this.settings = data.data.settings;
                this.permissions = data.data.permissions;

                if (this.permissions.links) {
                    this.allow_short_links = true

                    if (this.settings.links.features.link_type_trackable) {
                        this.allow_trackable_links = true
                    }
                }
            },
            fetchSegments() {
                this.$http.get(`/api/v1/segments?number_id=${this.import_item.number_id}`).then(this.refreshSegments);
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?number_id=${this.import_item.number_id}&per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                this.$http.get(`/api/v1/attributes`).then(this.refreshAttributes);
            },
            refreshAttributes({data}) {
                for(let index in data.data) {
                    this.all_attributes.push(data.data[index]);

                    if (data.data[index].scope !== 'system') {
                        this.attributes.push(data.data[index]);
                    }
                }
            },
            matchAttribute(name, use_all) {
                return (use_all ? this.all_attributes.find(item => item.name === name) : this.attributes.find(item => item.name === name));
            },
            getSegmentName(id) {
                let segment = this.segments.find(segment => segment.id === id);
                return segment?.name || 'n/a';
            },
            updateRow(row_index, index) {
                for (let col = 0 ; col < this.current_data[row_index].data.length ; ++col) {
                    this.import_data.data[index][col] = this.current_data[row_index].data[col];
                }

                this.validateRow(index);

                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'Row '+(index + 1)+' successfully updated.',
                    duration: 5000,
                    speed: 1000
                });
            },
            deleteRow(index) {
                if(this.delete_rows[index] == null)
                    this.delete_rows[index] = {};

                this.loadCurrentPage(this.current_page, this.current_per_page);
            },
            restoreRow(index) {
                delete this.delete_rows[index];

                this.loadCurrentPage(this.current_page, this.current_per_page);
            },
            validateData() {
                for (let row = 0 ; row < this.import_data.data.length ; ++row) {
                    console.log('Processing row '+row)
                    this.validateRow(row);
                }
            },
            validateRow(row) {
                for (let col = 0 ; col < this.import_data.data[row].length ; ++col) {
                    if (this.import_data.data[row][col].trim() !== '') {
                        if (!this.import_data.data[row][col].match(new RegExp(this.import_item.parameters.fields[col].regex.slice(1, -1)))) {
                            if (this.row_errors[row] === null) {
                                this.row_errors[row] = {};
                            }

                            this.row_errors[row][col] = 'Invalid format provided';
                        } else if (this.import_item.parameters.fields[col].values_type === 'predefined') {
                            let found = false;

                            for (let i = 0 ; i < this.import_item.parameters.fields[col].valid_values.values.length ; ++i) {
                                if (this.import_item.parameters.fields[col].valid_values.values[i].value == this.import_data.data[row][col]) {
                                    found = true;
                                    break;
                                }
                            }

                            if (!found) {
                                if (this.row_errors[row] === null) {
                                    this.row_errors[row] = {};
                                }

                                this.row_errors[row][col] = 'Expected values: '+this.import_item.parameters.fields[col].valid_values.values.map(e => e.value).join(', ');
                            } else if (this.row_errors[row][col]) {
                                delete this.row_errors[row][col];
                            }
                        } else if (['string'].includes(this.import_item.parameters.fields[col].type)) {
                            if (this.row_errors[row] == null) {
                                this.row_errors[row] = {};
                            }

                            if (this.import_data.data[row][col].length > this.import_item.parameters.fields[col].length) {
                                this.row_errors[row][col] = 'Value longer than allowed length';
                            } else if (this.row_errors[row][col]) {
                                //this.row_errors[row].splice(col, 1);
                                delete this.row_errors[row][col]
                            }
                        } else if (this.row_errors[row] !== undefined && this.row_errors[row][col]) {
                            delete this.row_errors[row][col]
                        }
                    } else if (this.row_errors[row] !== undefined && this.row_errors[row][col]) {
                        delete this.row_errors[row][col]
                    }
                }

                if (this.row_errors[row] == null || Object.keys(this.row_errors[row]).length === 0) {
                    delete this.row_errors[row]
                }
            },
            refreshImport({data}) {
                this.import_item = data.data;
                this.current_step = 2;
            },
            loadPage(page, per_page) {
                this.current_page = page
                this.current_data = []

                let offset = ((page - 1) * per_page)
                let row = 0

                for (let i = offset ; i < (offset + per_page) ; ++i) {
                    this.current_data[row] = {
                        data: this.import_data.data[i],
                        row_number: (offset + row + 1),
                    }

                    ++row

                    if (i >= (this.import_data.data.length - 1)) {
                        break;
                    }
                }
            },
            importFile(files) {
                let vm = this;
                let file = files[0];

                if (file === null || file === undefined) {
                    this.errors['import_file'] = ['Please select a CSV file to import'];
                    return;
                } else if (file.type !== 'text/csv') {
                    this.errors['import_file'] = ['Please select a valid CSV file to import'];
                    return;
                } else if (file.size > 20000000) {
                    this.errors['import_file'] = ['Please select a CSV file less than 20MB to upload'];
                    return;
                } else {
                    delete this.errors['import_file'];
                }

                this.$papa.parse(file, {
                    header: false,
                    complete: function (results) {
                        vm.import_data = {data: [], errors: results.errors, meta: results.meta};
                        vm.delete_rows = [];

                        for(let i = 0 ; i < results.data.length ; ++i) {
                            if(results.data[i][0] !== '') {
                                vm.import_data.data.push(results.data[i]);
                            }
                        }

                        vm.import_header = vm.import_data.data[0]
                        vm.import_data.data.splice(0, 1)
                        vm.import_item.parameters.fields = [];

                        for(let i = 0 ; i < vm.import_header.length ; ++i) {
                            vm.import_item.parameters.fields[i] = (i === 0 ? vm.matchAttribute('Phone Number', true) : vm.matchAttribute(vm.import_header[i]) || null);
                            vm.import_header_format[i] = null;
                        }

                        for(let i = 0 ; i < vm.import_data.data.length ; ++i) {
                            if(vm.import_data.data[i].length != vm.import_header.length) {
                                vm.import_data = null
                                vm.import_header = []

                                vm.$notify({
                                    group: 'alerts',
                                    type: 'error',
                                    title: 'Row '+(i + 2)+' column count does not match headers.  Please check the CSV file and try again',
                                    duration: 5000,
                                    speed: 1000
                                });

                                return
                            }
                        }
                    }
                })
            },
            loadCurrentPage(page) {
                console.log('Loading page: '+page);
                this.loadPage(page, this.current_per_page);
            },
            save() {
                const field_ids = this.import_item.parameters.fields.map(field => field.id);
                const field_names = this.import_item.parameters.fields.map(field => field.name);
                let data = [];

                for(let i = 0 ; i < this.import_data.data.length ; ++i) {
                    if (this.delete_rows[i] !== undefined) {
                        continue;
                    }

                    data.push(this.import_data.data[i]);
                }

                const csv_data = new Blob([this.$papa.unparse({fields: field_names, data: data})], {type: 'text/csv;charset=utf-8;'});
                const form_data = new FormData();
                form_data.append('number_id', this.import_item.number_id);
                form_data.append('name', this.import_item.name);
                form_data.append('segment_id', (this.import_item.segment_id ? this.import_item.segment_id : ''));
                form_data.append('notes', this.import_item.notes);
                form_data.append('parameters', JSON.stringify({field_ids: field_ids}));
                form_data.append('file', csv_data, 'import.csv');

                this.$http.post('/api/v1/imports', form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'The import has been uploaded.  Please wait while data is being processed.',
                        duration: 5000,
                        speed: 1000
                    });

                    this.$router.push({name: 'list_imports'});
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while creating the import',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
            continueStep() {
                this.errors = Object.assign({});

                if (this.current_step === 1) {
                    if (this.import_item.name === '') {
                        this.errors['name'] = ['Please provide a name for the import'];
                    }
                    if (this.import_data === null) {
                        this.errors['import_file'] = ['Please select a valid CSV file to import'];
                    }

                    if (Object.keys(this.errors).length > 0) {
                        return;
                    }

                    this.loadPage(1, 5);
                    this.current_step = 2;
                } else if (this.current_step === 2) {
                    this.validateData();

                    this.loadPage(1, this.current_per_page);
                    this.current_step = 3;
                } else if (this.current_step === 3) {
                    this.validateData();

                    this.current_step = 4;
                } else if (this.current_step === 4) {
                    this.save();
                }
            },
            goBack() {
                if (this.current_step === 4) {
                    this.current_step = 3;
                } else if (this.current_step === 3) {
                    this.current_step = 2;
                } else if (this.current_step === 2) {
                    this.current_step = 1;
                }
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
