<template>
    <div>
        <layout-header title="Edit Sequence"></layout-header>

        <form-section title="Sequence Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="sequence.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="sequence.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>
        </form-section>

        <form-section title="Keywords">
            <form-field wide>
                <div class="flex flex-wrap mb-6 -mx-3" v-for="(keyword, index) in sequence.keywords" v-bind:key="keyword.id">
                    <div class="w-full px-3 mb-6 md:w-2/5 md:mb-0">
                        <form-input label="Keyword/Phrase" :id="'keyword_keyword_'+index" name="'keyword_keyword_'+index" type="text" v-model="sequence.keywords[index].keyword" />
                        <form-validation :param="'keywords.'+index+'.keyword'" :errors="errors"></form-validation>
                    </div>
                    <div class="w-full px-3 mb-6 md:w-2/5 md:mb-0">
                        <form-select label="Rule" :id="'keyword_rule_'+index" :name="'keyword_rule_'+index" v-model="sequence.keywords[index].rule">
                            <option value="exact">Exact Match</option>
                            <option value="start">Starts With</option>
                            <option value="contain">Contains</option>
                        </form-select>
                        <form-validation :param="'keywords.'+index+'.rule'" :errors="errors"></form-validation>
                    </div>
                    <div class="w-full h-full pt-6 pl-6 text-right cursor-pointer md:w-1/5">
                        <form-button negative-action @click="removeKeyword(index)"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                    </div>
                </div>

                <div class="flex flex-wrap mx-3 mb-6">
                    <form-button positive-action @click="addKeyword()"><i class="fas fa-plus-circle"></i> ADD</form-button>
                </div>
            </form-field>
        </form-section>

        <form-section title="Sequence Builder">
            <form-field wide>
                <div class="">
                    <div class="flex w-full">
                        <!-- Builder -->
                        <div class="w-1/3 pb-4 overflow-hidden">
                            <div class="mb-8">
                                <Builder :sequence="sequence" :current_block="current_block" :set-active-block="setActiveBlock" :remove-block="removeBlock"></Builder>
                            </div>

                            <div class="px-3">
                                <form-button positive-action @click="addBlockDialog();"><i class="fas fa-plus-circle"></i> ADD</form-button>
                            </div>
                        </div>

                        <div class="w-2/3 pb-4 overflow-hidden">
                            <div v-if="current_block !== null" class="p-4 mx-6 bg-gray-100 border-2 rounded-lg border-sbr-purple">
                                <!-- Subscription Block -->
                                <div v-if="current_block.messaging_type === 'subscription'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Subscription Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Send a confirmation to the person.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field wide>
                                            <form-select label="Opt-In Type" id="block_message_optin" name="block_message_optin" v-model="current_block.parameters.optin_type">
                                                <option value="single">Single Opt-In</option>
                                                <option value="double">Double Opt-In</option>
                                            </form-select>
                                            <form-validation param="parameters.optin_type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Confirmation Message"
                                                name="block_message_confirm"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-if="current_block.parameters.optin_type === 'double'"
                                                v-model="current_block.parameters.confirm">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.confirm" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.confirm.type" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.confirm.subject" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.confirm.message" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.confirm.fallback" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.confirm.files" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Rejection Message"
                                                name="block_message_reject"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-if="current_block.parameters.optin_type === 'double'"
                                                v-model="current_block.parameters.reject">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.reject" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.reject.type" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.reject.subject" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.reject.message" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.reject.fallback" :errors="current_block.errors"></form-validation>
                                            <form-validation param="parameters.reject.files" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Subscriber Welcome Block -->
                                <div v-if="current_block.messaging_type === 'subscription_welcome'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">New Subscriber</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Execute items if the person is a new subscriber.</div>
                                </div>

                                <!-- Subscriber Exists Block -->
                                <div v-if="current_block.messaging_type === 'subscription_exists'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Existing Subscriber</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Execute items if the person is an existing subscriber.</div>
                                </div>

                                <!-- Send Text Block -->
                                <div v-if="current_block.messaging_type === 'message'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Send Text Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Send a message to the person</p>
                                    </div>
                                    <form-validation param="parameters" :errors="current_block.errors" />

                                    <form-section clear>
                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Text"
                                                name="block_message_text"
                                                maxSms="160"
                                                maxMms="1600"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                :attributes="all_attributes"
                                                v-model="current_block.parameters.text">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.message" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Conditional Block -->
                                <div v-if="current_block.messaging_type === 'conditional'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Conditional Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Check a set of criteria and perform an action based on result.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <h2>Conditions</h2>
                                    <div class="w-full">
                                        <!-- Row 1 -->
                                        <div class="flex flex-wrap mb-6 -mx-3">
                                            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                                <form-select label="Join By" id="block_conditional_operator" name="block_conditional_operator" v-model="current_block.parameters.conditions.operator">
                                                    <option value="AND">AND</option>
                                                    <option value="OR">OR</option>
                                                </form-select>
                                                <form-validation param="parameters.conditions.operator" :errors="current_block.errors"></form-validation>
                                            </div>
                                        </div>

                                        <!-- Conditions -->
                                        <div class="flex flex-wrap mb-6 -mx-3" v-for="(condition, condition_index) in current_block.parameters.conditions.conditions" v-bind:key="condition_index">
                                            <form-validation :param="'parameters.conditions.conditions.'+condition_index" :errors="current_block.errors"></form-validation>

                                            <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0">
                                                <form-select label="Operator" :id="'block_conditional_'+condition_index+'_operator'" :name="'block_conditional_'+condition_index+'_operator'" v-model="condition.operator">
                                                    <optgroup label="Attribute">
                                                        <option value="=">Equal To</option>
                                                        <option value="!=">Not Equal To</option>
                                                        <option value="<">Less Than</option>
                                                        <option value="<=">Less Than Or Equal To</option>
                                                        <option value=">">Greater Than</option>
                                                        <option value=">=">Greater Than Or Equal To</option>
                                                        <option value="exists">Exists</option>
                                                        <option value="not_exists">Does Not Exist</option>
                                                    </optgroup>
                                                    <optgroup label="Segment">
                                                        <option value="on">On Segment</option>
                                                        <option value="not_on">Not On Segment</option>
                                                    </optgroup>
                                                </form-select>
                                                <form-validation :param="'parameters.conditions.conditions.'+condition_index+'.operator'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full px-3 mb-6 md:w-2/5 md:mb-0" v-if="condition.operator !== 'on' && condition.operator !== 'not_on'">
                                                <form-select label="Attribute" :id="'block_conditional_'+condition_index+'_attribute_id'" :name="'block_conditional_'+condition_index+'_attribute_id'" v-model="condition.attribute_id">
                                                    <option :value="null">- Select Attribute -</option>
                                                    <option v-for="attribute in all_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                </form-select>
                                                <form-validation :param="'parameters.conditions.conditions.'+condition_index+'.attribute_id'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full px-3 mb-6 md:w-3/5 md:mb-0" v-if="condition.operator === 'on' || condition.operator === 'not_on'">
                                                <form-select label="Segment" :id="'block_conditional_'+condition_index+'_segment_id'" :name="'block_conditional_'+condition_index+'_segment_id'" v-model="condition.segment_id">
                                                    <option :value="null">- Select Segment -</option>
                                                    <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                                                </form-select>
                                                <form-validation :param="'parameters.conditions.conditions.'+condition_index+'.segment_id'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="condition.operator !== 'on' && condition.operator !== 'not_on' && condition.operator !== 'exists' && condition.operator !== 'not_exists'">
                                                <form-input label="Value" :id="'block_conditional_'+condition_index+'_value'" :name="'block_conditional_'+condition_index+'_value'" v-model="condition.value" />
                                                <form-validation :param="'parameters.conditions.conditions.'+condition_index+'.value'" :errors="current_block.errors"></form-validation>
                                            </div>

                                            <div class="w-full h-full pt-6 pl-6 text-right cursor-pointer md:w-1/5">
                                                <form-button negative-action @click="removeCondition(condition_index)"><i class="fas fa-minus-circle"></i></form-button>
                                            </div>
                                        </div>

                                        <div class="flex flex-wrap mx-3 mb-6">
                                            <form-button positive-action @click="addCondition()"><i class="fas fa-plus-circle"></i> ADD</form-button>
                                        </div>

                                        <form-validation param="parameters.conditions.conditions" :errors="current_block.errors"></form-validation>
                                    </div>
                                </div>

                                <!-- Collect Attribute Block -->
                                <div v-if="current_block.messaging_type === 'collect_attribute'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Collect Attribute Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Request a response to store as an attribute field on the user's profile.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field wide>
                                            <form-select label="Attribute" id="block_collect_attribute_attribute_id" name="block_collect_attribute_attribute_id" v-model="current_block.parameters.attribute_id">
                                                <option value="">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="parameters.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Request Message"
                                                name="block_collect_attribute_request"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.request">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.request" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Valid Response Message"
                                                name="block_collect_attribute_valid"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.valid">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.valid" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Invalid Response Message"
                                                name="block_collect_attribute_invalid"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.invalid">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.invalid" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Collect File Block -->
                                <div v-if="current_block.messaging_type === 'collect_file'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Collect File Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Request a response to store as a file on the user's profile.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field wide>
                                            <form-input label="File Key" id="block_collect_file_file_key" name="block_collect_file_file_key" v-model="current_block.parameters.file_key" />
                                            <form-validation param="parameters.file_key" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <form-select label="File Type" id="block_collect_file_file_type" name="block_collect_file_file_type" v-model="current_block.parameters.file_type">
                                                <option :value="null">- Select File Type -</option>
                                                <option value="image">Image</option>
                                                <option value="image">Audio</option>
                                                <option value="image">Video</option>
                                            </form-select>
                                            <form-validation param="parameters.file_type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Request Message"
                                                name="block_collect_file_request"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.request">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.request" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Valid Response Message"
                                                name="block_collect_file_valid"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.valid">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.valid" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Invalid Response Message"
                                                name="block_collect_file_invalid"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.invalid">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.invalid" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Tag Attribute Block -->
                                <div v-if="current_block.messaging_type === 'tag_attribute'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Tag Attribute Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Tag a caller with the associated attribute and value.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Attribute" id="block_tag_attribute_attribute_id" name="block_tag_attribute_attribute_id" v-model="current_block.parameters.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="parameters.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Value" id="block_tag_attribute_value" name="block_tag_attribute_value" v-model="current_block.parameters.value" />
                                            <form-validation param="parameters.value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Add to Segment Block -->
                                <div v-if="current_block.messaging_type === 'add_to_segment'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Add to Segment Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Add a caller to a segment.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Segment" id="block_add_to_segment_segment_id" name="block_add_to_segment_segment_id" v-model="current_block.parameters.segment_id">
                                                <option :value="null">- Select Segment -</option>
                                                <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                                            </form-select>
                                            <form-validation param="parameters.segment_id" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Drip Message Block -->
                                <div v-if="current_block.messaging_type === 'drip_message'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Drip Message Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Continue messaging a person after a delay.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Segment" id="block_drip_message_send_at" name="block_drip_message_send_at" v-model="current_block.parameters.send_at">
                                                <option :value="null">- Select Hours -</option>
                                                <option v-for="hour in hours" v-bind:key="hour" :value="hour">{{ hour + (hour === 1 ? ' hour' : ' hours') }}</option>
                                            </form-select>
                                            <form-validation param="parameters.send_at" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Send If Active" id="block_drip_message_send_active" name="block_drip_message_send_active" v-model="current_block.parameters.send_active">
                                                <option :value="true">Yes</option>
                                                <option :value="false">No</option>
                                            </form-select>
                                            <p class="mb-2 text-xs italic">If yes, will only send if the user doesn't respond/trigger another sequence. If no, will send at that time regardless of the user's interactions with other sequences.</p>
                                            <form-validation param="parameters.send_active" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Dynamic Content Block -->
                                <div v-if="current_block.messaging_type === 'dynamic_content'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Dynamic Content Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Send an HTTP post out to a 3rd party service, providing dynamic interactions.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field wide>
                                            <dynamic-textarea-basic
                                                label="URL"
                                                name="block_dynamic_content_url"
                                                max="1600"
                                                v-model="current_block.parameters.url"
                                                :attributes="all_attributes"
                                            >
                                            </dynamic-textarea-basic>
                                            <form-validation :param="'blocks.'+current_block_index+'.parameters.url'" :errors="errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Notification Block -->
                                <div v-if="current_block.messaging_type === 'notification'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Notification Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Send a notification outside the system during or after a sequence interaction.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field wide>
                                            <form-select label="Notification Type" id="block_notification_type" name="block_notification_type" v-model="current_block.parameters.type" @change="clearBlockErrors(current_block)">
                                                <option :value="null">- Select Notification Type -</option>
                                                <option value="email">Email</option>
                                                <option value="url_get">URL - GET Request</option>
                                                <option value="url_post_xml">URL - POST Request (XML)</option>
                                                <option value="url_post_json">URL - POST Request (JSON)</option>
                                                <!--<option value="sms">SMS</option>-->
                                            </form-select>
                                            <form-validation param="parameters.type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.parameters.type === 'email' || current_block.parameters.type === 'sms'">
                                            <form-input label="Recipient" id="block_notification_recipient" name="block_notification_recipient" type="text" v-model="current_block.parameters.recipient" />
                                            <form-validation param="parameters.recipient" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.parameters.type === 'email'">
                                            <dynamic-textarea-basic
                                                label="Subject"
                                                name="block_notification_subject"
                                                max="1600"
                                                v-model="current_block.parameters.subject"
                                                :attributes="all_attributes">
                                            </dynamic-textarea-basic>
                                            <form-validation param="parameters.subject" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.parameters.type === 'url_get' || current_block.parameters.type === 'url_post_xml' || current_block.parameters.type === 'url_post_json'">
                                            <dynamic-textarea-basic
                                                label="URL"
                                                name="block_notification_url"
                                                max="1600"
                                                v-if="current_block.parameters.type === 'url_get' || current_block.parameters.type === 'url_post_xml' || current_block.parameters.type === 'url_post_json'"
                                                v-model="current_block.parameters.url"
                                                :attributes="all_attributes">
                                            </dynamic-textarea-basic>
                                            <form-validation param="parameters.url" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="current_block.parameters.type !== 'url_get'">
                                            <dynamic-textarea-basic
                                                label="Body"
                                                name="block_notification_text"
                                                max="1600"
                                                v-if="current_block.parameters.type !== 'url_get'"
                                                v-model="current_block.parameters.text"
                                                :attributes="all_attributes">
                                            </dynamic-textarea-basic>
                                            <form-validation param="parameters.text" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Google Civic Polling Locator Block -->
                                <div v-if="current_block.messaging_type === 'google_civic_polling_locator'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Google Civic Polling Locator Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Request an address to check against an election for polling location details.</div>

                                    <form-section clear>
                                        <form-field wide>
                                            <form-select label="Election" id="block_google_civic_polling_locator_election_id" name="block_google_civic_polling_locator_election_id" v-model="current_block.parameters.election_id">
                                                <option value="">- Select Election -</option>
                                                <option v-for="election in integrations.google_civic_api.elections" v-bind:key="election.id" :value="election.id">{{ election.name }}</option>
                                            </form-select>
                                            <form-validation :param="'blocks.'+current_block_index+'.parameters.election_id'" :errors="errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Request Message"
                                                name="block_collect_attribute_request"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.request">
                                            </dynamic-textarea>
                                            <form-validation :param="'blocks.'+current_block_index+'.parameters.request'" :errors="errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Valid Response Message"
                                                name="block_collect_attribute_valid"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                showPollingLocatorOptions
                                                v-model="current_block.parameters.valid">
                                            </dynamic-textarea>
                                            <form-validation :param="'blocks.'+current_block_index+'.parameters.valid'" :errors="errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="No Results Response Message"
                                                name="block_collect_attribute_notfound"
                                                maxSms="160"
                                                maxMms="1600"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.notfound">
                                            </dynamic-textarea>
                                            <form-validation :param="'blocks.'+current_block_index+'.parameters.notfound'" :errors="errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Invalid Response Message"
                                                name="block_collect_attribute_invalid"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.invalid">
                                            </dynamic-textarea>
                                            <form-validation :param="'blocks.'+current_block_index+'.parameters.invalid'" :errors="errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Forward to Sequence Block -->
                                <div v-if="current_block.messaging_type === 'forward_to_sequence'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Forward to Sequence Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Forward the person to another sequence.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Sequence" id="block_forward_to_sequence_sequence_id" name="block_forward_to_sequence_sequence_id" v-model="current_block.parameters.sequence_id">
                                                <option :value="null">- Select Sequence -</option>
                                                <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                            </form-select>
                                        <form-validation param="parameters.sequence_id" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Forward to Inbox Block -->
                                <div v-if="current_block.messaging_type === 'forward_to_inbox'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Forward to Sequence Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Forward the person to the inbox for live agent chat.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Delay Sequence Processing (in hours)" type="number" class="form-text" id="block_forward_to_inbox_delay_timespan" name="block_forward_to_inbox_delay_timespan" v-model.number="current_block.parameters.delay_timespan" />
                                            <form-validation param="parameters.sequence_id" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- True Block -->
                                <div v-if="current_block.messaging_type === 'true'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">True Result</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Execute items if the conditions specified are true.</div>
                                </div>

                                <!-- False Block -->
                                <div v-if="current_block.messaging_type === 'false'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">False Result</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Execute items if the conditions specified are not true.</div>
                                </div>

                                <!-- Date Gate Block -->
                                <div v-if="current_block.messaging_type === 'date_gate'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Date Gate Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Determine path based on current date/time.</p>
                                    </div>
                                    <form-validation param="parameters" :errors="current_block.errors" />

                                    <form-section clear>
                                        <form-field>
                                            <form-datepicker id="block_date_gate_date_start" name="block_date_gate_date_start" label="Date Start" v-model="current_block.parameters.date_start" type="datetime" format="YYYY-MM-DD HH:mm:ss" valueFormat="format"></form-datepicker>
                                            <form-validation param="parameters.date_start" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                        <form-field>
                                            <form-datepicker id="block_date_gate_date_end" name="block_date_gate_date_end" label="Date End" v-model="current_block.parameters.date_end" type="datetime" format="YYYY-MM-DD HH:mm:ss" valueFormat="format"></form-datepicker>
                                            <form-validation param="parameters.date_end" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Date Gate Before Block -->
                                <div v-if="current_block.messaging_type === 'date_before'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Before Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Occurs before specified date range.</p>
                                    </div>
                                    <form-validation param="parameters" :errors="current_block.errors" />
                                </div>

                                <!-- Date Gate During Block -->
                                <div v-if="current_block.messaging_type === 'date_during'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Date Gate Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Occurs during specified date range.</p>
                                    </div>
                                    <form-validation param="parameters" :errors="current_block.errors" />
                                </div>

                                <!-- Date Gate After Block -->
                                <div v-if="current_block.messaging_type === 'date_after'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Date Gate Block</div>
                                    </div>
                                    <div class="mb-2">
                                        <p class="text-sm italic text-gray-500">Occurs after specified date range.</p>
                                    </div>
                                    <form-validation param="parameters" :errors="current_block.errors" />
                                </div>

                                <!-- Menu Block -->
                                <div v-if="current_block.messaging_type === 'menu'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Menu Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Provide a list of options the person can select.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Choices" name="block_menu_choices" id="block_menu_choices" step="1" min="1" max="9" :modelValue="getBlocksLength()" @update:modelValue="choices => updateMenuChoices(choices)" />
                                        </form-field>
                                        <form-field>
                                            <form-select label="Attribute" id="block_menu_attribute_id" name="block_menu_attribute_id" v-model="current_block.parameters.attribute_id">
                                                <option value="">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="parameters.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Request Message"
                                                name="block_menu_request"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.request">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.request.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Invalid Message"
                                                name="block_menu_invalid"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.invalid">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.invalid.message" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Random Block -->
                                <div v-if="current_block.messaging_type === 'random'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Random Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Provide a list of random options for the user.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Choices" name="block_menu_choices" id="block_menu_choices" type="number" step="1" min="1" max="9" :modelValue="getBlocksLength()" @update:modelValue="choices => updateRandomChoices(choices)" />
                                        </form-field>

                                        <form-field>
                                            <form-select label="Attribute" id="block_menu_attribute_id" name="block_menu_attribute_id" v-model="current_block.parameters.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="parameters.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Menu Item Block -->
                                <div v-if="current_block.messaging_type === 'menu_item'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Menu Item Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">View details for a specific menu item.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Option" id="block_menu_item_option" name="block_menu_item_option" v-model="current_block.parameters.option">
                                                <option :value="null">- Select Option -</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                                <option value="E">E</option>
                                                <option value="F">F</option>
                                            </form-select>
                                            <form-validation param="parameters.option" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Name" id="block_menu_item_name" name="block_menu_item_name" v-model="current_block.parameters.name" />
                                            <form-validation param="parameters.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Attribute Value" id="block_menu_item_attribute_value" name="block_menu_item_attribute_value" v-model="current_block.parameters.attribute_value" />
                                            <form-validation param="parameters.attribute_value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Random Item Block -->
                                <div v-if="current_block.messaging_type === 'random_item'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Random Item Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">View details for a specific random item.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Name" class="form-text" id="block_random_item_name" name="block_random_item_name" v-model="current_block.parameters.name" />
                                            <form-validation param="parameters.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Percentage" id="block_random_item_percent" name="block_random_item_percent" v-model.number="current_block.parameters.percent" />
                                            <form-validation param="parameters.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Attribute Value" id="block_random_item_attribute_value" name="block_random_item_attribute_value" v-model="current_block.parameters.attribute_value" />
                                            <form-validation param="parameters.attribute_value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Poll Block -->
                                <div v-if="current_block.messaging_type === 'poll'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Poll Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Provide a list of options the person can select.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Choices" name="block_poll_choices" id="block_poll_choices" type="number" step="1" min="1" max="9" :modelValue="getBlocksLength()" @update:modelValue="choices => updatePollChoices(choices)" />
                                        </form-field>

                                        <form-field>
                                            <form-select label="Attribute" id="block_poll_attribute_id" name="block_poll_attribute_id" v-model="current_block.parameters.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="parameters.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Voting Type" id="block_poll_voting_type" name="block_poll_voting_type" v-model="current_block.parameters.voting_type">
                                                <option :value="null">- Select Voting Type -</option>
                                                <option value="once">Once Only</option>
                                                <option value="first_only">First Time Only</option>
                                                <option value="last_only">Latest Time Only</option>
                                                <option value="multiple">Multiple Times</option>
                                            </form-select>
                                            <form-validation param="parameters.voting_type" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Allow External Access" id="block_poll_allow_external_access" name="block_poll_allow_external_access" v-model="current_block.parameters.allow_external_access">
                                                <option :value="null">- Select External Access Option -</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </form-select>
                                            <form-validation param="parameters.allow_external_access" :errors="current_block.errors"></form-validation>
                                            <p class="pb-3 text-xs italic" v-if="current_block.parameters.allow_external_access === 'true'">
                                                <a :href="'/sequences/'+sequence.id+'/blocks/'+current_block.id+'/data'" target="_blank">View Results</a> (save first to see updates)
                                            </p>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Send Results" id="block_poll_send_results" name="block_poll_send_results" v-model="current_block.parameters.send_results">
                                                <option :value="null">- Select Send Results Option -</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </form-select>
                                            <form-validation param="parameters.send_results" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Request Message"
                                                name="block_menu_request"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.request">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.request.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide>
                                            <dynamic-textarea
                                                label="Invalid Message"
                                                name="block_menu_invalid"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.invalid">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.invalid.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.parameters.voting_type === 'once'" wide>
                                            <dynamic-textarea
                                                label="Already Voted Type"
                                                name="block_menu_voted"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                v-model="current_block.parameters.voted">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.voted.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field v-if="current_block.parameters.send_results === 'true'" wide>
                                            <dynamic-textarea
                                                label="Results Message"
                                                name="block_poll_results"
                                                maxSms="160"
                                                maxMms="1600"
                                                :attributes="all_attributes"
                                                :allowShortLinks="allow_short_links"
                                                :allowTrackableLinks="allow_trackable_links"
                                                showPollOptions
                                                v-model="current_block.parameters.results">
                                            </dynamic-textarea>
                                            <form-validation param="parameters.results.message" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field wide v-if="(typeof current_block.id === 'number')">
                                            <form-button @click="showPollResults(sequence.id, current_block.id)">View Results</form-button>
                                            <form-button @click="confirmClearResults(sequence.id, current_block.id)">Clear Results</form-button>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- Poll Item Block -->
                                <div v-if="current_block.messaging_type === 'poll_item'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">Poll Item Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">View details for a specific poll item.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-select label="Option" id="block_menu_item_option" name="block_menu_item_option" v-model="current_block.parameters.option">
                                                <option :value="null">- Select Option -</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                                <option value="E">E</option>
                                                <option value="F">F</option>
                                            </form-select>
                                            <form-validation param="parameters.option" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Name" id="block_poll_item_name" name="block_poll_item_name" v-model="current_block.parameters.name" />
                                            <form-validation param="parameters.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Attribute Value" id="block_poll_item_block_poll_item_attribute_value" name="block_poll_item_block_poll_item_attribute_value" v-model="current_block.parameters.attribute_value" />
                                            <form-validation param="parameters.attribute_value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- ABSplit Block -->
                                <div v-if="current_block.messaging_type === 'absplit'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">A/B Split Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">Split responses to users using A/B split testing.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>
                                    <form-validation param="blocks" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Choices" name="block_absplit_choices" id="block_absplit_choices" type="number" step="1" min="1" max="9" :modelValue="getBlocksLength()" @update:modelValue="choices => updateABSplitChoices(choices)" />
                                        </form-field>

                                        <form-field>
                                            <form-select label="Attribute" id="block_absplit_attribute_id" name="block_absplit_attribute_id" v-model="current_block.parameters.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </form-select>
                                            <form-validation param="parameters.attribute_id" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-select label="Split Type" id="block_absplit_split_type" name="block_absplit_split_type" v-model="current_block.parameters.split_type">
                                                <option :value="null">- Select Split Type -</option>
                                                <option value="first_only">First Time Only</option>
                                                <option value="last_only">Latest Time Only</option>
                                            </form-select>
                                            <form-validation param="parameters.split_type" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>

                                <!-- ABSplit Item Block -->
                                <div v-if="current_block.messaging_type === 'absplit_item'">
                                    <div class="flex">
                                        <div class="flex-1 text-lg">A/B Split Item Block</div>
                                    </div>
                                    <div class="pb-4 text-sm italic">View details for a specific A/B split item.</div>
                                    <form-validation param="parameters" :errors="current_block.errors"></form-validation>

                                    <form-section clear>
                                        <form-field>
                                            <form-input label="Name" id="block_absplit_item_name" name="block_absplit_item_name" v-model="current_block.parameters.name" />
                                            <form-validation param="parameters.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Percentage" id="block_absplit_item_percent" name="block_absplit_item_percent" v-model.number="current_block.parameters.percent" />
                                            <form-validation param="parameters.name" :errors="current_block.errors"></form-validation>
                                        </form-field>

                                        <form-field>
                                            <form-input label="Attribute Value" id="block_absplit_item_attribute_value" name="block_absplit_item_attribute_value" v-model="current_block.parameters.attribute_value" />
                                            <form-validation param="parameters.attribute_value" :errors="current_block.errors"></form-validation>
                                        </form-field>
                                    </form-section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Update Sequence
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>

        <BlockModal
            :show="open_block_modal"
            :settings="settings"
            :integrations="integrations"
            v-model:currentID="current_id"
            @add="(block) => addBlock(block)"
            @cancel="open_block_modal = false;"
        />

        <PollResultsModal
            :show="open_poll_results_modal"
            :results="poll_results"
            :total="poll_results_total"
            @cancel="open_poll_results_modal = false;"
        />

        <ConfirmModal
            name="clear-confirm"
            height="220"
            title="Clear Poll Results?"
            description="Are you sure you want to clear current results?  This action cannot be undone."
            actionText="Clear"
            :show="open_clear_results_modal"
            @confirm="clearPollResults()"
            @cancel="open_clear_results_modal = false"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormFile from '../../shared/form/File';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import FormDatepicker from '../../shared/form/DatePicker';
    import BlockModal from '../../shared/modal/BlockModal'
    import PollResultsModal from '../../shared/modal/PollResultModal';
    import ConfirmModal from '../../shared/modal/ConfirmModal';
    import Builder from './Builder'
    import DynamicTextarea from '../../shared/DynamicTextarea';
    import DynamicTextareaBasic from '../../shared/DynamicTextareaBasic';
    import ValidationNotice from '../../shared/ValidationNotice';

    export default {
        name: 'UpdateSequence',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormFile,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            FormDatepicker,
            Builder,
            BlockModal,
            DynamicTextarea,
            DynamicTextareaBasic,
            ValidationNotice,
            Builder,
            ConfirmModal,
            PollResultsModal,
        },
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            this.fetchSequence()
            this.fetchNumbers()
            this.fetchAttributes()
            this.fetchSegments()
            this.fetchSequences()
            this.fetchIntegrations()
            this.fetchSettings();
        },
        computed: {
            id () {
                return this.$route.params.id;
            },
        },
        watch: {
            hasErrors: function (obj, key) {
                var item = _.get(obj, key);

                if (item !== undefined) {
                    return true;
                } else {
                    return false;
                }
            },
            showErrors: function (obj, key) {
                var item = _.get(obj, key);

                if (item !== undefined) {
                    return item[0];
                } else {
                    return '';
                }
            }
        },
        data() {
            return {
                current_id: 0,
                numbers: [],
                attributes: [],
                all_attributes: [],
                segments: [],
                sequences: [],
                integrations: {
                    google_civic_api: {
                        status: 'inactive',
                        elections: []
                    }
                },
                sequence: {
                    id: '',
                    name: '',
                    number_id: this.$store.getters.current_number_id,
                    status: 'active',
                    keywords: [],
                    blocks: []
                },
                current_block: null,
                current_block_index: null,
                errors: [],
                block_errors: {},
                attachment_file: null,
                hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                settings: {
                    text: {
                        features: {
                        },
                        maximum_numbers: null,
                        maximum_sequences: null,
                    },
                },
                permissions: {
                    chat: false,
                    text: true,
                    talk: false,
                    lookup: false,
                    links: false,
                    connect: false,
                },
                poll_results: [],
                poll_results_total: null,
                allow_short_links: false,
                allow_trackable_links: false,
                show_help_section: null,
                open_block_modal: false,
                open_poll_results_modal: false,
                open_clear_results_modal: false,
            }
        },
        methods: {
            fetchSettings() {
                this.$http.get(`/api/v1/me`).then(this.refreshSettings);
            },
            refreshSettings({data}) {
                this.settings = data.data.settings;
                this.permissions = data.data.permissions;

                if (this.permissions.links) {
                    this.allow_short_links = true

                    if (this.settings.links.features.link_type_trackable) {
                        this.allow_trackable_links = true
                    }
                }
            },
            fetchSequence(id) {
                this.$http.get(`/api/v1/sequences/${this.id}`).then(this.refreshSequence);
            },
            refreshSequence({data}) {
                this.setupBlocks(data.data.blocks)
                this.sequence = data.data;
            },
            fetchNumbers() {
                this.$http.get(`/api/v1/numbers?per_page=-1`).then(this.refreshNumbers);
            },
            refreshNumbers({data}) {
                this.numbers = data.data;
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?number_id=${this.$store.getters.current_number_id}&per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                this.$http.get(`/api/v1/attributes?per_page=-1`).then(this.refreshAttributes);
            },
            refreshAttributes({data}) {
                for(let index in data.data) {
                    this.all_attributes.push(data.data[index]);

                    if (data.data[index].scope !== 'system') {
                        this.attributes.push(data.data[index]);
                    }
                }
            },
            fetchSegments() {
                this.$http.get(`/api/v1/segments?number_id=${this.$store.getters.current_number_id}&search_types=static&per_page=-1`).then(this.refreshSegments);
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchIntegrations() {
                this.$http.get(`/api/v1/integrations`).then(this.refreshIntegrtations);
            },
            refreshIntegrtations({data}) {
                for (let index in data.data) {
                    if (data.data[index].integration === 'google_civic_api' && data.data[index].status === 'active') {
                        this.integrations.google_civic_api.status = 'active';
                        this.integrations.google_civic_api.elections = this.getGoogleCivicAPIElections(data.data[index].parameters.api_key);
                    }
                }
            },
            getGoogleCivicAPIElections(api_key) {
                this.$http.get(`https://www.googleapis.com/civicinfo/v2/elections?key=${api_key}`, {transformRequest: (data, headers) => {
                    delete headers['Authorization']
                    delete headers['X-CSRF-TOKEN']
                    delete headers['X-Socket-Id']
                }}).then(this.refreshElections);
            },
            refreshElections(data) {
                this.integrations.google_civic_api.elections = data.data.elections;
            },
            setupBlocks(blocks) {
                for (let i = 0 ; i < blocks.length ; ++i) {
                    blocks[i]['errors'] = []

                    if (blocks[i].hasOwnProperty('blocks')) {
                        this.setupBlocks(blocks[i].blocks)
                    }
                }
            },
            save() {
                this.$http.put('/api/v1/sequences/'+this.id, this.sequence).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'The sequence has been updated',
                        duration: 5000,
                        speed: 1000
                    });

                    this.$router.push({
                        name: 'list_sequences'
                    });
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {}
                        this.block_errors = {}
                        this.clearAllBlockErrors(this.sequence.blocks)

                        for (var block_index in this.errors) {
                            if (block_index.substr(0, 6) === 'blocks') {
                                var full_index = block_index.substring(7, block_index.lastIndexOf('.parameters'))
                                var param = block_index.substring(block_index.lastIndexOf('.parameters') + 1)

                                if (!this.block_errors[full_index]) {
                                    this.block_errors[full_index] = {}
                                }

                                if (!this.block_errors[full_index][param]) {
                                    this.block_errors[full_index][param] = []
                                }

                                this.block_errors[full_index][param] = this.errors[block_index]

                                full_index = block_index.substring(7, block_index.lastIndexOf('.blocks'))
                                param = block_index.substring(block_index.lastIndexOf('.blocks') + 1)

                                if (!this.block_errors[full_index]) {
                                    this.block_errors[full_index] = {}
                                }

                                if (!this.block_errors[full_index][param]) {
                                    this.block_errors[full_index][param] = []
                                }

                                this.block_errors[full_index][param] = this.errors[block_index]
                            }
                        }

                        this.checkBlockErrors(this.sequence.blocks, '')
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while updating the sequence',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
            clearBlockErrors(block) {
                block.errors = []
            },
            clearAllBlockErrors(blocks) {
                for (var block_index in blocks) {
                    blocks[block_index].errors = []

                    if (blocks[block_index].messaging_type === 'menu' || blocks[block_index].messaging_type === 'menu_item' || blocks[block_index].messaging_type === 'random' || blocks[block_index].messaging_type === 'random_item'
                    || blocks[block_index].messaging_type === 'subscription_welcome' || blocks[block_index].messaging_type === 'subscription_exists' || blocks[block_index].messaging_type === 'poll'  || blocks[block_index].messaging_type === 'poll_item'
                    || blocks[block_index].messaging_type === 'absplit' || blocks[block_index].messaging_type === 'absplit_item' ) {
                        this.clearAllBlockErrors(blocks[block_index].blocks)
                    }
                }
            },
            checkBlockErrors(blocks, parent_index) {
                for (var block_index in blocks) {
                    let display_index = this.getDisplayIndex(parent_index, block_index)

                    if (display_index in this.block_errors) {
                        blocks[block_index].errors = this.block_errors[display_index]
                    }

                    if (blocks[block_index].messaging_type === 'menu' || blocks[block_index].messaging_type === 'menu_item' || blocks[block_index].messaging_type === 'random' || blocks[block_index].messaging_type === 'random_item'
                    || blocks[block_index].messaging_type === 'subscription_welcome' || blocks[block_index].messaging_type === 'subscription_exists' || blocks[block_index].messaging_type === 'poll'  || blocks[block_index].messaging_type === 'poll_item'
                    || blocks[block_index].messaging_type === 'absplit' || blocks[block_index].messaging_type === 'absplit_item' ) {
                        this.checkBlockErrors(blocks[block_index].blocks, display_index)
                    }
                }
            },
            getDisplayIndex(parent_index, block_index) {
                return ((parent_index === '' ? '' : parent_index + '.') + block_index)
            },
            addBlockDialog() {
                this.open_block_modal = true;
            },
            addBlock(block) {
                this.sequence.blocks.push(block);
                this.setActiveBlock(block);
                this.open_block_modal = false;
            },
            setActiveBlock(block) {
                this.current_block = block
            },
            removeBlock(block, parent = null) {
                this.current_block = null;
                this.block_errors = {};
                this.errors = [];

                if (parent === null) {
                    parent = this.sequence
                }

                if (parent.blocks) {
                    for (let i = 0 ; i < parent.blocks.length ; ++i) {
                        if (parent.blocks[i].id === block.id) {
                            parent.blocks.splice(i, 1);
                            break
                        }

                        this.removeBlock(block, parent.blocks[i])
                    }
                }
            },
            getBlocksLength() {
                return this.current_block.blocks.length
            },
            findItemByID(id, parent) {
                if (parent.hasOwnProperty(blocks)) {
                    if (parent.id === id) {
                        return
                    }

                    for (let i = 0 ; i < parent.blocks ; ++i) {
                        this.findItemByID(id, parent.blocks[i])
                    }
                }
            },
            beforeMove ({ dragItem, pathFrom, pathTo }) {
                if (dragItem.messaging_type === 'true' || dragItem.messaging_type === 'false') {
                    return false
                }

                return true
            },
            addKeyword() {
                this.sequence.keywords.push({
                    keyword: '',
                    rule: 'exact',
                });
            },
            removeKeyword(index) {
                this.sequence.keywords.splice(index, 1);
            },
            addCondition() {
                this.current_block.parameters.conditions.conditions.push({
                    attribute_id: null,
                    segment_id: null,
                    operator: '=',
                    value: ''
                });
            },
            removeCondition(condition_index) {
                this.current_block.parameters.conditions.conditions.splice(condition_index, 1);
            },
            submitFile(offset) {
                let formData = new FormData();
                formData.append('file', this.attachment_file);

                this.$http.post('/api/v1/attachments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (offset) {
                        this.current_block.parameters[offset].attachment_key = response.data.attachment_key;
                        this.current_block.parameters[offset].attachment_mimetype = response.data.attachment_mimetype;
                    } else {
                        this.current_block.parameters.attachment_key = response.data.attachment_key;
                        this.current_block.parameters.attachment_mimetype = response.data.attachment_mimetype;
                    }
                }).catch(error => {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while uploading the attachment',
                        duration: 5000,
                        speed: 1000
                    });
                });
            },
            removeFile(offset) {
                if (offset && this.current_block.parameters[offset].attachment_key) {
                    this.current_block.parameters[offset].attachment_key = null
                    this.current_block.parameters[offset].attachment_mimetype = null
                } else {
                    this.current_block.parameters.attachment_key = null
                    this.current_block.parameters.attachment_mimetype = null
                }
            },
            handleFileUpload(offset) {
                this.attachment_file = this.$refs[offset].files[0];
            },
            updateMenuChoices(choices) {
                if (choices > 9) {
                    choices = 9
                    return
                } else if (choices < 1) {
                    choices = 1;
                    return;
                }

                if (choices > this.current_block.blocks.length) {
                    let new_count = (choices - this.current_block.blocks.length);

                    for (let i = 0 ; i < new_count ; ++i) {
                        this.current_block.blocks.push({
                            'id': ('new_'+(this.current_id += 1)),
                            'messaging_type': 'menu_item',
                            'parameters': {
                                'option': null,
                                'name': 'Choice 1',
                                'attribute_value': null,
                            },
                            'blocks': [],
                            'errors': [],
                        });
                    }
                } else if (choices < this.current_block.blocks.length) {
                    this.current_block.blocks.splice(choices);
                }
            },
            updatePollChoices(choices) {
                if (choices > 9) {
                    choices = 9
                    return
                } else if (choices < 1) {
                    choices = 1;
                    return;
                }

                if (choices > this.current_block.blocks.length) {
                    let new_count = (choices - this.current_block.blocks.length);

                    for (let i = 0 ; i < new_count ; ++i) {
                        this.current_block.blocks.push({
                            'id': ('new_'+(this.current_id += 1)),
                            'messaging_type': 'poll_item',
                            'parameters': {
                                'option': null,
                                'name': 'Choice 1',
                                'attribute_value': null,
                            },
                            'blocks': [],
                            'errors': [],
                        });
                    }
                } else if (choices < this.current_block.blocks.length) {
                    this.current_block.blocks.splice(choices);
                }
            },
            updateRandomChoices(choices) {
                if (choices > 9) {
                    choices = 9
                    return
                } else if (choices < 1) {
                    choices = 1;
                    return;
                }

                if (choices > this.current_block.blocks.length) {
                    let new_count = (choices - this.current_block.blocks.length);

                    for (let i = 0 ; i < new_count ; ++i) {
                        this.current_block.blocks.push({
                            'id': ('new_'+(this.current_id += 1)),
                            'messaging_type': 'random_item',
                            'parameters': {
                                'percent': 100,
                                'name': 'Choice 1',
                                'attribute_value': null,
                            },
                            'blocks': [],
                            'errors': [],
                        });
                    }
                } else if (choices < this.current_block.blocks.length) {
                    this.current_block.blocks.splice(choices);
                }
            },
            updateABSplitChoices(choices) {
                if (choices > 9) {
                    choices = 9
                    return
                } else if (choices < 1) {
                    choices = 1;
                    return;
                }

                if (choices > this.current_block.blocks.length) {
                    let new_count = (choices - this.current_block.blocks.length);

                    for (let i = 0 ; i < new_count ; ++i) {
                        this.current_block.blocks.push({
                            'id': ('new_'+(this.current_id += 1)),
                            'messaging_type': 'absplit_item',
                            'parameters': {
                                'percent': 100,
                                'name': 'Choice 1',
                                'attribute_value': null,
                            },
                            'blocks': [],
                            'errors': [],
                        });
                    }
                } else if (choices < this.current_block.blocks.length) {
                    this.current_block.blocks.splice(choices);
                }
            },
            showPollResults(sequence_id, sequence_block_id) {
                this.poll_results = null

                this.fetchPollResults(sequence_id, sequence_block_id)
            },
            fetchPollResults(sequence_id, sequence_block_id) {
                return this.$http.get(`/api/v1/sequences/${sequence_id}/blocks/${sequence_block_id}/data`).then(this.refreshPollResults);
            },
            refreshPollResults({data}) {
                this.poll_results_total = data.data.total
                this.poll_results = data.data.results

                this.open_poll_results_modal = true;
            },
            confirmClearResults(sequence_id, sequence_block_id) {
                this.clear_poll_sequence_id = sequence_id
                this.clear_poll_sequence_block_id = sequence_block_id

                this.open_clear_results_modal = true;
            },
            clearPollResults() {
                return this.$http.delete(`/api/v1/sequences/${this.clear_poll_sequence_id}/blocks/${this.clear_poll_sequence_block_id}/data`).then(this.postClearResults);
            },
            postClearResults({data}) {
                this.clear_poll_sequence_id = null
                this.clear_poll_sequence_block_id = null

                this.open_clear_results_modal = false;
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }

</script>
