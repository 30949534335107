<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Insert Short Link</DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">Using SeeBotLink, you can insert or create dynamic short links.</p>
                            </div>
                            <div class="mt-8">
                                <form-field>
                                    <form-select label="Domain" id="sl_domain" name="sl_domain" v-model="link.domain_id" :readonly="link.id !== null" @change="updateLinkDetails()">
                                        <option :value="null">- Select Domain -</option>
                                        <option v-for="domain in domains" v-bind:key="domain.id" :value="domain.id">{{ domain.name }}</option>
                                    </form-select>
                                    <form-validation param="domain_id" :errors="link.errors" />
                                </form-field>

                                <form-field v-if="link.domain_id !== null">
                                    <form-select label="Action" id="sl_action" name="sl_action" v-model="link.action" :readonly="link.id !== null" @change="updateLinkDetails()">
                                        <option value="new">Create New Link</option>
                                        <option value="existing">Select Existing Link</option>
                                    </form-select>
                                    <form-validation param="action" :errors="link.errors" />
                                </form-field>

                                <form-field v-if="link.domain_id !== null && link.action === 'new'">
                                    <form-select label="Type" id="sl_type" name="sl_type" v-model="link.type" :readonly="link.id !== null" @change="updateLinkDetails()">
                                        <option value="default">Short URL</option>
                                        <option value="trackable_text" v-if="allowTrackableLinks">Trackable Short URL</option>
                                    </form-select>
                                    <form-validation param="type" :errors="link.errors" />
                                </form-field>

                                <form-field v-if="link.domain_id !== null && link.action === 'new'">
                                    <form-input label="Name" class="form-text" name="sl_name" id="sl_name" v-model="link.name" />
                                    <form-validation param="name" :errors="link.errors" />
                                </form-field>

                                <form-field v-if="link.domain_id !== null && link.action === 'new'">
                                    <form-input label="Path" class="form-text" name="sl_path" id="sl_path" v-model="link.path" />
                                    <form-validation param="path" :errors="link.errors" />
                                </form-field>

                                <form-field v-if="link.domain_id !== null && link.action === 'new'">
                                    <form-input label="Redirect" class="form-text" name="sl_redirect" id="sl_redirect" v-model="link.redirect" />
                                    <form-validation param="redirect" :errors="link.errors" />
                                </form-field>

                                <form-field v-if="link.action === 'existing'">
                                    <form-label label="Link" for="sl_link_id" class="form-label"></form-label>
                                    <multiselect :model-value="link.selected_link" track-by="id" label="name" placeholder="Search Links" :options="links" :multiple="false" :searchable="true" :loading="links_loading" :internal-search="false" :clear-on-search="false"  @search-change="updateLinkSearch" @update:model-value="updateLinkOnSelect"></multiselect>
                                    <form-validation param="link_id" :errors="link.errors" />
                                </form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button @click="createLink();">
                            {{ actionText }}
                        </form-button>
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'link-shortener-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
            FormField,
            FormLabel,
            FormSelect,
            FormInput,
            FormValidation,
        },
        emits: [
            'insert',
            'cancel',
            'update:link',
        ],
        props: {
            attributes: Array,
            errors: Object,
            domains: Array,
            link: {
                type: Object,
                default: {
                    id: null,
                    name: '',
                    path: '',
                    redirect: '',
                    domain_id: null,
                    action: 'new',
                    type: 'default',
                    selected_link: null,
                    errors: [],
                },
            },
            actionText: {
                type: String,
                default: 'Insert',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
            allowTrackableLinks: Boolean,
        },
        computed: {
            link_value: {
                get() {
                    return this.link
                },
                set(val) {
                    this.$emit('update:link', val);
                }
            },
        },
        data() {
            return {
                links: [],
                links_loading: false,
            };
        },
        methods: {
            updateLinkSearch(query) {
                this.links_loading = true

                this.$http.get(process.env.MIX_LINKS_URL+'/api/v1/links?domain_id='+this.link.domain_id+'&page=1&sort=desc&order=id&search='+query+'&search_statuses=active&search_types=default,predefined,trackable_text', {transformRequest: (data, headers) => {
                    delete headers['X-CSRF-TOKEN']
                    delete headers['X-Socket-Id']
                }}).then(response => {
                    this.links = response.data.data;
                    this.links_loading = false
                });
            },
            updateLinkOnSelect(entity) {
                this.link.selected_link = entity;

                if (entity) {
                    this.link.id = entity.id
                    this.link.generated_url = entity.generated_url
                } else {
                    this.link.id = null
                    this.link.generated_url = null
                }
            },
            updateLinkDetails() {
                if (this.link.domain_id === null) {
                    this.link.action = 'new'
                    this.link.type = 'default'
                }

                this.link.id = null;
                this.link.name = '';
                this.link.path = '';
                this.link.redirect = '';
                this.link.type = 'default';
                this.link.errors = [];
                this.links = [];
            },
            createLink() {
                if (this.link.action === 'new') {
                    let link_data = {
                        name: this.link.name,
                        path: this.link.path,
                        redirect: this.link.redirect,
                        status: 'active',
                        domain_id: this.link.domain_id,
                        type: this.link.type,
                    };

                    this.$http.post(process.env.MIX_LINKS_URL+'/api/v1/links', link_data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }, transformRequest: (data, headers) => {
                            delete headers['X-CSRF-TOKEN']
                            delete headers['X-Socket-Id']

                            data = JSON.stringify(data)
                            return data
                    }}).then(response => {
                        this.link.id = response.data.id;
                        this.$emit('insert', response.data.generated_url);
                    }).catch(error => {
                        this.link.errors = error.response.data.errors || {}

                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while creating the link',
                            duration: 5000,
                            speed: 1000
                        });
                    });
                } else {
                    if (this.link.id === null) {
                        this.link.errors = {
                            link_id: ['A link must be selected'],
                        };
                    } else {
                        let generated_url = ''

                        for (let i = 0 ; i < this.links.length ; ++i) {
                            if (this.links[i].id === this.link.id) {
                                generated_url = this.links[i].generated_url
                                break
                            }
                        }

                        this.$emit('insert', generated_url);
                    }
                }
            },
        },
    };
</script>
