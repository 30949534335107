<template>
    <div>
        <layout-header title="Create Number Request"></layout-header>

        <form-section title="Number Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="number.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-label id="number" label="Number"></form-label>
                <div class="flex flex-row">
                    <div class="w-2/3">
                        <form-select id="number" name="number" v-model="number.number" @change="numberUpdated()">
                            <option v-if="available_numbers.length === 0" :value="''">Click Search to find numbers</option>
                            <option v-for="(available_number, index) in available_numbers" :key="index" :value="index">{{ available_number }}</option>
                        </form-select>
                        <form-validation param="number" :errors="errors" />
                    </div>
                    <div class="w-1/3 text-right">
                        <form-button @click="showSearchDialog()" secondary>Search</form-button>
                    </div>
                </div>
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="number.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <!--<form-field>
                <form-select id="catchall_sequence_id" name="catchall_sequence_id" label="Inbound Call Sequence" v-model="number.catchall_sequence_id">
                    <option value="">- None -</option>
                    <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                </form-select>
                <form-validation param="catchall_sequence_id" :errors="errors" />
            </form-field>-->
        </form-section>

        <form-action>
            <form-button @click="save">
                Create Number
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>

        <SearchNumberModal
            v-model:numberType="number_type"
            v-model:searchType="search_type"
            v-model:searchText="search"
            :show="open_search_modal"
            @search="getAvailableNumbers()"
            @cancel="open_search_modal = false;"
        ></SearchNumberModal>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import SearchNumberModal from '../../shared/modal/SearchNumberModal';

    export default {
        name: 'CreateNumber',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            SearchNumberModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchSequences();
        },
        data() {
            return {
                sequences: [],
                available_numbers: [],
                search: '',
                search_type: '',
                number_type: 'local',
                number: {
                    name: '',
                    number: '',
                    status: 'active',
                    catchall_sequence_id: '',
                },
                errors: [],
                show_help_section: null,
                open_search_modal: false,
            }
        },
        methods: {
            fetchSequences() {
                this.$http.get(this.sequencesUrl()).then(this.refreshSequences);
            },
            sequencesUrl() {
                return `/api/v1/sequences?per_page=-1`;
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            getAvailableNumbers() {
                this.$http.get(`/api/v1/numbers/available?number_type=${this.number_type}&search_type=${this.search_type}&search=${this.search}`).then(this.refreshAvailableNumbers);
            },
            refreshAvailableNumbers({data}) {
                this.available_numbers = data.data;

                if (Object.keys(data.data).length > 0) {
                    this.number.number = Object.keys(data.data)[0];
                }

                this.numberUpdated()
                this.open_search_modal = false;
            },
            showSearchDialog() {
                this.open_search_modal = true;
            },
            save() {
                this.$http.post('/api/v1/numbers', this.number).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'The number has been created',
                        duration: 5000,
                        speed: 1000
                    });
                    this.$emitter.emit('numbers_updated');

                    this.$router.push({name: 'list_numbers'});
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
            numberUpdated() {
                if (this.number.number !== '' && (this.number.name === '' || this.number.name[0] === '+')) {
                    this.number.name = this.number.number
                }
            },
            updateNumberType() {
                this.search_type = ''
                this.search = ''
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
