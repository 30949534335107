<template>
    <div>
        <layout-header title="Edit Broadcast"></layout-header>

        <form-section title="Audience Details">
            <form-field>
                <div class="flex flex-wrap -mx-3 mb-6" v-for="(audience_segment, index) in broadcast.audience.segments" v-bind:key="index">
                    <div class="w-full md:w-4/5 px-3 mb-6 md:mb-0">
                        <form-select label="" :id="'broadcast.audience.'+index+'.id'" :name="'broadcast.audience.'+index+'.id'" v-model="broadcast.audience.segments[index].id" @change="updateEstimate()">
                            <option :value="null">- Select Segment -</option>
                            <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                        </form-select>
                        <form-validation :param="'audience.segments.'+index+'.id'" :errors="errors"></form-validation>
                    </div>
                    <div class="w-full md:w-1/5 cursor-pointer h-full pl-6 text-right">
                        <form-button negative-action @click="removeSegment(index)"><i class="fas fa-minus-circle"></i></form-button>
                    </div>
                </div>

                <div class="flex flex-wrap mx-3 mb-6">
                    <form-button positive-action @click="addSegment()"><i class="fas fa-plus-circle"></i> ADD SEGMENT</form-button>
                </div>
            </form-field>
        </form-section>

        <form-section title="Broadcast Details">
            <form-validation :param="'slices.0'" :errors="errors" v-if="errors && errors.slices"></form-validation>

            <form-field wide>
                <form-section inline :title="'Slice '+ (slice_index + 1)" v-for="(slice, slice_index) in broadcast.slices" v-bind:key="slice_index">
                    <form-field>
                        <form-select label="Type" :id="'broadcast.slices.'+slice_index+'.type'" :name="'broadcast.slices.'+slice_index+'.type'" v-model="broadcast.slices[slice_index].type" @change="updateEstimate()">
                            <option value="message">Message</option>
                            <option value="sequence">Sequence</option>
                            <option value="none">None</option>
                        </form-select>
                        <form-validation :param="'slices.'+slice_index+'.type'" :errors="errors"></form-validation>
                    </form-field>

                    <form-field>
                        <form-input label="Percentage" :id="'broadcast.slices.'+slice_index+'.percentage'" :name="'broadcast.slices.'+slice_index+'.percentage'" v-model="broadcast.slices[slice_index].percentage" @blur="updateEstimate()" />
                        <form-validation :param="'slices.'+slice_index+'.percentage'" :errors="errors"></form-validation>
                    </form-field>

                    <form-field wide v-if="broadcast.slices[slice_index].type === 'message'">
                        <dynamic-textarea
                            label="Message"
                            :name="'slice_'+slice_index+'_message'"
                            maxSms="160"
                            maxMms="1600"
                            :allowShortLinks="allow_short_links"
                            :allowTrackableLinks="allow_trackable_links"
                            v-model="broadcast.slices[slice_index].message"
                            :attributes="all_attributes">
                        </dynamic-textarea>
                        <form-validation :param="'slices.'+slice_index+'.message'" :errors="errors"></form-validation>
                    </form-field>
                    <form-field v-if="broadcast.slices[slice_index].type === 'sequence'">
                        <form-select label="Sequence" :id="'broadcast.slices.'+slice_index+'.sequence_id'" :name="'broadcast.slices.'+slice_index+'.sequence_id'" v-model="broadcast.slices[slice_index].sequence_id">
                            <option :value="null">- Select Sequence -</option>
                            <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                        </form-select>
                        <form-validation :param="'slices.'+slice_index+'.sequence_id'" :errors="errors"></form-validation>
                    </form-field>
                    <form-field v-if="broadcast.slices[slice_index].type === 'sequence'">

                    </form-field>

                    <form-field>
                        <form-select label="Tag Attribute" :id="'broadcast.slices.'+slice_index+'.attribute_id'" :name="'broadcast.slices.'+slice_index+'.attribute_id'" v-model="broadcast.slices[slice_index].attribute_id" @change="updateAttributeValue(slice_index)">
                            <option :value="null">- Select Attribute -</option>
                            <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                        </form-select>
                        <form-validation :param="'slices.'+slice_index+'.attribute_id'" :errors="errors"></form-validation>
                    </form-field>

                    <form-field>
                        <form-input label="Attribute Value" v-model="broadcast.slices[slice_index].attribute_value" v-if="broadcast.slices[slice_index].attribute && broadcast.slices[slice_index].attribute.values_type === 'open'" />
                        <form-select label="Attribute Value" :name="'broadcast.slices.'+slice_index+'.attribute_value'" v-model="broadcast.slices[slice_index].attribute_value" v-if="broadcast.slices[slice_index].attribute && broadcast.slices[slice_index].attribute.values_type === 'predefined'">
                            <option :value="null" disabled>- Select Value -</option>
                            <option v-for="(value, value_index) in broadcast.slices[slice_index].attribute.valid_values.values" v-bind:key="value_index" :value="value.value">{{ value.label }}</option>
                        </form-select>
                        <form-validation :param="'slices.'+slice_index+'.attribute_value'" :errors="errors"></form-validation>
                    </form-field>

                    <form-field>
                        <form-button @click="triggerTestDialog(slice_index)" v-if="broadcast.slices[slice_index].type !== 'none'"><i class="fas fa-vial"></i> TEST</form-button>
                        <form-button negative-action @click="removeSlice(slice_index)"><i class="fas fa-minus-circle"></i> REMOVE</form-button>
                    </form-field>
                </form-section>

                <form-field wide>
                    <form-button positive-action @click="addSlice()"><i class="fas fa-plus-circle"></i> ADD SLICE</form-button>
                </form-field>
            </form-field>
        </form-section>

        <form-section title="Schedule Details">
            <form-field>
                <form-select label="Send At" id="send_at_type" name="send_at_type" v-model="send_at_type">
                    <option value="now">Now</option>
                    <option value="later">Later</option>
                </form-select>
                <form-validation :param="'send_at_type'" :errors="errors"></form-validation>
            </form-field>
            <form-field v-if="send_at_type === 'later'">
                <form-label for="date_range">Send At</form-label>
                <form-datepicker label="Send At" v-model="broadcast.send_at" type="datetime" format="YYYY-MM-DD hh:mm a" value-format="YYYY-MM-DDTHH:mm:ssZ"></form-datepicker>
            </form-field>
        </form-section>

        <form-section title="Broadcast Summary">
            <form-field wide>
                <p v-if="send_at_type === 'now'">Sending broadcast immediately to {{ estimate.recipients || 0 }} recipients.</p>
                <p v-if="send_at_type !== 'now'">Sending broadcast on {{ sendAtDate }} at {{ sendAtTime }} to {{ estimate.recipients || 0 }} recipients (billed at {{ sendAtDateUTC }} at {{ sendAtTimeUTC }}).</p>
                <ul class="pl-5 list-disc" v-if="estimate.slices.length > 1">
                    <li v-for="(segment, index) in estimate.slices" v-bind:key="index">Slice {{ (index + 1)}} Estimate: {{ estimate.slices[index].estimate || 0 }} recipients</li>
                </ul>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Update Broadcast
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>

        <TestBroadcastModal
            name="test-broadcast"
            actionText="Send"
            :show="open_test_modal"
            :errors="test_errors"
            :numberID="broadcast.number_id"
            v-model:testPeople="test_people"
            @confirm="triggerTest()"
            @cancel="open_test_modal = false"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import moment from 'moment-timezone'
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import FormDatepicker from '../../shared/form/DatePicker';
    import DynamicTextarea from '../../shared/DynamicTextarea';
    import TestBroadcastModal from '../../shared/modal/TestBroadcastModal'

    export default {
        name: 'UpdateBroadcast',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            FormDatepicker,
            DynamicTextarea,
            TestBroadcastModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
            //this.fetchBroadcast(); // Moved to exist in fetchAttributes() callback, since we need those to rebuild slices
            this.fetchSegments();
            this.fetchSequences();
            this.fetchAttributes();
            this.fetchSettings();
        },
        computed: {
            id () {
                return this.$route.params.id;
            },
            sendAtDate: function() {
                return moment(this.broadcast.send_at).tz(moment.tz.guess()).format('MMM D, YYYY');
            },
            sendAtTime: function() {
                return moment(this.broadcast.send_at).tz(moment.tz.guess()).format('h:mm a z');
            },
            sendAtDateUTC: function() {
                return moment(this.broadcast.send_at).tz('UTC').format('MMM D, YYYY');
            },
            sendAtTimeUTC: function(tz) {
                return moment(this.broadcast.send_at).tz('UTC').format('h:mm a z');
            },
        },
        data() {
            return {
                segments: [],
                sequences: [],
                attributes: [],
                all_attributes: [],
                send_at_type: 'later',
                broadcast: {
                  number_id: this.$store.getters.current_number_id,
                  status: 'active',
                  audience: {
                      segments: [{id: null}],
                      operator: 'OR'
                  },
                  slices: [
                      {
                          percentage: 100,
                          type: 'message',
                          message: {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                          sequence_id: null,
                          attribute_id: null,
                          attribute_value: null,
                          attribute: null,
                      }
                  ],
                  send_at: null,
                },
                estimate: {
                    recipients: 0,
                    slices: [{
                        recipients: 0
                    }]
                },
                settings: {
                    text: {
                        features: {
                        },
                        maximum_numbers: null,
                        maximum_sequences: null,
                    },
                },
                permissions: {
                    chat: false,
                    text: true,
                    talk: false,
                    lookup: false,
                    links: false,
                    connect: false,
                },
                allow_short_links: false,
                allow_trackable_links: false,
                test_slice: null,
                test_people: [],
                errors: [],
                test_errors: [],
                show_help_section: false,
                open_test_modal: false,
            }
        },
        methods: {
            fetchBroadcast() {
                this.$http.get(`/api/v1/broadcasts/${this.id}`).then(this.refreshBroadcast);
            },
            refreshBroadcast({data}) {
                this.broadcast = data.data;

                for (let slice_index in this.broadcast.slices) {
                    for (let attribute_index in this.attributes) {
                        if (this.attributes[attribute_index].id === this.broadcast.slices[slice_index].attribute_id) {
                            this.broadcast.slices[slice_index].attribute = this.attributes[attribute_index];
                            break;
                        }
                    }
                }

                this.updateEstimate()
            },
            fetchSettings() {
                this.$http.get(`/api/v1/me`).then(this.refreshSettings);
            },
            refreshSettings({data}) {
                this.settings = data.data.settings;
                this.permissions = data.data.permissions;

                if (this.permissions.links) {
                    this.allow_short_links = true

                    if (this.settings.links.features.link_type_trackable) {
                        this.allow_trackable_links = true
                    }
                }
            },
            fetchSegments() {
                this.$http.get(`/api/v1/segments?number_id=${this.broadcast.number_id}`).then(this.refreshSegments);
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?number_id=${this.broadcast.number_id}&per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                this.$http.get(`/api/v1/attributes`).then(this.refreshAttributes);
            },
            refreshAttributes({
                data
            }) {
                for(let index in data.data) {
                    this.all_attributes.push(data.data[index]);

                    if (data.data[index].scope !== 'system') {
                        this.attributes.push(data.data[index]);
                    }
                }

                this.fetchBroadcast()
            },
            save() {
              let item = JSON.parse(JSON.stringify(this.broadcast));

              for (let i = 0 ; i < item.slices.length ; ++i) {
                    delete item.slices[i].attribute;
              }

              if (this.send_at_type === 'now') {
                  item.send_at = null;
              } else {
                  item.send_at = moment(item.send_at).tz('UTC').format('YYYY-MM-DD HH:mm:ss');
              }

              this.$http.put(`/api/v1/broadcasts/${this.id}`, item).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The broadcast has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_broadcasts'});
              }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while updating the broadcast',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addSegment() {
                this.broadcast.audience.segments.push({
                    id: null,
                });
            },
            removeSegment(index) {
                this.broadcast.audience.segments.splice(index, 1);
                this.updateEstimate();
            },
            addSlice() {
                this.estimate.slices.push({
                    recipient: 0
                });

                this.broadcast.slices.push({
                    percentage: 100,
                    type: 'message',
                    message: {
                        'type': 'sms',
                        'subject': '',
                        'message': '',
                        'fallback': '',
                        'files': []
                    },
                    sequence_id: null,
                    attribute_id: null,
                    attribute_value: null,
                    attribute: null,
                });

                this.updateEstimate();
            },
            removeSlice(index) {
                this.broadcast.slices.splice(index, 1);
                this.estimate.slices.splice(index, 1);

                this.updateEstimate();
            },
            updateAttributeValue(slice_index) {
                this.broadcast.slices[slice_index].attribute = null;

                for (let attribute_index in this.attributes) {
                    if (this.attributes[attribute_index].id === this.broadcast.slices[slice_index].attribute_id) {
                        this.broadcast.slices[slice_index].attribute = this.attributes[attribute_index];
                        break;
                    }
                }

                this.broadcast.slices[slice_index].attribute_value = null
            },
            updateEstimate() {
                let item = {
                    audience: this.broadcast.audience,
                    slices: this.broadcast.slices,
                    number_id: this.broadcast.number_id
                };

                this.$http.post('/api/v1/broadcasts/calculate', item).then(data => {
                    this.estimate.recipients = data.data.estimate;
                    this.estimate.slices = data.data.slices;
                });
            },
            triggerTestDialog(slice_index) {
                this.test_errors = []
                this.test_slice = this.broadcast.slices[slice_index]
                this.open_test_modal = true;
            },
            triggerTest() {
                this.test_errors = []

                let data = {
                    number_id: this.broadcast.number_id,
                    person_ids: [],
                    slice: this.test_slice,
                }

                for (let person_index in this.test_people) {
                    data.person_ids.push(this.test_people[person_index].id)
                }

                this.$http.post(`/api/v1/broadcasts/test`, data).then(this.postTriggerTest).catch(error => {
                    if (error.response.status === 422) {
                        this.test_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while creating the test message',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postTriggerTest() {
                this.open_test_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The test message has been triggered',
                    duration: 5000,
                    speed: 1000
                });

                //this.resetTriggerMessage()
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
