import Pusher from "pusher-js"
import Echo from "laravel-echo"

window._ = require('lodash');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_API_KEY,
    cluster: process.env.MIX_PUSHER_CLUSTER,
    encrypted: true
});
