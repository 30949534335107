<template>
    <div>
        <layout-header title="Home"></layout-header>

        <div class="w-full flex flex-row">
            <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md w-1/2">
                <div class="">
                    <div class="bg-white my-6">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Growth/Loss</div>
                            <line-chart
                                :data="growth_loss_data"
                                :colors="['#8368db', '#f96900']"
                            ></line-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-5 rounded-lg border-2 border-gray-200 shadow-md w-1/2">
                <div class="">
                    <div class="bg-white my-6">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Messages Sent</div>
                            <column-chart
                                :data="message_summary_data"
                                :colors="['#8368db', '#f96900']"
                            ></column-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex" v-if="broadcast_summary_data && broadcast_summary_data.recent">
            <div class="m-5 bg-white shadow-md rounded w-full">
                <div class="">
                    <div class="bg-white my-6">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Most Recent Broadcast</div>
                            <div v-for="(broadcast, broadcast_index) in broadcast_summary_data.recent" v-bind:key="broadcast_index" class="mb-8 ml-2 p-4 bg-blue-100 rounded-lg">
                                <div class="grid grid-rows-3 grid-flow-col gap-2">
                                    <div class="font-bold">Sent at {{ $filters.formatDateFriendly(broadcast_summary_data.recent[broadcast_index].send_at) }}</div>
                                    <div class="italic text-sm">Sent to <span v-for="(segment, segment_index) in broadcast_summary_data.recent[broadcast_index].audience.segments" v-bind:key="segment_index">{{ segment.name }}<span v-if="segment_index !== (broadcast_summary_data.recent[broadcast_index].audience.segments.length - 1)">, </span></span></div>
                                </div>
                                <table class="text-left w-full border-collapse">
                                    <thead>
                                        <tr>
                                            <th class="w-1/4 py-2 px-2 bg-gray-200 font-bold uppercase text-sm text-gray-800 border-b border-gray-300">Type</th>
                                            <th class="w-1/4 py-2 px-2 bg-gray-200 font-bold uppercase text-sm text-gray-800 border-b border-gray-300">Recipients</th>
                                            <th class="w-1/4 py-2 px-2 bg-gray-200 font-bold uppercase text-sm text-gray-800 border-b border-gray-300">Content</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(slice, slice_index) in broadcast_summary_data.recent[broadcast_index].slices" v-bind:key="slice_index">
                                            <td class="px-2 py-2 align-top"><span v-bind:class="{'bg-green-500': (slice.message.type === 'sms'), 'bg-blue-600': (slice.message.type === 'mms')}" class="text-white font-bold py-1 px-2 mr-3 rounded-full text-sm uppercase">{{ slice.message.type }}</span></td>
                                            <td class="px-2 py-2 align-top">{{ broadcast_summary_data.recent[broadcast_index].estimate.slices[slice_index].estimate }} recipients ({{ slice.percentage }}%)</td>
                                            <td class="px-2 py-2 align-top">
                                                <span v-if="slice.type === 'message'">{{ $filters.truncate(slice.message.message, 160, '...') }}</span>
                                                <span v-if="slice.type === 'sequence'"><router-link :to="{ name: 'edit_sequence', params: { id: slice.sequence_id }}" class="mx-3">{{ slice.sequence_name }}</router-link></span>
                                                <span v-if="slice.type === 'none'">(no content)</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="broadcast_summary_data.recent.length === 0" class="ml-2">
                                <p>No broadcasts recently sent.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex" v-if="broadcast_summary_data && broadcast_summary_data.scheduled">
            <div class="m-5 bg-white shadow-md rounded w-full">
                <div class="">
                    <div class="bg-white my-6">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Scheduled Broadcasts</div>
                            <div v-for="(broadcast, broadcast_index) in broadcast_summary_data.scheduled" v-bind:key="broadcast_index" class="mb-8 ml-2 p-4 bg-blue-100 rounded-lg">
                                <div class="grid grid-rows-3 grid-flow-col gap-2">
                                    <div class="col-span-2 font-bold">Scheduled for {{ $filters.formatDateFriendly(broadcast_summary_data.scheduled[broadcast_index].send_at) }}</div>
                                    <div class="col-span-2 italic text-sm">Sending to <span v-for="(segment, segment_index) in broadcast_summary_data.scheduled[broadcast_index].audience.segments" v-bind:key="segment_index">{{ segment.name }}<span v-if="segment_index !== (broadcast_summary_data.scheduled[broadcast_index].audience.segments.length - 1)">, </span></span></div>
                                    <div class="row-span-2 text-right">
                                        <router-link :to="{ name: 'edit_broadcast', params: { id: broadcast.id }}" class="mx-3"><i class="fas fa-pencil-alt"></i></router-link>
                                        <a href="javascript:void(0);" v-on:click="confirmBroadcastDelete(broadcast.id)" class="mx-3"><i class="fas fa-trash-alt"></i></a>
                                    </div>
                                </div>
                                <table class="text-left w-full border-collapse">
                                    <thead>
                                        <tr>
                                            <th class="w-1/4 py-2 px-2 bg-gray-200 font-bold uppercase text-sm text-gray-800 border-b border-gray-300">Type</th>
                                            <th class="w-1/4 py-2 px-2 bg-gray-200 font-bold uppercase text-sm text-gray-800 border-b border-gray-300">Recipients</th>
                                            <th class="w-1/4 py-2 px-2 bg-gray-200 font-bold uppercase text-sm text-gray-800 border-b border-gray-300">Content</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(slice, slice_index) in broadcast_summary_data.scheduled[broadcast_index].slices" v-bind:key="slice_index">
                                            <td class="px-2 py-2 align-top">
                                                <span v-if="slice.type === 'message'" v-bind:class="{'bg-green-500': (slice.message.type === 'sms'), 'bg-blue-600': (slice.message.type === 'mms')}" class="text-white font-bold py-1 px-2 mr-3 rounded-full text-sm uppercase">{{ slice.message.type }}</span>
                                                <span v-if="slice.type === 'sequence'" class="bg-red-500 text-white font-bold py-1 px-2 mr-3 rounded-full text-sm uppercase">Sequence</span>
                                                <span v-if="slice.type === 'none'" class="bg-gray-500 text-white font-bold py-1 px-2 mr-3 rounded-full text-sm uppercase">None</span>
                                            </td>
                                            <td class="px-2 py-2 align-top">{{ broadcast_summary_data.scheduled[broadcast_index].estimate.slices[slice_index].estimate }} recipients ({{ slice.percentage }}%)</td>
                                            <td class="px-2 py-2 align-top">
                                                <span v-if="slice.type === 'message'">{{ $filters.truncate(slice.message.message, 160, '...') }}</span>
                                                <span v-if="slice.type === 'sequence'"><router-link :to="{ name: 'edit_sequence', params: { id: slice.sequence_id }}">{{ slice.sequence_name }}</router-link></span>
                                                <span v-if="slice.type === 'none'">(no content)</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="broadcast_summary_data.scheduled.length === 0" class="ml-2">
                                <div class="mb-4">No broadcasts currently scheduled.</div>
                                <!--<router-link class="mr-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to="/broadcasts/create">Create New Broadcast</router-link>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--<modal name="delete-broadcast-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Delete Broadcast</div>
                    <div class="modal-c-text">Are you sure you want to delete this broadcast?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('delete-broadcast-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="deleteBroadcast()">Delete</button>
                </div>
            </div>
        </modal>-->
    </div>
</template>


<script>
    import { shallowRef } from 'vue';
    import moment from 'moment-timezone';
    import LayoutApp from '../layouts/App';
    import LayoutHeader from '../shared/layout/Header'

    export default {
        name: 'home',
        components: {
            LayoutApp,
            LayoutHeader,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetch();
            this.fetchGrowthLoss();
            this.fetchMessageSummary();
            this.fetchBroadcastSummary();
        },
        data() {
            return {
                growth_loss_data: null,
                message_summary_data: null,
                broadcast_summary_data: null,
                delete_broadcast_id: null,
            }
        },
        methods: {
            fetch(page) {
                this.$http.get(`/api/v1/home`).then(this.refresh);
            },
            refresh({data}) {
            },
            reportUrl() {
                return `/api/v1/reports/view`;
            },
            fetchGrowthLoss() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().format('YYYY-MM-DD');

                if (!this.$store.getters.current_number_id.isNaN) {
                    this.$http.get(this.reportUrl()+`/?number_id=${this.$store.getters.current_number_id}&type=growth_loss_summary&group_by=day&start=${start}&end=${end}`).then(this.refreshGrowthLoss);
                }
            },
            refreshGrowthLoss({data}) {
                this.growth_loss_data = data.data;
            },
            fetchMessageSummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().add(1, 'days').format('YYYY-MM-DD');

                if (!this.$store.getters.current_number_id.isNaN) {
                    this.$http.get(this.reportUrl()+`/?number_id=${this.$store.getters.current_number_id}&type=message_summary&group_by=day&start=${start}&end=${end}`).then(this.refreshMessageSummary);
                }
            },
            refreshMessageSummary({data}) {
                this.message_summary_data = data.data;
            },
            fetchBroadcastSummary() {
                if (!this.$store.getters.current_number_id.isNaN) {
                    this.$http.get(this.reportUrl()+`/?number_id=${this.$store.getters.current_number_id}&type=broadcast_summary`).then(this.refreshBroadcastSummary);
                }
            },
            refreshBroadcastSummary({data}) {
                this.broadcast_summary_data = data.data;
            },
            confirmBroadcastDelete(id) {
                this.delete_broadcast_id = id;

                this.$modal.show('delete-broadcast-confirm');
            },
            deleteBroadcast() {
                this.$http.delete(`/api/v1/broadcasts/${this.delete_broadcast_id}`).then(this.postBroadcastDelete);
            },
            postBroadcastDelete() {
                this.$modal.hide('delete-broadcast-confirm');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The broadcast has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetchBroadcastSummary()
            },
        }
    }
</script>
