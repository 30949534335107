<template>
    <div>
        <layout-header :title="'Viewing '+person.phone_number">
            <layout-button v-if="person.status === 'subscribed'" href="javascript:void(0);" @click="endSession()">End Session</layout-button>
            <layout-button v-if="person.status === 'subscribed'" href="javascript:void(0);" @click="unsubscribe()">Unsubscribe</layout-button>
        </layout-header>

        <layout-tabs :tabs="tabs" @change="(index) => {
            tabs.forEach((tab, index) => {
                tabs[index].current = false;
            });
            tabs[index].current = true;
        }">
            <div v-if="tabs[0].current">
                <div class="text-right m-2">
                    <form-button @click="triggerSequenceDialog()" v-if="person.status === 'subscribed'">Trigger Sequence</form-button>
                    <form-button @click="triggerMessageDialog()" v-if="person.status === 'subscribed'">Send Message</form-button>
                </div>

                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header @click="updateMessageSort('text')" hide-sm>Message</table-header>
                            <table-header @click="updateMessageSort('type')" hide-sm>In/Out</table-header>
                            <table-header @click="updateMessageSort('internal_type')">Message Type</table-header>
                            <table-header @click="updateMessageSort('delivery_status')" hide-md>Delivery Status</table-header>
                            <table-header @click="updateMessageSort('created_at')" last>Date Sent</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(message, index) in messages" :key="message.id" :index="index">
                            <table-cell bold hide-sm>
                                {{ message.text || '(no text)' }}
                                <div v-if="message.media && message.media.length > 0">
                                    <span class="btn inline-block relative text-gray-700 text-center bg-gray-400 px-4 py-2 m-2" v-for="(file, file_index) in message.media" :key="file_index" :index="file_index">
                                        <a class="far fa-file-image" @click="previewAttachment(file.url, file.type)" href="javascript:void(0)" v-if="file.type === 'image'"></a>
                                        <a class="far fa-file-audio" @click="previewAttachment(file.url, file.type)" href="javascript:void(0)" v-if="file.type === 'audio'"></a>
                                        <a class="far fa-file-video" @click="previewAttachment(file.url, file.type)" href="javascript:void(0)" v-if="file.type === 'video'"></a>
                                    </span>
                                </div>
                            </table-cell>
                            <table-cell>{{ $filters.capitalize(message.type) }}</table-cell>
                            <table-cell>{{ $filters.capitalize(message.internal_type) }}</table-cell>
                            <table-cell hide-md>{{ $filters.capitalize(message.delivery_status) }}</table-cell>
                            <table-cell last>{{ $filters.formatDate(message.created_at) }}</table-cell>
                        </table-row>
                    </template>
                </table-table>

                <paginate
                    :page-count="message_pages"
                    :click-handler="fetchMessages"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'pagination-page'"
                    :prev-class="'pagination-prev'"
                    :next-class="'pagination-next'"
                    :disabled-class="'pagination-inactive'">
                </paginate>
            </div>

            <div v-if="tabs[1].current">
                <div class="text-right m-2">
                    <form-button @click="addToSegmentDialog()">Add to Segment</form-button>
                </div>

                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header hide-sm>Name</table-header>
                            <table-header>Created At</table-header>
                            <table-header>Removed At</table-header>
                            <table-header last>Actions</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(segment, index) in person.segments" :key="segment.id" :index="index">
                            <table-cell hide-sm bold>{{ $filters.capitalize(segment.name) }}</table-cell>
                            <table-cell>{{ $filters.formatDate(segment.pivot.added_at) }}</table-cell>
                            <table-cell>{{ $filters.formatDate(segment.pivot.removed_at) }}</table-cell>
                            <table-cell last><i class="fas fa-trash" @click="confirmRemoveFromSegment(segment.id)"></i></table-cell>
                        </table-row>
                    </template>
                </table-table>
            </div>

            <div v-if="tabs[2].current">
                <div class="text-right m-2">
                    <form-button @click="addAttributeDialog()">Add Attribute</form-button>
                </div>

                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header hide-sm>Name</table-header>
                            <table-header>Value</table-header>
                            <table-header>Created At</table-header>
                            <table-header>Updated At</table-header>
                            <table-header last>Actions</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(attribute, index) in person.attributes" :key="attribute.id" :index="index">
                            <table-cell hide-sm bold>{{ $filters.capitalize(attribute.name) }}</table-cell>
                            <table-cell>{{ attribute.pivot.value }}</table-cell>
                            <table-cell>{{ $filters.formatDate(attribute.pivot.created_at) }}</table-cell>
                            <table-cell>{{ $filters.formatDate(attribute.pivot.updated_at) }}</table-cell>
                            <table-cell last><i class="fas fa-trash" v-if="attribute.scope !== 'system'" @click="confirmRemoveAttribute(attribute.id)"></i></table-cell>
                        </table-row>
                    </template>
                </table-table>
            </div>

            <div v-if="tabs[3].current">
                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header hide-sm>Webform</table-header>
                            <table-header>IP Address</table-header>
                            <table-header last>Created At</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(submission, index) in person.webform_submissions" :key="submission.id" :index="index">
                            <table-cell hide-sm bold>{{ submission.webform.name }}</table-cell>
                            <table-cell>{{ $filters.capitalize(submission.ip_address) }}</table-cell>
                            <table-cell last>{{ $filters.formatDate(submission.created_at) }}</table-cell>
                        </table-row>
                    </template>
                </table-table>
            </div>

            <div v-if="tabs[4].current">
                <table-table>
                    <template v-slot:header>
                        <table-row type="header">
                            <table-header hide-sm>File Key</table-header>
                            <table-header>Type</table-header>
                            <table-header>Sequence</table-header>
                            <table-header last>Created At</table-header>
                        </table-row>
                    </template>
                    <template v-slot:body>
                        <table-row v-for="(file, index) in person.files" :key="file.id" :index="index">
                            <table-cell hide-sm bold>{{ file.file_key }}</table-cell>
                            <table-cell>{{ $filters.capitalize(file.type) }}</table-cell>
                            <table-cell>{{ file.sequence.name }}</table-cell>
                            <table-cell last>{{ $filters.formatDate(file.created_at) }}</table-cell>
                        </table-row>
                    </template>
                </table-table>
            </div>
        </layout-tabs>

        <ConfirmModal
            name="delete-confirm"
            title="Remove Attribute?"
            description="Are you sure you want to remove this attribute?  This action cannot be undone."
            actionText="Remove"
            :show="open_remove_attribute_modal"
            @confirm="removeAttribute()"
            @cancel="open_remove_attribute_modal = false"
        />

        <ConfirmModal
            name="delete-confirm"
            title="Remove Segment?"
            description="Are you sure you want to remove this segment?  This action cannot be undone."
            actionText="Remove"
            :show="open_remove_segment_modal"
            @confirm="removeSegment()"
            @cancel="open_remove_segment_modal = false"
        />

        <TriggerMessageModal
            name="trigger-message"
            actionText="Trigger"
            v-model:triggerMessage="trigger_message"
            :errors="trigger_message_errors"
            :show="open_trigger_message_modal"
            @trigger="triggerMessage()"
            @cancel="open_trigger_message_modal = false"
        />

        <TriggerSequenceModal
            name="trigger-sequence"
            actionText="Trigger"
            :sequences="sequences"
            v-model:triggerSequenceID="trigger_sequence_id"
            :errors="trigger_sequence_errors"
            :show="open_trigger_sequence_modal"
            @trigger="triggerSequence()"
            @cancel="open_trigger_sequence_modal = false"
        />

        <AddSegmentModal
            name="add-segment"
            actionText="Add"
            :segments="segments"
            v-model:addSegmentID="add_segment_id"
            :errors="segment_errors"
            :show="open_add_segment_modal"
            @add="addSegment()"
            @cancel="open_add_segment_modal = false"
        />

        <AddAttributeModal
            name="add-attribute"
            actionText="Add"
            :attributes="attributes"
            v-model:addAttribute="add_attribute"
            v-model:addAttributeValue="add_attribute_value"
            :errors="attribute_errors"
            :show="open_add_attribute_modal"
            @add="addAttribute()"
            @cancel="open_add_attribute_modal = false"
        />

        <PreviewAttachmentModal
            :show="open_preview_modal"
            :previewAttachmentUrl="previewAttachmentUrl"
            :previewAttachmentType="previewAttachmentType"
            @cancel="open_preview_modal = false; previewAttachmentUrl = null; previewAttachmentType = null;"
        />
    </div>
</template>


<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import LayoutButton from '../../shared/layout/Button';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormLabel from '../../shared/form/Label';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';
    import FormDatepicker from '../../shared/form/DatePicker';
    import LayoutSummary from '../../shared/layout/Summary';
    import LayoutSummaryItem from '../../shared/layout/SummaryItem';
    import LayoutTabs from '../../shared/layout/Tabs';
    import TableTable from '../../shared/table/Table'
    import TableRow from '../../shared/table/Row'
    import TableHeader from '../../shared/table/Header'
    import TableCell from '../../shared/table/Cell'
    import ConfirmModal from '../../shared/modal/ConfirmModal'
    import TriggerMessageModal from '../../shared/modal/TriggerMessageModal'
    import TriggerSequenceModal from '../../shared/modal/TriggerSequenceModal'
    import AddSegmentModal from '../../shared/modal/AddSegmentModal'
    import AddAttributeModal from '../../shared/modal/AddAttributeModal'
    import PreviewAttachmentModal from '../../shared/modal/PreviewAttachmentModal'
    import { PhoneIcon, PhoneArrowDownLeftIcon, ListBulletIcon, CircleStackIcon, QueueListIcon, PhotoIcon } from '@heroicons/vue/20/solid'

    export default {
        name: 'ViewPerson',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormLabel,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
            FormDatepicker,
            LayoutSummary,
            LayoutSummaryItem,
            LayoutTabs,
            ConfirmModal,
            TriggerMessageModal,
            TriggerSequenceModal,
            AddSegmentModal,
            AddAttributeModal,
            PreviewAttachmentModal
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch();
            this.fetchMessages(1);
            this.fetchSegments();
            this.fetchSequences();
            this.fetchAttributes();
            this.resetTriggerMessage();
            this.fetchSettings();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                person: {
                    id: '',
                    first_name: '',
                    last_name: '',
                    added_at: '',
                    source: '',
                    handover_primary: '',
                    segments: [],
                    attributes: []
                },
                messages: [],
                message_pages: 1,
                attributes: [],
                all_attributes: [],
                segments: [],
                sequences: [],
                add_segment_id: null,
                add_attribute: null,
                add_attribute_value: null,
                remove_segment_id: null,
                remove_attribute_id: null,
                attribute_errors: [],
                segment_errors: [],
                trigger_sequence_id: null,
                trigger_message: null,
                trigger_sequence_errors: [],
                trigger_message_errors: [],
                tabs: [
                    { name: 'Messages', href: '#', icon: PhoneIcon, current: true },
                    { name: 'Segments', href: '#', icon: ListBulletIcon, current: false },
                    { name: 'Attributes', href: '#', icon: CircleStackIcon, current: false },
                    { name: 'Submissions', href: '#', icon: QueueListIcon, current: false },
                    { name: 'Files', href: '#', icon: PhotoIcon, current: false },
                ],
                settings: {
                    text: {
                        features: {
                        },
                        maximum_numbers: null,
                        maximum_sequences: null,
                    },
                },
                permissions: {
                    chat: false,
                    text: true,
                    talk: false,
                    lookup: false,
                    links: false,
                    connect: false,
                },
                message_sort: 'desc',
                message_order: 'created_at',
                allow_short_links: false,
                allow_trackable_links: false,
                previewAttachmentUrl: null,
                previewAttachmentType: null,
                open_trigger_message_modal: false,
                open_trigger_sequence_modal: false,
                open_add_segment_modal: false,
                open_remove_segment_modal: false,
                open_add_attribute_modal: false,
                open_remove_attribute_modal: false,
                open_remove_recording_modal: false,
                open_preview_modal: false,
            }
        },
        methods: {
            fetch() {
                this.$http.get(`/api/v1/people/${this.id}?number_id=${this.$store.getters.current_number_id}`).then(this.refresh);
            },
            refresh({data}) {
                this.person = data.data;
                window.scrollTo(0,0);
            },
            fetchSettings() {
                this.$http.get(`/api/v1/me`).then(this.refreshSettings);
            },
            refreshSettings({data}) {
                this.settings = data.data.settings;
                this.permissions = data.data.permissions;

                if (this.permissions.links) {
                    this.allow_short_links = true

                    if (this.settings.links.features.link_type_trackable) {
                        this.allow_trackable_links = true
                    }
                }
            },
            fetchSegments() {
                this.$http.get(`/api/v1/segments?number_id=${this.$store.getters.current_number_id}&search_types=static`).then(this.refreshSegments);
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchSequences() {
                this.$http.get(`/api/v1/sequences?number_id=${this.$store.getters.current_number_id}&per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                this.$http.get(`/api/v1/attributes`).then(this.refreshAttributes);
            },
            refreshAttributes({
                data
            }) {
                for(let index in data.data) {
                    this.all_attributes.push(data.data[index]);

                    if (data.data[index].scope !== 'system') {
                        this.attributes.push(data.data[index]);
                    }
                }
            },
            fetchMessages(page) {
                this.$http.get(`/api/v1/people/${this.id}/messages?page=${page}&number_id=${this.$store.getters.current_number_id}&per_page=25&sort=${this.message_sort}&order=${this.message_order}`).then(this.refreshMessages);
            },
            refreshMessages({data}) {
                this.messages = data.data;
                this.message_pages = data.meta.pages;
                window.scrollTo(0,0);
            },
            addToSegmentDialog() {
                this.add_segment_id = null
                this.segment_errors = []

                this.open_add_segment_modal = true;
            },
            addSegment() {
                this.$http.post(`/api/v1/people/${this.id}/segments/${this.add_segment_id}`).then(this.postSegmentAdd).catch(error => {
                    if (error.response.status === 422) {
                        this.segment_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while associating the segment',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postSegmentAdd() {
                this.open_add_segment_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The segment has been added',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },

            confirmRemoveFromSegment(segment_id) {
                this.remove_segment_id = segment_id;
                this.open_remove_segment_modal = true;
            },
            removeSegment() {
                this.$http.delete(`/api/v1/people/${this.id}/segments/${this.remove_segment_id}`).then(this.postSegmentRemove);
            },
            postSegmentRemove() {
                this.open_remove_segment_modal = true;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The segment has been removed',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },


            addAttributeDialog() {
                this.add_attribute = null
                this.add_attribute_value = null
                this.attribute_errors = []
                this.open_add_attribute_modal = true;
            },
            addAttribute() {
                let attribute_id = (this.add_attribute ? this.add_attribute.id : null)

                this.$http.post(`/api/v1/people/${this.id}/attributes/${attribute_id}`, {value: this.add_attribute_value}).then(this.postAttributeAdd).catch(error => {
                    if (error.response.status === 422) {
                        this.attribute_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while associating the attribute',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postAttributeAdd() {
                this.open_add_attribute_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The attribute has been added',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },
            confirmRemoveAttribute(attribute_id) {
                this.remove_attribute_id = attribute_id;
                this.open_remove_attribute_modal = true;
            },
            removeAttribute() {
                this.$http.delete(`/api/v1/people/${this.id}/attributes/${this.remove_attribute_id}`).then(this.postAttributeRemove);
            },
            postAttributeRemove() {
                this.open_remove_attribute_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The attribute has been removed',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },
            triggerSequenceDialog() {
                this.trigger_sequence_id = null
                this.trigger_sequence_errors = []
                this.open_trigger_sequence_modal = true;
            },
            triggerSequence() {
                this.trigger_sequence_errors = []

                this.$http.post(`/api/v1/people/${this.id}/sequence/${this.trigger_sequence_id}?number_id=${this.$store.getters.current_number_id}&`).then(this.postTriggerSequence).catch(error => {
                    if (error.response.status === 422) {
                        this.trigger_sequence_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while triggering the sequence',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postTriggerSequence() {
                this.open_trigger_sequence_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The sequence has been triggered',
                    duration: 5000,
                    speed: 1000
                });
            },
            resetTriggerMessage() {
                this.trigger_message = {
                    text: {
                        type: 'sms',
                        message: '',
                        subject: '',
                        fallback: '',
                        files: [],
                    },
                }
            },
            triggerMessageDialog() {
                this.resetTriggerMessage()
                this.trigger_message_errors = []
                this.open_trigger_message_modal = true;
            },
            triggerMessage() {
                this.trigger_message_errors = []

                this.$http.post(`/api/v1/people/${this.id}/message?number_id=${this.$store.getters.current_number_id}&`, this.trigger_message).then(this.postTriggerMessage).catch(error => {
                    if (error.response.status === 422) {
                        this.trigger_message_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while sending the message',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postTriggerMessage() {
                this.open_trigger_message_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The message has been triggered',
                    duration: 5000,
                    speed: 1000
                });

                this.resetTriggerMessage()
            },
            unsubscribe() {
                this.$http.post(`/api/v1/people/${this.id}/unsubscribe?number_id=${this.$store.getters.current_number_id}&`).then(this.postUnsubscribe);
            },
            postUnsubscribe() {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The person has been unsubscribed',
                    duration: 5000,
                    speed: 1000
                });
            },
            endSession() {
                this.$http.post(`/api/v1/people/${this.id}/endsession?number_id=${this.$store.getters.current_number_id}&`).then(this.postEndSession);
            },
            postEndSession() {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The person\'s session has been ended',
                    duration: 5000,
                    speed: 1000
                });
            },
            updateMessageSort(field) {
                if (field === this.message_order) {
                    this.message_sort = (this.message_sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.message_sort = 'asc';
                }

                this.message_order = field;

                this.fetchMessages(1);
            },
            previewAttachment(url, type) {
                this.previewAttachmentUrl = url;
                this.previewAttachmentType = type;
                this.open_preview_modal = true;
            },
        }
    }
</script>
