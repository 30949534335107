<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="$emit('cancel')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <div>
                                <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Add Block</DialogTitle>
                                <div class="mt-2 mb-2">
                                    <p class="text-sm text-gray-500">Add a new block to the sequence</p>
                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 md:grid-cols-4">
                                    <div class="bg-white px-4 py-2 sm:px-6 hidden sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Actions</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 md:col-span-3 px-4 py-2 sm:px-6">
                                        <label @click="addBlock('subscription')" class="palette-label">
                                            <i class="fas fa-user-check pr-2"></i>Subscription
                                        </label>

                                        <label @click="addBlock('message')" class="palette-label">
                                            <i class="far fa-comment pr-2"></i>Send Message
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 md:grid-cols-4">
                                    <div class="bg-white px-4 py-2 sm:px-6 hidden sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Prompts</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 md:col-span-3 px-4 py-2 sm:px-6">

                                        <label @click="addBlock('collect_attribute')" class="palette-label">
                                            <i class="fas fa-user-tag pr-2"></i>Collect Attr
                                        </label>

                                        <label @click="addBlock('collect_file')" class="palette-label">
                                            <i class="fas fa-file-upload pr-2"></i>Collect File
                                        </label>

                                        <label v-if="integrations.google_civic_api.status === 'active'" @click="addBlock('google_civic_polling_locator')" class="palette-label">
                                            <i class="fab fa-google pr-2"></i>Locator
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 md:grid-cols-4">
                                    <div class="bg-white px-4 py-2 sm:px-6 hidden sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Attributes</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 md:col-span-3 px-4 py-2 sm:px-6">

                                        <label @click="addBlock('tag_attribute')" class="palette-label">
                                            <i class="fas fa-user-tag pr-2"></i>Tag Attr
                                        </label>

                                        <label @click="addBlock('add_to_segment')" class="palette-label">
                                            <i class="fas fa-user-plus pr-2"></i>Segment
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 md:grid-cols-4">
                                    <div class="bg-white px-4 py-2 sm:px-6 hidden sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Options</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 md:col-span-3 px-4 py-2 sm:px-6">
                                        <label @click="addBlock('menu')" class="palette-label">
                                            <i class="fas fa-list-ol pr-2"></i>Menu
                                        </label>

                                        <label @click="addBlock('random')" class="palette-label">
                                            <i class="fas fa-random pr-2"></i>Random
                                        </label>

                                        <label @click="addBlock('poll')" class="palette-label">
                                            <i class="fas fa-poll pr-2"></i>Poll
                                        </label>

                                        <label @click="addBlock('absplit')" class="palette-label">
                                            <i class="fas fa-code-branch pr-2"></i>A/B Split
                                        </label>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 md:grid-cols-4">
                                    <div class="bg-white px-4 py-2 sm:px-6 hidden sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Logic</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 md:col-span-3 px-4 py-2 sm:px-6">
                                        <label @click="addBlock('conditional')" class="palette-label">
                                            <i class="fas fa-bezier-curve pr-2"></i>Conditional
                                        </label>

                                        <label @click="addBlock('forward_to_sequence')" class="palette-label">
                                            <i class="fas fa-share pr-2"></i>To Sequence
                                        </label>

                                        <label @click="addBlock('forward_to_inbox')" class="palette-label">
                                            <i class="fas fa-envelope-open-text pr-2"></i>To Inbox
                                        </label>

                                        <label @click="addBlock('date_gate')" class="palette-label">
                                            <i class="fas fa-share pr-2"></i>Date Gate
                                        </label>

                                        <!--<label @click="addBlock('date_conditional')" class="palette-label">
                                            <i class="fas fa-share pr-2"></i>Date Condition
                                        </label>-->
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 md:grid-cols-4">
                                    <div class="bg-white px-4 py-2 sm:px-6 hidden sm:block">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900">Advanced</h3>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 md:col-span-3 px-4 py-2 sm:px-6">
                                        <label @click="addBlock('drip_message')" class="palette-label">
                                            <i class="fas fa-tint pr-2"></i>Drip Message
                                        </label>

                                        <label @click="addBlock('dynamic_content')" class="palette-label">
                                            <i class="fas fa-code pr-2"></i>Dynamic Res
                                        </label>

                                        <label @click="addBlock('notification')" class="palette-label">
                                            <i class="far fa-paper-plane pr-2"></i>Notification
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <form-button secondary @click="$emit('cancel')">
                            {{ cancelText }}
                        </form-button>
                    </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import FormButton from '../../shared/form/Button'
    import FormSection from '../../shared/form/Section'
    import FormField from '../../shared/form/Field'
    import FormLabel from '../../shared/form/Label'
    import FormSelect from '../../shared/form/Select'
    import FormInput from '../../shared/form/Input'
    import FormValidation from '../../shared/form/ValidationNotice';
    import DynamicTextarea from '../../shared/DynamicTextarea'
    import DynamicTextareaBasic from '../../shared/DynamicTextareaBasic';

    export default {
        name: 'block-modal',
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormButton,
            FormSection,
            FormField,
            FormLabel,
            FormSelect,
            FormInput,
            FormValidation,
            DynamicTextarea,
        },
        emits: [
            'add',
            'cancel',
            'update:currentID',
        ],
        props: {
            name: String,
            currentID: Number,
            errors: Object,
            settings: Object,
            integrations: Object,
            actionText: {
                type: String,
                default: 'Add',
            },
            cancelText: {
                type: String,
                default: 'Cancel',
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            current_id: {
                get() {
                    return this.currentID;
                },
                set(v) {
                    this.$emit('update:currentID', v)
                }
            },
        },
        methods: {
            addBlock(type) {
                let block = null;
                let current_id = this.current_id;

                if (type === 'subscription') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'subscription',
                        'parameters': {
                            'optin_type': 'double',
                            'confirm': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'reject': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'subscription_welcome',
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'subscription_exists',
                                'blocks': [],
                                'errors': [],
                            },
                        ],
                        'errors': [],
                    }
                } else if (type === 'message') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'message',
                        'parameters': {
                            'text': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                        },
                        'errors': [],
                    }
                } else if (type === 'collect_attribute') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'collect_attribute',
                        'parameters': {
                            'request': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'valid': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'invalid': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'attribute_id': ''
                        },
                        'errors': [],
                    }
                } else if (type === 'collect_file') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'collect_file',
                        'parameters': {
                            'request': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'valid': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'invalid': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'file_key': '',
                            'file_type': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'menu') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'menu',
                        'choices': 1,
                        'parameters': {
                            'attribute_id': '',
                            'request': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': [],
                            },
                            'invalid': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': [],
                            },
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'menu_item',
                                'parameters': {
                                    'option': 'A',
                                    'name': 'Choice 1',
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            }
                        ],
                        'errors': [],
                    }
                } else if (type === 'random') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'random',
                        'choices': 1,
                        'parameters': {
                            'attribute_id': null,
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'random_item',
                                'parameters': {
                                    'percent': 100,
                                    'name': 'Choice 1',
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            }
                        ],
                        'errors': [],
                    }
                } else if (type === 'poll') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'poll',
                        'choices': 1,
                        'parameters': {
                            'attribute_id': null,
                            'voting_type': 'first_only',
                            'allow_external_access': 'false',
                            'send_results': 'false',
                            'request': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'invalid': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'voted': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'results': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'poll_item',
                                'parameters': {
                                    'option': 'A',
                                    'name': 'Choice A',
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            }
                        ],
                        'errors': [],
                    }
                } else if (type === 'absplit') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'absplit',
                        'choices': 1,
                        'parameters': {
                            'attribute_id': null,
                            'split_type': 'first_only',
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'absplit_item',
                                'parameters': {
                                    'percent': 100,
                                    'name': 'Choice 1',
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            }
                        ],
                        'errors': [],
                    }
                } else if (type === 'conditional') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'conditional',
                        'parameters': {
                            'conditions': {
                                'operator': 'AND',
                                'conditions': []
                            },
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'true',
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'false',
                                'blocks': [],
                                'errors': [],
                            },
                        ],
                        'errors': [],
                    }
                } else if (type === 'tag_attribute') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'tag_attribute',
                        'parameters': {
                            'value': '',
                            'attribute_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'add_to_segment') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'add_to_segment',
                        'parameters': {
                            'segment_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'forward_to_sequence') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'forward_to_sequence',
                        'parameters': {
                            'sequence_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'forward_to_inbox') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'forward_to_inbox',
                        'parameters': {
                            'delay_timespan': 12,
                        },
                        'errors': [],
                    }
                } else if (type === 'drip_message') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'drip_message',
                        'parameters': {
                            'send_at': 1,
                            'send_active': true
                        },
                        'errors': [],
                    }
                } else if (type === 'notification') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'notification',
                        'parameters': {
                            'type': 'email',
                            'recipient': '',
                            'text': '',
                            'subject': '',
                            'url': '',
                        },
                        'errors': [],
                    }
                } else if (type === 'dynamic_content') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'dynamic_content',
                        'parameters': {
                            'url': ''
                        },
                        'errors': [],
                    }
                } else if (type === 'google_civic_polling_locator') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'google_civic_polling_locator',
                        'parameters': {
                            'request': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'valid': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'invalid': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'notfound': {
                                'type': 'sms',
                                'subject': '',
                                'message': '',
                                'fallback': '',
                                'files': []
                            },
                            'election_id': ''
                        },
                        'errors': [],
                    }
                } else if (type === 'date_gate') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'date_gate',
                        'parameters': {
                            'date_start': null,
                            'date_end': null,
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_before',
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_during',
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_after',
                                'blocks': [],
                                'errors': [],
                            },
                        ],
                        'errors': [],
                    }
                } else if (type === 'date_conditional') {
                    block = {
                        'id': ('new_'+(++current_id)),
                        'messaging_type': 'date_conditional',
                        'choices': 1,
                        'parameters': {
                            'attribute_id': null,
                        },
                        'blocks': [
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_condition',
                                'parameters': {
                                    'conditions': {
                                        'operator': 'AND',
                                        'conditions': []
                                    },
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+(++current_id)),
                                'messaging_type': 'date_condition_default',
                                'parameters': {
                                    'attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            },
                        ],
                        'errors': [],
                    }
                }

                this.current_id = current_id;
                this.$emit('add', block);
            },
        }
    };
</script>
